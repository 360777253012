import { useAtom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import { useEffect } from 'react';

export const $theme = atomWithStorage<'light' | 'dark'>(
  'beink-theme',
  'dark',
  undefined,
  {
    getOnInit: true,
  }
);

export const useTheme = () => {
  const [theme, setTheme] = useAtom($theme);

  useEffect(() => {
    document.body.classList.toggle('dark-theme', theme === 'dark');
  }, [theme]);

  return [theme, setTheme] as const;
};
