import { Link, useParams } from 'react-router-dom';
import {
  faFolder,
  faLink,
  faRemove,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@/components/lib/Button';
import { useTranslation } from 'react-i18next';
import Card, {
  CreatedAtInfo,
  NumberOfImagesInfo,
  OverviewCard,
} from '@/components/lib/Containers/Card';
import RenamableAlbum from '@/components/Common/EditableItems/RenamableAlbum';
import useArchiveAlbum from '@/hooks/albums/useArchiveAlbum';
import useRestoreAlbum from '@/hooks/albums/useRestoreAlbum';
import { copyToClipboard } from '@/helpers/copyToClipboard';

import useGetAlbum from '@/hooks/albums/useGetAlbum';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import AlbumImageGallery from '@/components/Creator/Panel/Album/AlbumImageGallery';
import { ItemPageWrapper } from '../common/ItemPageWrapper';

import useRemoveAlbumFromProjects from '@/hooks/albums/useRemoveAlbumFromProjects';
import AddAlbumToProjectsForm from '@/components/Common/ItemsForms/AddAlbumToProjectsForm';

const AlbumPage = () => {
  const { albumId } = useParams<{ albumId: string }>();

  const { album } = useGetAlbum(albumId as string);
  if (!albumId) return null;

  if (!album) return null;

  const { images } = album;

  return (
    <ItemPageWrapper>
      <AlbumOverview albumId={albumId} imagesNumber={images.length} />

      <AlbumImageGallery album={album} />
    </ItemPageWrapper>
  );
};

const AlbumOverview = ({
  albumId,
  imagesNumber,
}: {
  albumId: string;
  imagesNumber: number;
}) => {
  const { album } = useGetAlbum(albumId ?? '');
  const { t } = useTranslation('dashboard');
  const { archiveAlbum } = useArchiveAlbum();
  const { restoreAlbum } = useRestoreAlbum();

  const { removeAlbumFromProjects } = useRemoveAlbumFromProjects();

  if (!album) {
    return null;
  }

  const { status, createdAt, projects } = album;

  return (
    <OverviewCard>
      <div className='flex flex-col gap-10'>
        <RenamableAlbum
          className='border-grey bg-surface-secondary/50 w-full rounded-xl border p-4'
          albumId={albumId}
        />

        <Card.Section>
          <Card.SectionHeader>{t('common.details')}</Card.SectionHeader>
          <Card.Separator />
          <CreatedAtInfo date={new Date(createdAt)} />
          <NumberOfImagesInfo number={imagesNumber} />
        </Card.Section>
        <Card.Section>
          <Card.SectionHeader>{t('album.view.usedIn')}</Card.SectionHeader>
          <Card.Separator />

          {projects &&
            projects.length > 0 &&
            projects.map((project) => (
              <Card.Info icon={faFolder}>
                <p>{project.name}</p>
                <div className='flex gap-2 items-center'>
                  <Link
                    to={`/projects/${project.id}`}
                    title={t('album.view.removeFromProject')}>
                    <FontAwesomeIcon icon={faLink} />
                  </Link>
                  <button
                    className='text-red cursor-pointer'
                    title={t('album.view.removeFromProject')}
                    onClick={() =>
                      removeAlbumFromProjects(albumId, [project.id])
                    }>
                    <FontAwesomeIcon icon={faRemove} className='text-red' />
                  </button>
                </div>
              </Card.Info>
            ))}
        </Card.Section>
        <Card.Section>
          <AddAlbumToProjectsForm albumId={albumId} integrated={true} />
        </Card.Section>

        <Card.Section>
          <Button
            leftIcon={faLink}
            iconSize='20px'
            className='w-full'
            onClick={() => {
              const url = `${window.location.origin}/albums/${albumId}`;
              copyToClipboard(url);
            }}
            variant={'primary'}>
            {t('common.copyLink')}
          </Button>
        </Card.Section>
      </div>

      <div className='w-full self-end'>
        <Button
          variant='danger'
          size='medium'
          className='w-full'
          leftIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
          onClick={() => {
            if (status === 'VISIBLE') {
              archiveAlbum(album, albumId);
            } else {
              restoreAlbum(album, albumId);
            }
          }}>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
      </div>
    </OverviewCard>
  );
};

export default AlbumPage;
