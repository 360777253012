import { useEffect, useState } from 'react';
import { useCreationsAPI } from '../creations/useCreationsAPI';
import useGetProject from './useGetProject';
import { Creation } from '@/types/api';

/**This hook is just here to get the generations of a project while we don't have the API endpoint*/
export const useGetProjectGenerations = (projectId: string) => {
  const { project } = useGetProject(projectId ?? '');
  const { getCreations } = useCreationsAPI();
  const [allGenerations, setAllGenerations] = useState<Creation[]>([]);

  useEffect(() => {
    const fetchGenerations = async () => {
      if (!project?.whiteboards) return;
      const allGenerations = await Promise.all(
        project.whiteboards.map((whiteboard) => {
          return getCreations(1, whiteboard.id, 200);
        })
      );

      setAllGenerations(allGenerations.map((gen) => gen.creations).flat());
    };

    fetchGenerations();
  }, []);

  return { allGenerations };
};
