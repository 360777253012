import useGetProject from '@/hooks/projects/useGetProject';
import { useParams } from 'react-router-dom';
import PageTitle from '../../Layout/PageTitle';

import {
  faBookOpen,
  faChalkboard,
  faImages,
  faPlus,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import { UserRole } from '@/types';
import Tabs from '@/components/lib/Tabs/Tabs';
import { useHasRole } from '@/hooks/useHasRole';
import { useTranslation } from 'react-i18next';

// Import the new tab components
import WhiteboardTab from './WhiteboardTab';
import AlbumTab from './AlbumTab';
import CreationTab from './CreationTab';
import Card, {
  CreatedAtInfo,
  NumberOfAlbumsInfo,
  NumberOfGenerationsInfo,
  NumberOfWhiteboardsInfo,
  OverviewCard,
} from '@/components/lib/Containers/Card';
import RenamableProject from '../../../Common/EditableItems/RenamableProject';

import { useCreationsAPI } from '@/hooks/creations/useCreationsAPI';
import { useState, useEffect } from 'react';
import useArchiveProject from '@/hooks/projects/useArchiveProject';
import useRestoreProject from '@/hooks/projects/useRestoreProject';
import Button from '@/components/lib/Button';
/* import PresentationSwitch from '../../common/PresentationSwitch'; */

import { PresentationLayout } from '@/types/ui';
import { AddWhiteboardModal } from '../../../Common/ItemsForms/AddWhiteboardToProjectModal';
import { AddAlbumModal } from '../../../Common/ItemsForms/AddAlbumToProjectModal';

import { useIsScreen } from '@/hooks/useScreenQuery';

import { PageHeader } from '../../common/PageHeader';
import { EditableProjectDescription } from '@/components/Common/EditableItems/EditableProjectDescription';
import { ItemPageWrapper } from '../../common/ItemPageWrapper';

const ProjectPage = () => {
  const { projectId } = useParams();
  const { project } = useGetProject(projectId ?? '');

  const [presentation /* , setPresentation */] =
    useState<PresentationLayout>('grid');

  const [activeAddModal, setActiveAddModal] = useState<
    'whiteboards' | 'albums' | null
  >(null);

  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');

  const hasInspireRole = hasRole(UserRole.Inspire);

  const isScreen = useIsScreen();

  if (!projectId) return null;

  return (
    <ItemPageWrapper>
      {/* Add new Whiteboard */}
      <AddWhiteboardModal
        isOpen={activeAddModal === 'whiteboards'}
        onClose={() => setActiveAddModal(null)}
        projectId={projectId}
        existingWhiteboards={project?.whiteboards}
      />

      {/* Add new Album */}
      <AddAlbumModal
        isOpen={activeAddModal === 'albums'}
        onClose={() => setActiveAddModal(null)}
        projectId={projectId}
        existingAlbums={project?.albums}
      />

      <ProjectOverview projectId={projectId} />

      <Tabs
        selectorId='project-tabs'
        className='h-full overflow-visible lg:overflow-hidden'
        TabHeader={({ tabNav, currentTab }) => {
          return (
            <PageHeader
              tabNav={tabNav}
              /* presentationSwitch={
                currentTab?.id !== 'creations' && (
                  <PresentationSwitch
                    presentation={presentation}
                    setPresentation={setPresentation}
                  />
                )
              } */
            >
              {currentTab?.id !== 'creations' && (
                <Button
                  rightIcon={faPlus}
                  variant='accent'
                  showLabel={!isScreen}
                  size='large'
                  onClick={() =>
                    setActiveAddModal(
                      currentTab?.id as 'whiteboards' | 'albums'
                    )
                  }>
                  {t('common.add') + ' ' + currentTab?.title}
                </Button>
              )}
            </PageHeader>
          );
        }}
        tabs={[
          {
            id: 'whiteboards' as const,
            title: t('pageNames.whiteboards'),
            icon: faChalkboard,
            content: (
              <WhiteboardTab
                presentation={presentation}
                projectId={projectId}
              />
            ),
          },
          hasInspireRole
            ? {
                id: 'albums' as const,
                title: t('pageNames.albums'),
                icon: faBookOpen,
                content: (
                  <AlbumTab presentation={presentation} projectId={projectId} />
                ),
              }
            : null,
          {
            id: 'creations' as const,
            title: t('pageNames.creations'),
            icon: faImages,
            content: <CreationTab projectId={projectId} />,
          },
        ].filter((tab) => tab !== null)}></Tabs>
    </ItemPageWrapper>
  );
};

const ProjectOverview = ({ projectId }: { projectId: string }) => {
  const { project, isError, isLoading } = useGetProject(projectId ?? '');
  const { getCreations } = useCreationsAPI();
  const { t } = useTranslation('dashboard');
  const [totalGenerations, setTotalGenerations] = useState(0);

  const { archiveProject } = useArchiveProject();
  const { restoreProject } = useRestoreProject();

  //TODO: we're missing an API endpoint that returns all the generations of a project, so we're fetching all the creations of all the whiteboards in the project
  //@Aurélien
  useEffect(() => {
    const fetchGenerations = async () => {
      if (!project?.whiteboards) return;
      const allGenerations = await Promise.all(
        project.whiteboards.map((whiteboard) => {
          return getCreations(1, whiteboard.id, 200);
        })
      );

      console.log(allGenerations);
      const total = allGenerations.reduce(
        (acc, curr) => acc + curr.creations.length,
        0
      );

      setTotalGenerations(total);
    };

    fetchGenerations();
  }, []);

  if (isError) return <PageTitle title={t('notFound.project')} />;
  if (isLoading) return <PageTitle title={t('state.loading')} />;
  if (!project) return <PageTitle title={t('state.loading')} />;

  const { status } = project;

  return (
    <OverviewCard>
      <div className='flex flex-col gap-10'>
        <Card.Section>
          <Card.SectionHeader>
            {t('pages.project.overview.name')}
          </Card.SectionHeader>
          <Card.Separator />
          <RenamableProject
            className='border-grey bg-surface-secondary/50 w-full rounded-xl border p-4'
            projectId={projectId}
          />
        </Card.Section>

        <Card.Section>
          <Card.SectionHeader>
            {t('pages.project.overview.description')}
          </Card.SectionHeader>
          <Card.Separator />

          <EditableProjectDescription
            className='w-full'
            projectId={projectId}
          />
        </Card.Section>

        <Card.Section>
          <Card.SectionHeader>{t('common.details')}</Card.SectionHeader>
          <Card.Separator />
          <CreatedAtInfo date={new Date(project.createdAt)} />
          <NumberOfWhiteboardsInfo number={project.whiteboards?.length} />
          <NumberOfAlbumsInfo number={project.albums?.length} />
          <NumberOfGenerationsInfo number={totalGenerations} />
        </Card.Section>
      </div>

      <div className='w-full self-end'>
        <Button
          variant='danger'
          size='medium'
          className='w-full'
          leftIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
          onClick={() => {
            if (status === 'VISIBLE') {
              archiveProject(projectId);
            } else {
              restoreProject(projectId);
            }
          }}>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
      </div>
    </OverviewCard>
  );
};

export default ProjectPage;
