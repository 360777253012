import useUpdateAlbum from '../../../hooks/albums/useUpdateAlbum';
import useGetAlbum from '@/hooks/albums/useGetAlbum';
import RenamableItem from '@/components/lib/RenamableItem';

type RenamableAlbumProps = {
  albumId: string;
  className?: string;
};

const RenamableAlbum = ({ albumId, className }: RenamableAlbumProps) => {
  const { album } = useGetAlbum(albumId);
  const { updateAlbum } = useUpdateAlbum();

  const handleRename = async (newName: string) => {
    if (!album) return;
    await updateAlbum(album, album.id, newName);
  };

  return (
    <RenamableItem
      initialName={album?.name}
      onRename={handleRename}
      className={className}
    />
  );
};

export default RenamableAlbum;
