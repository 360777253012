import { useTranslation } from 'react-i18next';
import { BeinkMode, UserRole } from '../../../types';
import {
  faCircleQuestion,
  faPencil,
  faMagicWandSparkles,
  faTimes,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';

import { usePanelContext } from './context/usePanelContext';

import { motion } from 'framer-motion';
import { twJoin } from 'tailwind-merge';
import { useHasRole } from '@/hooks/useHasRole';
import { I18Keys } from '@/types/i18next';
import { useMemo } from 'react';

type PanelNavigationButton = {
  mode: BeinkMode;
  icon: IconDefinition;
  label: I18Keys<'panel'>;
  condition?: boolean;
};

const PanelNavigation = function () {
  const { t } = useTranslation('panel');
  const {
    showHelp,
    setShowHelp,
    currentMode,
    setCurrentMode,
    isPanelOpen,
    setIsPanelOpen,
  } = usePanelContext();

  const hasRole = useHasRole();
  const inpaintAllowed = hasRole(UserRole.Inpaint);

  const buttons: PanelNavigationButton[] = useMemo(
    () => [
      {
        mode: BeinkMode.Imagine,
        icon: faPencil,
        label: 'Modes.Imagine',
      } as const,
      {
        mode: BeinkMode.Inpaint,
        icon: faMagicWandSparkles,
        label: 'Modes.Inpaint',
        condition: inpaintAllowed,
      } as const,
    ],
    [inpaintAllowed]
  );

  return (
    <motion.div
      layout
      style={{ borderRadius: '16px' }}
      className='bg-surface-primary relative flex items-center justify-between p-2'>
      <motion.nav
        layout
        className={twJoin(
          'flex gap-2 place-self-center',
          !isPanelOpen && 'excalidraw:flex-col'
        )}>
        {buttons.map(
          ({ mode, icon, label, condition = true }) =>
            condition && (
              <Button
                animateLabel={false}
                key={mode}
                variant={'tab'}
                selectorId='panel-navigation'
                showLabel={isPanelOpen}
                leftIcon={icon}
                state={currentMode === mode ? 'active' : 'default'}
                onClick={() => {
                  setCurrentMode(mode);
                  setIsPanelOpen(true); // open panel when switching modes
                }}>
                {t(label)}
              </Button>
            )
        )}
      </motion.nav>
      {/* HELP BUTTON*/}
      {isPanelOpen && (
        <Button
          round
          variant='secondary'
          iconSize='16px'
          leftIcon={showHelp ? faTimes : faCircleQuestion}
          onClick={() => {
            setShowHelp((prev) => !prev);
          }}
        />
      )}
    </motion.div>
  );
};

export default PanelNavigation;
