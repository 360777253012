import { useEffect } from "react";

const usePreventSwipeBack = (enabled = true) => {
    useEffect(() => {
        if (!enabled) return;

        const handlePopState = () => {
            history.pushState(null, "", location.href);
        };

        const handleWheel = (event: any) => {
            if (event.deltaX < 0) {
                event.preventDefault();
            }
        };

        // Add event listeners
        window.addEventListener("popstate", handlePopState);
        window.addEventListener("wheel", handleWheel, { passive: false });

        // Initialize state to prevent back navigation
        history.pushState(null, "", location.href);

        return () => {
            // Cleanup event listeners when unmounting
            window.removeEventListener("popstate", handlePopState);
            window.removeEventListener("wheel", handleWheel);
        };
    }, [enabled]);
};

export default usePreventSwipeBack;