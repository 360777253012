import { useKeycloak } from '@react-keycloak/web';
import { useSync } from '@tldraw/sync';
import { useCallback, useMemo } from 'react';
import { Editor, defaultShapeUtils, defaultBindingUtils } from 'tldraw';
import {
  customShapes,
  UNWANTED_DEFAULT_SHAPE_TYPES,
} from '../config/CustomShapes';
import useMultiplayerAssets from './useMultiplayerAssets';

const COLLAB_SERVER_URL = import.meta.env.VITE_COLLAB_SERVER_URL;

export const useCollabStore = (editor: Editor | null, whiteboardId: string) => {
  const { keycloak } = useKeycloak();

  const multiplayerAssets = useMultiplayerAssets(editor, whiteboardId);

  const getURI = useCallback(async () => {
    return `${COLLAB_SERVER_URL}/connect/${whiteboardId}?token=${keycloak.token}`;
  }, [whiteboardId, keycloak.token]);

  const defaultShapeUtilsWithoutUnwanted = defaultShapeUtils.filter(
    (shapeUtil) => !UNWANTED_DEFAULT_SHAPE_TYPES.includes(shapeUtil.type)
  );

  const shapeUtilsWithDefault = useMemo(
    () => [...defaultShapeUtilsWithoutUnwanted, ...customShapes],
    []
  );

  //Breaking changes when updating to tldraw 3.8
  const store = useSync({
    uri: getURI,
    assets: multiplayerAssets,
    shapeUtils: shapeUtilsWithDefault,
    bindingUtils: useMemo(() => [...defaultBindingUtils], []),
  });

  return { store, multiplayerAssets };
};
