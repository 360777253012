import { useAtom } from 'jotai';
import { useCallback, useEffect } from 'react';

import { TLUiEventHandler } from 'tldraw';
import { UiEventCallbacksAtom } from '../TlDrawCustomEditor';

/**
 * Hook to subscribe to UI events from anywhere, instead of having to go through 
 * the onUiEvent callback of the TlDrawUi component in TlDrawCustomEditor. 
 * 
 * @Aurelien : ça vaudrait le coup que tu checkes si ça la logique est saine, avec l'atom et tout
 */
export const useOnUiEvent = (callback: TLUiEventHandler) => {
  //get the atom
  const [, setCallbacks] = useAtom(UiEventCallbacksAtom);

  //conserve referential equality
  const callBack = useCallback(callback, []);

  // add the callback to the atom on mount and remove it on unmount
  useEffect(() => {
    setCallbacks((prev) => [...prev, callBack]);

    return () => {
      setCallbacks((prev) => prev.filter((cb) => cb !== callBack));
    };
  }, [callBack]);
};
