import { faTrashCan } from '@fortawesome/free-solid-svg-icons';

import useDeleteImageInAlbum from '../../../../hooks/albums/useDeleteImageInAlbum';
import AlbumImage from './AlbumImage';
import Button from '@/components/lib/Button';

type AlbumImageProps = {
  albumId: string;
  imageId: string;
};

const DeletableAlbumImage = ({ albumId, imageId }: AlbumImageProps) => {
  const { deleteImageFromAlbum } = useDeleteImageInAlbum(albumId, imageId);

  return (
    <div className='relative h-full w-full'>
      <AlbumImage albumId={albumId} imageId={imageId} />

      <div
        className='absolute bottom-full left-full flex -translate-x-2/3 translate-y-2/3
          text-text-base'>
        <Button
          onClick={() => {
            deleteImageFromAlbum();
          }}
          size={'xs'}
          variant={'danger'}
          iconSize='9px'
          leftIcon={faTrashCan}
        />
      </div>
    </div>
  );
};

export default DeletableAlbumImage;
