import {
  faFileCircleQuestion,
  faImages,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';

import { useGetWhiteboards } from '../../../hooks/whiteboard/useGetWhiteboards';
import { WhiteboardItem } from './WhiteboardItem';
import { useHasRole } from '@/hooks/useHasRole';
import { UserRole } from '@/types';
import { useGetLastUpdatedWhiteboard } from '@/hooks/whiteboard/useGetLastUpdatedWhiteboard';
import Tabs from '@/components/lib/Tabs/Tabs';
import { useTranslation } from 'react-i18next';
import Collection from '@/components/lib/Containers/Collection';
/* import PresentationSwitch from '../common/PresentationSwitch'; */
import { memo, useMemo, useState } from 'react';
import { NewWhiteboardForm } from '@/components/Common/ItemsForms/NewWhiteboardForm';
import BlurModal from '@/components/lib/Containers/BlurModal';

import { PageHeader } from '../common/PageHeader';
import { useIsScreen } from '@/hooks/useScreenQuery';

const Whiteboards = () => {
  const { whiteboard } = useGetLastUpdatedWhiteboard();
  const { t } = useTranslation('dashboard');
  const hasRole = useHasRole();
  const hasProjectsRole = hasRole(UserRole.Projects);
  const [presentation /*  setPresentation */] = useState<'grid' | 'list'>(
    'grid'
  );

  const [showCreateWhiteboardModal, setShowCreateWhiteboardModal] =
    useState(false);

  const isScreen = useIsScreen();

  const tabs = useMemo(
    () => [
      {
        content: <VisibleWhiteboards presentation={presentation} />,
        id: 'visible',
        title: t('items.whiteboards', { count: 2 }),
        icon: faImages,
      },
      {
        content: <ArchivedWhiteboards presentation={presentation} />,
        id: 'archived',
        title: t('common.archived', { count: 2 }),
        icon: faTrashCan,
      },
      {
        content: <UnClassifiedWhiteboards presentation={presentation} />,
        id: 'unclassified',
        title: t('common.noFolder'),
        icon: faFileCircleQuestion,
      },
    ],
    [presentation, t]
  );

  if (!hasProjectsRole) {
    return (
      <Collection>
        {whiteboard && <WhiteboardItem whiteboardId={whiteboard.id} />}
      </Collection>
    );
  }

  return (
    <section className='flex flex-col sm:gap-4 overflow-hidden'>
      <BlurModal
        isOpen={showCreateWhiteboardModal}
        setIsOpen={setShowCreateWhiteboardModal}>
        <NewWhiteboardForm
          onClose={() => {
            setShowCreateWhiteboardModal(false);
          }}
        />
      </BlurModal>

      <Tabs
        className='overflow-auto'
        tabs={tabs}
        selectorId='whiteboars-nav'
        TabHeader={({ tabNav }) => (
          <PageHeader
            tabNav={tabNav}
            /*    presentationSwitch={
              <PresentationSwitch
                presentation={presentation}
                setPresentation={setPresentation}
              />
            } */
          >
            <Button
              rightIcon={faPlus}
              variant='accent'
              size='large'
              showLabel={!isScreen}
              onClick={() => setShowCreateWhiteboardModal(true)}>
              {t('newItems.newWhiteboard')}
            </Button>
          </PageHeader>
        )}
      />
    </section>
  );
};

const ArchivedWhiteboards = memo(
  ({ presentation = 'grid' }: { presentation?: 'grid' | 'list' }) => {
    const { whiteboards } = useGetWhiteboards('archived');

    return (
      <Collection presentation={presentation}>
        {whiteboards &&
          whiteboards.map((whiteboard) => (
            <WhiteboardItem
              key={whiteboard.id}
              whiteboardId={whiteboard.id}
              presentation={presentation}
            />
          ))}
      </Collection>
    );
  }
);

const VisibleWhiteboards = memo(
  ({ presentation = 'grid' }: { presentation?: 'grid' | 'list' }) => {
    const { whiteboards } = useGetWhiteboards('visible');

    return (
      <Collection presentation={presentation}>
        {whiteboards &&
          whiteboards.map((whiteboard) => (
            <WhiteboardItem
              key={whiteboard.id}
              whiteboardId={whiteboard.id}
              presentation={presentation}
            />
          ))}
      </Collection>
    );
  }
);

const UnClassifiedWhiteboards = memo(
  ({ presentation = 'grid' }: { presentation?: 'grid' | 'list' }) => {
    const { whiteboards } = useGetWhiteboards('visible', true);

    return (
      <Collection presentation={presentation}>
        {whiteboards &&
          whiteboards.map((whiteboard) => (
            <WhiteboardItem
              key={whiteboard.id}
              whiteboardId={whiteboard.id}
              presentation={presentation}
            />
          ))}
      </Collection>
    );
  }
);

export default Whiteboards;
