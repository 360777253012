import { CreationCard } from '../../Creations/CreationCard';

import { useGetProjectGenerations } from '@/hooks/projects/useGetProjectGenerations';
import Collection from '@/components/lib/Containers/Collection';

interface AlbumTabProps {
  projectId: string;
}

const CreationTab = ({ projectId }: AlbumTabProps) => {
  const { allGenerations } = useGetProjectGenerations(projectId);

  //map over the whiteboards and get all the creations
  return (
    <Collection>
      {allGenerations.map((generation) => {
        return <CreationCard key={generation.id} creationId={generation.id} />;
      })}
    </Collection>
  );
};
export default CreationTab;
