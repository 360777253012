import { StateNode, TLStateNodeConstructor } from '@tldraw/editor';
import { Pointing } from './toolStates/Pointing';
import { GeoIdle } from '../TldrawExports';

// Geo tools is special because it's in fact the same tool and shape util for every different kind of shape
// The only thing that changes is the style of the shape, stored in the props of GeoShapeUtil
/** @public */
export class CustomRectangleShapeTool extends StateNode {
  static override id = 'rectangleToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomEllipseShapeTool extends StateNode {
  static override id = 'ellipseToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomTriangleShapeTool extends StateNode {
  static override id = 'triangleToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomDiamondShapeTool extends StateNode {
  static override id = 'diamondToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomHexagonShapeTool extends StateNode {
  static override id = 'hexagonToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomStarShapeTool extends StateNode {
  static override id = 'starToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomCloudShapeTool extends StateNode {
  static override id = 'cloudToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomOvalShapeTool extends StateNode {
  static override id = 'ovalToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomRhombusShapeTool extends StateNode {
  static override id = 'rhombusToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomXBoxShapeTool extends StateNode {
  static override id = 'x-boxToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomCheckBoxShapeTool extends StateNode {
  static override id = 'check-boxToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomArrowLeftShapeTool extends StateNode {
  static override id = 'arrow-leftToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomArrowUpShapeTool extends StateNode {
  static override id = 'arrow-upToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomArrowDownShapeTool extends StateNode {
  static override id = 'arrow-downToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}

/** @public */
export class CustomArrowRightShapeTool extends StateNode {
  static override id = 'arrow-rightToolCustom';
  static override initial = 'idle';
  static override children(): TLStateNodeConstructor[] {
    return [GeoIdle, Pointing];
  }
  override shapeType = 'geoCustom';
}
