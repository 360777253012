import {
  createShapePropsMigrationIds,
  createShapePropsMigrationSequence,

  ImageShapeUtil,
  RecordProps,
  T,
  TLDrawShapeSegment,
  tlenv,
  TLImageShape,
  TLImageShapeProps,
  TLResizeInfo,
} from 'tldraw';
import {
  getEraserMask,
  getEraserMaskDataUrl,
  resizeEraserPaths,
} from '../CustomTools/sharedFeatures/EraserMaskFeature';
import { JSX } from 'react/jsx-runtime';


type CustomImageShapeProps = TLImageShapeProps & {
  eraserPaths: EraserPathSegment[];
};

export type TLImageShapeCustom = TLImageShape & {
  props: CustomImageShapeProps;
};

export type EraserPathSegment = {
  width: number;
  segment: TLDrawShapeSegment;
};
const imageMigrationVersions = createShapePropsMigrationIds('image', {
  AddUrlProp: 1,
  AddCropProp: 2,
  MakeUrlsValid: 3,
  AddFlipProps: 4,
  AddEraser: 5,
});

const imageMigrations = createShapePropsMigrationSequence({
  sequence: [
    {
      id: imageMigrationVersions.AddUrlProp,
      up: (props) => {
        props.url = '';
      },
      down: 'retired',
    },
    {
      id: imageMigrationVersions.AddCropProp,
      up: (props) => {
        props.crop = null;
      },
      down: (props) => {
        delete props.crop;
      },
    },
    {
      id: imageMigrationVersions.MakeUrlsValid,
      up: (props) => {
        if (!T.linkUrl.isValid(props.url)) {
          props.url = '';
        }
      },
    },
    {
      id: imageMigrationVersions.AddFlipProps,
      up: (props) => {
        props.flipX = false;
        props.flipY = false;
      },
      down: (props) => {
        delete props.flipX;
        delete props.flipY;
      },
    },
    {
      id: imageMigrationVersions.AddEraser,

      up(props) {
        props.eraserPaths = [];
      },
      down(props) {
        delete props.eraserPaths;
      },
    },
  ],
});

export class CustomImageShapeUtil extends ImageShapeUtil {
  override getDefaultProps(): CustomImageShapeProps {
    return {
      ...super.getDefaultProps(),

      eraserPaths: [],
    };
  }

  static override props: RecordProps<TLImageShapeCustom> = {
    ...super.props,

    ...{
      eraserPaths: T.any,
    },
  };

  static override migrations = imageMigrations;

  override async toSvg(shape: TLImageShape): Promise<JSX.Element | null> {
    const baseSvg = await super.toSvg(shape);
    if (!baseSvg) return null;

    return (
      <svg mask={'url(#eraserMask' + shape.id + ')'}>
        <baseSvg.type {...baseSvg.props} />

        {getEraserMask(this.editor, shape as TLImageShapeCustom)}
      </svg>
    );
  }

  override onResize(shape: TLImageShape, info: TLResizeInfo<TLImageShape>) {
    // Super
    const baseShapeResized = super.onResize(shape, info);

    // Casting the shape to our custom shape
    const customShape = shape as unknown as TLImageShapeCustom;

    const newEraserSegments = resizeEraserPaths(customShape, info);

    // Returning the new props with new eraser paths added
    return {
      ...baseShapeResized,
      props: { ...baseShapeResized.props, eraserPaths: newEraserSegments },
    };
  }

  override component(shape: TLImageShapeCustom) {
    const baseComponent = super.component(shape as TLImageShape);

    // if safari

    if (tlenv.isSafari) {
      const maskUrlData = getEraserMaskDataUrl(
        this.editor,
        shape as TLImageShapeCustom
      );

      return (
        <>
          <div
            style={{
              height: shape.props.h,
              width: shape.props.w,
              maskMode: 'luminance',
              maskImage: shape.props.eraserPaths.length
                ? 'url(' + maskUrlData + ')'
                : '',
            }}>
            <baseComponent.type {...baseComponent.props} />
          </div>
        </>
      );
    }

    return (
      <>
        <div
          /*  className='overflow-hidden' */

          style={{
            height: shape.props.h,
            width: shape.props.w,
            maskImage: shape.props.eraserPaths.length
              ? 'url(#eraserMask' + shape.id + ')'
              : '',
          }}>
          <baseComponent.type {...baseComponent.props} />
          <div className='absolute inset-0'>
            <svg width='100%' height='100%'>
              {getEraserMask(this.editor, shape as TLImageShapeCustom)}
            </svg>
          </div>
        </div>
      </>
    );
  }
}
