import { ImgHTMLAttributes } from 'react';

import ImageLoading from './ImageLoading';
/* import { useCreatorContext } from '../../../../pages/Creator'; */
import useEditorIO from '@/components/TlDraw/hooks/useEditorIO';

import useGetCreationImage from '@/hooks/creations/useGetCreationImage';

type ResultsGenerationImageProps = ImgHTMLAttributes<HTMLImageElement> & {
  isLoading?: boolean;
  imageId: string;
  creationId: string;
};

const ResultsGenerationImage = ({
  isLoading = false,
  imageId,
  creationId,

  ...rest
}: ResultsGenerationImageProps) => {
  const { image } = useGetCreationImage(creationId, imageId, false);

  const { importImageFromDOMElement } = useEditorIO();

  return !image || isLoading ? (
    <ImageLoading />
  ) : (
    <img
      {...rest}
      src={image}
      onClick={(e) => {
        importImageFromDOMElement(e.target as HTMLImageElement, 600);
      }}
    />
  );
};

export default ResultsGenerationImage;
