import { useState, useMemo } from 'react';
import { Action } from '../types';

/**
 * useRecentActions Hook
 *
 * This hook manages a list of recently used actions in local storage and provides
 * functions to access and update this list.
 *
 * @param {Action[]} actions - The complete list of available actions
 * @returns {Object} An object containing:
 *   - lastActionsItems: Array of recent actions
 *   - saveExecutedAction: Function to save an action to the recents list
 */
export const useRecentActions = (actions: Action[]) => {
  const [lastActions, setLastActions] = useState<string[]>(() => {
    return JSON.parse(localStorage.getItem('executedActions') || '[]');
  });

  const saveExecutedAction = (actionId: string) => {
    const executedActions = JSON.parse(
      localStorage.getItem('executedActions') || '[]'
    );

    if (executedActions.includes(actionId)) return;

    const newExecutedActions = [actionId, ...executedActions].slice(0, 5);
    localStorage.setItem('executedActions', JSON.stringify(newExecutedActions));
    setLastActions(newExecutedActions);
  };

  const lastActionsItems = useMemo(() => {
    return lastActions
      .map((actionId) => actions.find((a) => a.id === actionId))
      .filter(Boolean) as Action[];
  }, [lastActions, actions]);

  return {
    lastActionsItems,
    saveExecutedAction,
  };
};
