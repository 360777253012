import PanelHeader from './PanelHeader';
import { usePanelContext } from './context/usePanelContext';
import AllGenerations from './Results/AllGenerations';
import { Generate } from './Generate/Generate';
import { BeinkPhase } from '../../../types';
import { AnimatePresence, motion } from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import useOverflowDetection from '../../../hooks/useOverflowDetection';

const PanelContent = () => {
  const { phase, isPanelOpen } = usePanelContext();
  const { isOverflowing, contentRef } = useOverflowDetection();

  return (
    <motion.section
      layout
      className={`relative bg-surface-secondary flex h-full max-h-none w-min flex-col items-center
        text-text-base overflow-hidden shadow-md `}>
      <AnimatePresence>
        {/* OVERFLOWING INDICATORS */}
        {isOverflowing && (
          <>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='from-surface-secondary absolute top-0 left-0 z-500 h-24 w-full bg-linear-to-b
                from-70% to-transparent'></motion.div>
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className='from-surface-secondary absolute bottom-0 left-0 z-500 h-30 w-full bg-linear-to-t
                from-70% to-transparent'></motion.div>
          </>
        )}
      </AnimatePresence>
      {/* CONTENT */}
      <motion.div
        layoutScroll
        ref={contentRef}
        style={{ overflowY: isOverflowing ? 'scroll' : 'hidden' }}
        className={twMerge(
          `pointer-events-auto flex h-full flex-col overflow-x-hidden overflow-y-auto pt-0
          p-4 ${isPanelOpen ? 'w-[400px] max-w-[100%]' : 'w-min'}`,
          !isPanelOpen && 'px-2'
        )}>
        {<PanelHeader />}

        {phase === BeinkPhase.Generate && <Generate />}
        {phase === BeinkPhase.Results && <AllGenerations />}
      </motion.div>
    </motion.section>
  );
};

export default PanelContent;
