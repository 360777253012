import React, { ReactNode } from 'react';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Card from '@/components/lib/Containers/Card';

import useGetAlbum from '@/hooks/albums/useGetAlbum';
import { Album } from '@/types/api';
import AlbumImageGallery from '@/components/Creator/Panel/Album/AlbumImageGallery';
import RenamableAlbum from '@/components/Common/EditableItems/RenamableAlbum';

interface ModifyAlbumFormProps {
  albumId: string;
  onClose: (album?: Album) => void;
  integrated?: boolean;
}

export const ModifyAlbumForm = ({
  albumId,
  onClose,
  integrated = false,
}: ModifyAlbumFormProps) => {
  const { t } = useTranslation('dashboard');
  const { album } = useGetAlbum(albumId);

  const Wrapper = integrated ? React.Fragment : ModifyAlbumFormWrapper;

  if (!album) return null;

  return (
    <Wrapper onClose={onClose}>
      <Card.SubSectionHeader icon={faBookOpen}>
        {t('forms.album.name')}
      </Card.SubSectionHeader>
      <RenamableAlbum
        albumId={album.id}
        className='border-grey/50 bg-surface-primary/80 w-full rounded-xl border p-4'
      />

      <Card.SubSectionHeader icon={faBookOpen}>
        {t('forms.album.images')}
      </Card.SubSectionHeader>
      {/* IMAGES */}
      <AlbumImageGallery
        key={album.images.length}
        album={album}
        className='grid-cols-3'
      />
    </Wrapper>
  );
};

const ModifyAlbumFormWrapper = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <Card className='w-full max-w-100 overflow-auto'>
      <Card.Header icon={faBookOpen}>
        <div className='flex items-center justify-between gap-10'>
          {t('forms.album.modifyTitle')}
          <Card.CloseButton onClose={onClose} />
        </div>
      </Card.Header>
      <Card.Section>{children}</Card.Section>
    </Card>
  );
};
