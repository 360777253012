import { useAtom } from "jotai";
import { $generationsLeft } from '../store/generations-left';

//FIXME: this is the wrong way of doing thing because we loose the granularity of the store by adding stores together
//TODO: remove, use jotai instead
const useAppContext = () => {
  const [generationsLeft, setGenerationsLeft] = useAtom($generationsLeft);

  return { generationsLeft, setGenerationsLeft };
};
export default useAppContext;

