import { Loader2 } from 'lucide-react';
import useGetWhiteboardPreviewImage from '../../../hooks/whiteboard/useGetWhiteboardImage';
import { useTranslation } from 'react-i18next';

type WhiteboardImageProps = {
  whiteboardId: string;
};

export const WhiteboardImage = ({ whiteboardId }: WhiteboardImageProps) => {
  const { image, isLoading } = useGetWhiteboardPreviewImage(whiteboardId);
  const { t } = useTranslation('dashboard');

  if (isLoading)
    return (
      <div className='flex h-full items-center justify-center'>
        <Loader2 className='animate-spin' />
      </div>
    );

  if (!image)
    return (
      <div className='flex h-full items-center justify-center text-center'>
        {t('notFound.preview')}
      </div>
    );

  return (
    <img
      src={image}
      alt='Whiteboard Image'
      className='pointer-events-none h-full w-full rounded-xl object-cover'
    />
  );
};
