  import {
  useRef,
  useLayoutEffect,
  useDebugValue,
  useCallback,
  useMemo,
} from 'react';
import {
  TLOnMountHandler,
  useEditor,
  Editor,
  TldrawUi,
  DEFAULT_SUPPORT_VIDEO_TYPES,
  DEFAULT_SUPPORTED_IMAGE_TYPES,
  defaultBindingUtils,
  defaultShapeTools,
  defaultShapeUtils,
  defaultTools,
  TldrawHandles,
  TldrawScribble,
  TldrawSelectionBackground,
  TldrawSelectionForeground,
  TldrawShapeIndicators,
  registerDefaultExternalContentHandlers,
  registerDefaultSideEffects,
  TLExternalContentProps,
  useEditorComponents,
  useTldrawUiComponents,
  useToasts,
  useTranslation,
  TldrawProps,
  TLUiEventHandler,
  TLUiEventMap,
  TLUiEventData,
} from 'tldraw';

//This is a barebones version of the editor that allows for more customisation, different than TldrawEditor from "tldraw"
import { TldrawEditor } from '@tldraw/editor';

import { customAssetUrls, uiOverrides } from './config/UiOverrides';
import { customComponents } from './CustomComponents/CustomComponents';
import {
  customShapes,
  UNWANTED_DEFAULT_SHAPE_TYPES,
} from './config/CustomShapes';
import { customTools } from './config/CustomTools';
import { assert } from './helpers/svgInk';
import { atom, useAtom } from 'jotai';

import { lockMaxImageDimension } from './helpers/lockMaxImageScale';
import { registerCustomExternalContentHandlers } from './config/CustomExternalContentHandlers';

type TldrawCustomEditorProps = TldrawProps & {
  lockMaxImageScale?: boolean;
};

//Callback atom for UI events
export const UiEventCallbacksAtom = atom<TLUiEventHandler[]>([]);

//Exploded editor for more customisation
//This should be used for low level customisation. For our own features, put them in the TldrawWhiteboard component, it's better if we ever want to go back to the base TldrawEditor, uncustomized
//We can select which default shapes/tools/etc are included
//See https://tldraw.dev/examples/editor-api/exploded
//See the Tldraw component from the library for more info
// XXX: @Aurelien Dans la version 3.8 de Tldraw, ce n'est plus vraiment un problème de passer une shape qui existe déjà dans tldraw (https://github.com/tldraw/tldraw/releases/tag/v3.8.0)
//  "if you pass in a shape util that shares a type with a default, it now replaces the default instead of crashing." C'était une des raisons principales que j'ai utilisé cette version "explosée", mais ça n'en vaut peut-être plus le coup... A voir
export const TldrawCustomEditor = ({
  onMount,
  store,
  initialState,
  children,
  maxImageDimension,

  lockMaxImageScale,
}: TldrawCustomEditorProps) => {
  const componentsWithDefault = useMemo(
    () => ({
      Scribble: TldrawScribble,
      ShapeIndicators: TldrawShapeIndicators,
      CollaboratorScribble: TldrawScribble,
      SelectionForeground: TldrawSelectionForeground,
      SelectionBackground: TldrawSelectionBackground,
      Handles: TldrawHandles,
      /*      InFrontOfTheCanvas: ImagineContextToolbar, */
      ...customComponents,
    }),
    []
  );

  const defaultShapeUtilsWithoutUnwanted = useMemo(
    () =>
      defaultShapeUtils.filter(
        (shapeUtil) => !UNWANTED_DEFAULT_SHAPE_TYPES.includes(shapeUtil.type)
      ),
    []
  );

  const shapeUtilsWithDefault = useMemo(
    () => [...defaultShapeUtilsWithoutUnwanted, ...customShapes],
    []
  );

  const bindingUtilsWithDefaults = useMemo(() => [...defaultBindingUtils], []);

  const toolsWithDefaults = useMemo(
    () => [...defaultTools, ...defaultShapeTools, ...customTools],
    []
  );

  const _imageMimeTypes = useMemo(() => DEFAULT_SUPPORTED_IMAGE_TYPES, []);

  const _videoMimeTypes = useMemo(() => DEFAULT_SUPPORT_VIDEO_TYPES, []);

  const mediaMimeTypes = useMemo(
    () => [..._imageMimeTypes, ..._videoMimeTypes],
    []
  );

  /**
   * Run all the UI event callbacks.
   */
  const [uiEventCallBacks] = useAtom(UiEventCallbacksAtom);

  const handleUiEvents = useCallback<TLUiEventHandler>(
    <T extends keyof TLUiEventMap>(
      event: T,
      data: TLUiEventData<TLUiEventMap[T]>
    ) => {
      uiEventCallBacks.forEach((cb) => cb(event, data));
    },
    [uiEventCallBacks]
  );

  return (
    <TldrawEditor
      options={{
        maxPages: 1,
        maxPointsPerDrawShape: 5000,
        createTextOnCanvasDoubleClick: false,
      }}
      initialState={initialState}
      tools={toolsWithDefaults}
      shapeUtils={shapeUtilsWithDefault}
      store={store}
      bindingUtils={bindingUtilsWithDefaults}
      components={componentsWithDefault}>
      <TldrawUi
        onUiEvent={handleUiEvents}
        assetUrls={customAssetUrls}
        overrides={uiOverrides}
        components={componentsWithDefault}
        mediaMimeTypes={mediaMimeTypes}>
        <InsideOfEditorAndUiContext
          maxImageDimension={maxImageDimension}
          onMount={onMount}
          maxAssetSize={10 * 1024 * 1024}
          lockMaxImageScale={lockMaxImageScale}
          acceptedImageMimeTypes={_imageMimeTypes}
          acceptedVideoMimeTypes={_videoMimeTypes}
        />
        {children}
      </TldrawUi>
    </TldrawEditor>
  );
};

function useOnMount(onMount?: TLOnMountHandler) {
  const editor = useEditor();

  const onMountEvent = useEvent((editor: Editor) => {
    let teardown: (() => void) | void = undefined;
    // If the user wants to do something when the editor mounts, we make sure it doesn't effect the history.
    // todo: is this reeeeally what we want to do, or should we leave it up to the caller?
    editor.run(
      () => {
        teardown = onMount?.(editor);
        editor.emit('mount');
      },
      { history: 'ignore' }
    );
    window.tldrawReady = true;
    return teardown;
  });

  useLayoutEffect(() => {
    if (editor) return onMountEvent?.(editor);
  }, [editor, onMountEvent]);
}

function useEvent<Args extends Array<unknown>, Result>(
  handler: (...args: Args) => Result
): (...args: Args) => Result {
  const handlerRef = useRef<(...args: Args) => Result>();

  // In a real implementation, this would run before layout effects
  useLayoutEffect(() => {
    handlerRef.current = handler;
  });

  useDebugValue(handler);

  return useCallback((...args: Args) => {
    // In a real implementation, this would throw if called during render
    const fn = handlerRef.current;
    assert(fn, 'fn does not exist');
    return fn(...args);
  }, []);
}

// We put these hooks into a component here so that they can run inside of the context provided by TldrawEditor and TldrawUi.
function InsideOfEditorAndUiContext({
  maxImageDimension = 1000,
  lockMaxImageScale = false,

  maxAssetSize = 10 * 1024 * 1024, // 10mb
  acceptedImageMimeTypes = DEFAULT_SUPPORTED_IMAGE_TYPES,
  acceptedVideoMimeTypes = DEFAULT_SUPPORT_VIDEO_TYPES,
  onMount,
}: TLExternalContentProps & {
  onMount?: TLOnMountHandler;

  lockMaxImageScale?: boolean;
}) {
  const editor = useEditor();
  const toasts = useToasts();
  const msg = useTranslation();

  // Lock image to a maximum size
  if (lockMaxImageScale) {
    lockMaxImageDimension(editor, maxImageDimension);
  }

  useOnMount(() => {
    const unsubs: (void | (() => void) | undefined)[] = [];

    unsubs.push(registerDefaultSideEffects(editor));

    // for content handling, first we register the default handlers...
    registerDefaultExternalContentHandlers(
      editor,
      {
        maxImageDimension,
        maxAssetSize,
        acceptedImageMimeTypes,
        acceptedVideoMimeTypes,
      },
      {
        toasts,
        msg,
      }
    );

    //our own handlers
    registerCustomExternalContentHandlers(editor);

    // ...then we call the store's on mount which may override them...
    unsubs.push(editor.store.props.onMount(editor));

    // ...then we run the user's onMount prop, which may override things again.
    unsubs.push(onMount?.(editor));

    return () => {
      unsubs.forEach((fn) => fn?.());
    };
  });

  const { Canvas } = useEditorComponents();
  const { ContextMenu } = useTldrawUiComponents();

  if (ContextMenu) {
    // should wrap canvas
    return <ContextMenu />;
  }

  if (Canvas) {
    return <Canvas />;
  }

  return null;
}
