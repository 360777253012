import { useParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';
import { motion } from 'framer-motion';
import { useEffect } from 'react';

/**
 * Props for the Toggle component
 * @typedef {Object} ToggleProps
 * @property {(isOn: boolean) => void} [onChange] - Callback function triggered when the toggle value changes
 * @property {string} id - Unique identifier for the toggle, used to save the value in local storage per whiteboard
 * @property {boolean} [defaultValue=false] - Default value for the toggle
 * @property {string} [label] - Label text to display next to the toggle
 * @property {string} [labelPosition='right'] - Position of the label relative to the toggle ('left' or 'right')
 * @property {boolean} [disabled=false] - Whether the toggle is disabled
 */
type ToggleProps = {
  onChange?: (isOn: boolean) => void;
  id: string;
  defaultValue?: boolean;
  label?: string;
  labelPosition?: 'left' | 'right';
  disabled?: boolean;
};

/**
 * Interactive toggle switch component with local storage persistence.
 * Values are saved per whiteboard using the provided ID in local storage.
 *
 * The toggle features:
 * - Animated transition between on/off states
 * - Optional label that can be positioned on either side
 * - Persistent values per whiteboard ID
 * - Disabled state support
 * - Keyboard accessibility
 *
 * @param {ToggleProps} props - Component props
 * @returns {JSX.Element} The toggle component
 */
const Toggle = ({
  onChange,
  id,
  defaultValue = false,
  label,
  labelPosition = 'right',
  disabled = false,
}: ToggleProps) => {
  const { whiteboardId } = useParams<{ whiteboardId: string }>();

  // Store the value in local storage with a key that includes both the toggle ID and whiteboard ID
  const [isOn, setIsOn] = useLocalStorage<boolean>(
    `toggle-${id}-whiteboard-${whiteboardId}`,
    defaultValue
  );

  // Call onChange callback when isOn changes
  useEffect(() => {
    onChange?.(isOn);
  }, [isOn, onChange]);

  // Handle toggle click
  const handleToggle = () => {
    if (disabled) return;

    const newValue = !isOn;
    setIsOn(newValue);
    onChange?.(newValue);
  };

  // Handle keyboard events
  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (disabled) return;

    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleToggle();
    }
  };

  // Toggle component with label
  const toggleElement = (
    <div
      className={`inline-flex items-center
        ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      onClick={handleToggle}
      onKeyDown={handleKeyDown}
      tabIndex={disabled ? -1 : 0}
      role='switch'
      aria-checked={isOn}
      aria-disabled={disabled}>
      <motion.div
        style={{ justifyContent: isOn ? 'start' : 'end' }}
        className={`flex w-7 rounded-full px-3 py-1 items-center
          ${isOn ? 'bg-surface-tertiary' : 'bg-surface-tertiary/40'} transition-colors`}>
        <motion.div
          className='size-3 aspect-square rounded-full bg-surface-primary'
          layout
          transition={{ duration: 0.1 }}
        />
      </motion.div>

      {label && labelPosition === 'right' && (
        <span className='ml-2 text-xs'>{label}</span>
      )}
    </div>
  );

  // Render with label in correct position
  return (
    <>
      {label && labelPosition === 'left' && (
        <div className='flex items-center gap-2'>
          <span className='text-xs mr-2'>{label}</span>
          {toggleElement}
        </div>
      )}

      {(!label || labelPosition === 'right') && toggleElement}
    </>
  );
};

export default Toggle;
