import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCogs, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import Accordion, {
  AccordionHeaderProps,
} from '@/components/lib/Containers/Accordion';
import { motion } from 'framer-motion';
import Slider from '@/components/lib/Inputs/Slider';
import { SectionTitle } from '../Imagine';
import { useCallback, useEffect } from 'react';
import { usePanelInputContext } from '../../context/usePanelInputContext';
import Toggle from '@/components/lib/Inputs/Toggle';

export const AdvancedSettings = () => {
  const { t } = useTranslation(['panel']);
  const {
    creativityCursor,
    setCreativityCursor,
    setCreativityCursorOn,
    creativityCursorOn,
  } = usePanelInputContext();

  /**
   * Handle push back of the creativity cursor.
   * @param value
   */
  const handleCreativityCursorChange = useCallback(
    (value: number) => {
      setCreativityCursor(value);
    },
    [setCreativityCursor]
  );

  useEffect(() => {
    console.log('creativity cursor', creativityCursor);
  }, [creativityCursor]);

  return (
    <Accordion Header={AdvancedSettingsHeader} defaultExpanded={false}>
      <div
        className='flex flex-col gap-2'
        style={{ opacity: creativityCursorOn ? 1 : 0.7 }}>
        <motion.div layout className='pt-4 text-sm flex items-center gap-2'>
          <Toggle
            id='creativity-toggle'
            onChange={(isOn) => {
              setCreativityCursorOn(isOn);
            }}
          />
          {t('panel:Imagine.creativity')}
        </motion.div>
        <Slider
          labels={[
            t('panel:Imagine.respectful'),
            t('panel:Inspire.SliderLabels.Average'),
            t('panel:Imagine.creative'),
          ]}
          step={0.1}
          range={[0, 1]}
          id='creativity-cursor'
          defaultValue={0.5}
          onChange={handleCreativityCursorChange}
        />
      </div>
    </Accordion>
  );
};

const AdvancedSettingsHeader = ({ isExpanded }: AccordionHeaderProps) => {
  const { t } = useTranslation(['panel']);
  return (
    <SectionTitle>
      <div
        className='flex items-center gap-2 justify-between w-full'
        style={{
          opacity: isExpanded ? 1 : 0.8,
        }}>
        <div className='flex items-center gap-2'>
          <FontAwesomeIcon icon={faCogs} className='ml-2' />
          {t('Imagine.advancedSettings')}
        </div>

        <FontAwesomeIcon
          icon={isExpanded ? faMinus : faPlus}
          className='opacity-90 hover:opacity-100'
        />
      </div>
    </SectionTitle>
  );
};
