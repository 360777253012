const SidebarHeader = () => {
  return (
    <div className='hidden items-center justify-center p-2 py-4 sm:flex'>
      <img
        src='logo-white.svg'
        className='object-cover brightness-100'
        alt='logo'
      />
    </div>
  );
};

export default SidebarHeader;
