import { ReactNode, useCallback, useMemo, useState } from 'react';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Card from '@/components/lib/Containers/Card';
import Button from '@/components/lib/Button';
import ProjectDropdown from '@/components/Common/Dropdowns/ProjectDropdown';
import useGetAlbum from '@/hooks/albums/useGetAlbum';
import useAddAlbumToProjects from '@/hooks/albums/useAddAlbumToProjects';
import { Album } from '@/types/api';
import { type Option } from '@/components/lib/Dropdown/Dropdown';

type AddAlbumToProjectsFormProps =
  | {
      albumId: string;
      onClose: (album?: Album) => void;
      integrated?: false;
    }
  | {
      albumId: string;
      onClose?: never;
      integrated: true;
    };

const AlbumToProjectsFormContent = ({
  albumId,
  onClose,
}: {
  albumId: string;
  onClose?: (album?: Album) => void;
}) => {
  const { t } = useTranslation('dashboard');
  const { album } = useGetAlbum(albumId);
  const { addAlbumToProjects, isLoading } = useAddAlbumToProjects();
  const [selectedProjects, setSelectedProjects] = useState<string[]>([]);

  const handleSelectProjects = useCallback((options: Option[]) => {
    setSelectedProjects(options.map((option) => option.id));
  }, []);

  const handleSubmit = useCallback(async () => {
    if (selectedProjects.length === 0 || !album) return;

    await addAlbumToProjects(albumId, selectedProjects);
    onClose?.(album);
  }, [addAlbumToProjects, albumId, selectedProjects, onClose, album]);

  const initialValue = useMemo(() => [], []);
  if (!album) return null;

  // Get project IDs that the album is already part of
  const projectsToExclude = album.projects?.map((project) => project.id) || [];

  return (
    <>
      <ProjectDropdown
        onChange={handleSelectProjects}
        projectsToExclude={projectsToExclude}
        multipleSelect
        selectedItemsPosition={'aboveList'}
        initialValue={initialValue}
      />

      <Button
        variant='accent'
        onClick={handleSubmit}
        disabled={selectedProjects.length === 0 || isLoading}>
        {t('album.view.addToSelectedProjects')}
      </Button>
    </>
  );
};

export const AddAlbumToProjectsForm = ({
  albumId,
  onClose,
  integrated = false,
}: AddAlbumToProjectsFormProps) => {
  const { t } = useTranslation('dashboard');
  if (integrated) {
    return (
      <>
        <Card.SectionHeader>{t('album.view.addToProjects')}</Card.SectionHeader>
        <Card.Separator />
        <AlbumToProjectsFormContent albumId={albumId} onClose={onClose} />;
      </>
    );
  }

  return (
    <AddAlbumFormWrapper onClose={onClose!}>
      <AlbumToProjectsFormContent albumId={albumId} onClose={onClose} />
    </AddAlbumFormWrapper>
  );
};

const AddAlbumFormWrapper = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <Card className='max-w-[95vw] w-150 overflow-visible'>
      <Card.Header icon={faBookOpen}>
        <div className='flex items-center justify-between gap-10'>
          {t('album.view.addToProjects')}
          <Card.CloseButton onClose={onClose} />
        </div>
      </Card.Header>
      <Card.Section>{children}</Card.Section>
    </Card>
  );
};

export default AddAlbumToProjectsForm;
