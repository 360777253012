import { useQueryClient, useMutation } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';

const useAddWhiteboardToProject = () => {
  const queryClient = useQueryClient();
  const { addWhiteboardToProject: addWhiteboardToProjectAPI } =
    useWhiteboardAPI();

  const addWhiteboardToProjectMutation = useMutation({
    mutationFn: async ({
      whiteboardId,
      projectId,
    }: {
      whiteboardId: string;
      projectId: string;
    }) => {
      return await addWhiteboardToProjectAPI(whiteboardId, projectId);
    },
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['whiteboard', variables.whiteboardId]);
    },
    onSettled: (_, __, variables) => {
      queryClient.invalidateQueries(['project', variables.projectId]);
      queryClient.invalidateQueries(['whiteboard', variables.whiteboardId]);
    },
  });

  return {
    addWhiteboardToProject: (whiteboardId: string, projectId: string) =>
      addWhiteboardToProjectMutation.mutate({ whiteboardId, projectId }),
    isLoading: addWhiteboardToProjectMutation.isLoading,
    isError: addWhiteboardToProjectMutation.isError,
    error: addWhiteboardToProjectMutation.error,
  };
};

export default useAddWhiteboardToProject;
