import { ReactNode } from 'react';

export const PageHeader = ({
  tabNav,
  presentationSwitch,
  children,
}: {
  tabNav: ReactNode;
  presentationSwitch?: ReactNode;
  children: ReactNode;
}) => {
  return (
    <header
      className='xl:bg-surface-primary flex w-full flex-col xl:flex-row gap-2 items-center
        justify-between rounded-xl xl:p-6'>
      <div
        className='flex gap-4 bg-surface-primary w-full items-center overflow-y-auto p-2 rounded-xl
          xl:p-0'>
        {tabNav}
        {children}
      </div>
      {presentationSwitch}
    </header>
  );
};
