import { Modal, Dialog, ModalOverlay } from 'react-aria-components';
import { ReactNode } from 'react';

interface FullscreenModalProps {
  children: ReactNode;
  isOpen: boolean;

  fullScreen?: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const BlurModal = ({
  children,
  isOpen,
  setIsOpen,
  fullScreen = true,
}: FullscreenModalProps) => {
  if (fullScreen)
    return (
      <ModalOverlay
        isDismissable
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        className={
          'fixed inset-0 z-[10000] flex items-center justify-center bg-black/50 backdrop-blur-lg'
        }>
        <Modal>
          <Dialog>{children}</Dialog>
        </Modal>
      </ModalOverlay>
    );

  if (!isOpen) return null;

  return (
    <div className='absolute inset-0 z-[1000000] flex items-center justify-center bg-black/20 p-4 backdrop-blur-sm'>
      <Dialog>{children}</Dialog>
    </div>
  );
};

export default BlurModal;
