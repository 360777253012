import { useEffect } from 'react';

function useClickOutside(
  ref: React.RefObject<HTMLElement>,
  callback: () => void,
  closeButtonRef?: React.RefObject<HTMLElement>
) {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      /*  console.log(event.target, ref.current); */
      if (
        (ref.current && !ref.current.contains(event.target as Node)) ||
        (closeButtonRef &&
          closeButtonRef.current &&
          !closeButtonRef.current.contains(event.target as Node))
      ) {
        callback();
      }
    }

    window.addEventListener('mousedown', handleClickOutside);
    return () => {
      window.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
}

export default useClickOutside;
