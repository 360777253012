import { useContext } from 'react';
import { PanelContext } from './PanelContext';

/** Custom hook to access the Panel context
 * Context is used to persist state between mobile and desktop views
 *  */
export const usePanelContext = () => {
  const context = useContext(PanelContext);
  if (context === undefined) {
    throw new Error('usePanelContext must be used within a PanelContext');
  }
  return context;
};
