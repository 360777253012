import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '@/components/lib/Button';
import Card from '@/components/lib/Containers/Card';
import TextArea from '@/components/lib/Inputs/TextArea';

import useGetProjects from '@/hooks/projects/useGetProjects';
import useCreateProject from '@/hooks/projects/useCreateProject';

import useAddAlbumToProjects from '@/hooks/albums/useAddAlbumToProjects';

import useAddWhiteboardToProject from '@/hooks/whiteboard/useAddWhiteboardToProject';
import { Project } from '@/types/api';
import AlbumDropdown from '../Dropdowns/AlbumDropdown';
import WhiteboardDropdown from '../Dropdowns/WhiteboardDropdown';

import { type Option } from '@/components/lib/Dropdown/Dropdown';

interface ProjectFormProps {
  onClose: (project?: Project) => void;
  integrated?: boolean;
}

export const NewProjectForm = ({
  onClose,
  integrated = false,
}: ProjectFormProps) => {
  const { t } = useTranslation('dashboard');
  const [newProjectName, setNewProjectName] = useState('');
  const [newProjectDescription, setNewProjectDescription] = useState('');

  const { addAlbumToProjects } = useAddAlbumToProjects();
  const { addWhiteboardToProject } = useAddWhiteboardToProject();

  const [selectedAlbums, setSelectedAlbums] = useState<string[]>([]);
  const [selectedWhiteboards, setSelectedWhiteboards] = useState<string[]>([]);

  const onChangeAlbums = (options: Option[]) => {
    setSelectedAlbums(options.map((option) => option.id));
  };

  const onChangeWhiteboards = (options: Option[]) => {
    setSelectedWhiteboards(options.map((option) => option.id));
  };

  const { createProject } = useCreateProject((project) => {
    selectedAlbums.forEach((albumId) => {
      addAlbumToProjects(albumId, [project.id]);
    });
    selectedWhiteboards.forEach((whiteboardId) => {
      addWhiteboardToProject(whiteboardId, project.id);
    });

    onClose(project);
  });

  const { projects } = useGetProjects('archived');
  const { projects: visibleProjects } = useGetProjects('visible');
  const allProjects = useMemo(() => {
    return projects?.concat(visibleProjects ?? []);
  }, [projects, visibleProjects]);

  const isNewNameAlreadyTaken = useMemo(() => {
    return allProjects?.some(
      (project) => project.name.toLowerCase() === newProjectName.toLowerCase()
    );
  }, [allProjects, newProjectName]);

  const handleCreateProject = useCallback(() => {
    if (!isNewNameAlreadyTaken) {
      createProject(newProjectName);
    }
  }, [isNewNameAlreadyTaken, newProjectName]);

  const Wrapper = integrated ? React.Fragment : NewProjectFormWrapper;

  const albumInitialValue = useMemo(() => [], []);
  const whiteboardInitialValue = useMemo(() => [], []);

  return (
    <Wrapper onClose={onClose}>
      <Card.SubSectionHeader icon={faFolderOpen}>
        {t('forms.project.name')}
      </Card.SubSectionHeader>
      <TextArea
        rows={1}
        value={newProjectName}
        onChange={(e) => setNewProjectName(e.target.value)}
      />
      {isNewNameAlreadyTaken && (
        <div className='text-sm text-red-500'>
          {t('forms.project.nameExists')}
        </div>
      )}
      <Card.SubSectionHeader icon={faFolderOpen}>
        {t('forms.project.description')}
      </Card.SubSectionHeader>
      <TextArea
        value={newProjectDescription}
        onChange={(e) => setNewProjectDescription(e.target.value)}
      />
      <Card.SubSectionHeader icon={faFolderOpen}>
        {t('forms.project.addAlbums')}
      </Card.SubSectionHeader>
      <div className='relative'>
        <AlbumDropdown
          onChange={onChangeAlbums}
          multipleSelect={true}
          initialValue={albumInitialValue}
          selectedItemsPosition={'aboveList'}
        />
      </div>
      <Card.SubSectionHeader icon={faFolderOpen}>
        {t('forms.project.addWhiteboards')}
      </Card.SubSectionHeader>
      <div className='relative'>
        <WhiteboardDropdown
          onChange={onChangeWhiteboards}
          multipleSelect={true}
          initialValue={whiteboardInitialValue}
          selectedItemsPosition={'aboveList'}
        />
      </div>

      <Button
        onClick={handleCreateProject}
        variant='accent'
        state={newProjectName.length === 0 ? 'disabled' : 'default'}>
        {t('forms.project.create')}
      </Button>
    </Wrapper>
  );
};

const NewProjectFormWrapper = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <Card className='overflow-visible w-120 max-w-[95vw]'>
      <Card.Header icon={faFolderOpen}>
        <div className='flex items-center justify-between gap-10'>
          {t('forms.project.title')}
          <Card.CloseButton
            onClose={() => {
              onClose();
            }}
          />
        </div>
      </Card.Header>
      <Card.Section>{children}</Card.Section>
    </Card>
  );
};
