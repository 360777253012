import {
  faImages,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';

import useGetProjects from '@/hooks/projects/useGetProjects';
import { ProjectItem } from './ProjectItem';
import { useTranslation } from 'react-i18next';
import Tabs from '@/components/lib/Tabs/Tabs';
import Collection from '@/components/lib/Containers/Collection';
import Button from '@/components/lib/Button';
/* import PresentationSwitch from '../common/PresentationSwitch'; */
import { useMemo, useState } from 'react';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewProjectForm } from '@/components/Common/ItemsForms/NewProjectForm';

import { PageHeader } from '../common/PageHeader';
import { useIsScreen } from '@/hooks/useScreenQuery';

const Projects = () => {
  const { t } = useTranslation(['dashboard']);

  const [presentation /* , setPresentation */] = useState<'grid' | 'list'>(
    'grid'
  );

  const [showAddProjectModal, setShowAddProjectModal] = useState(false);

  const isLessThanScreen = useIsScreen();

  const tabs = useMemo(
    () => [
      {
        content: <VisibleProjects presentation={presentation} />,
        id: 'visible',
        title: t('items.projects_other'),
        icon: faImages,
      },
      {
        content: <ArchivedProjects presentation={presentation} />,
        id: 'archived',
        title: t('common.archived_other'),
        icon: faTrashCan,
      },
    ],
    [presentation, t]
  );

  return (
    <section className='flex flex-col gap-4 overflow-hidden'>
      <BlurModal
        isOpen={showAddProjectModal}
        setIsOpen={setShowAddProjectModal}>
        <NewProjectForm
          onClose={() => {
            setShowAddProjectModal(false);
          }}
        />
      </BlurModal>

      <Tabs
        className='overflow-auto'
        tabs={tabs}
        TabHeader={({ tabNav }) => {
          return (
            <PageHeader
              tabNav={tabNav}
              /* presentationSwitch={
                <PresentationSwitch
                  presentation={presentation}
                  setPresentation={setPresentation}
                />
              } */
            >
              <Button
                rightIcon={faPlus}
                variant='accent'
                size='large'
                showLabel={!isLessThanScreen}
                onClick={() => {
                  setShowAddProjectModal(true);
                }}>
                {t('newItems.newProject')}
              </Button>
            </PageHeader>
          );
        }}
      />
    </section>
  );
};

const ArchivedProjects = ({
  presentation = 'grid',
}: {
  presentation?: 'grid' | 'list';
}) => {
  const { projects } = useGetProjects('archived');

  if (presentation === 'list') return null;

  return (
    <Collection>
      {projects &&
        projects.map((project) => (
          <ProjectItem key={project.id} projectId={project.id} />
        ))}
    </Collection>
  );
};

const VisibleProjects = ({
  presentation,
}: {
  presentation?: 'grid' | 'list';
}) => {
  const { projects } = useGetProjects('visible');

  return (
    <Collection presentation={presentation}>
      {projects &&
        projects.map((project) => (
          <ProjectItem
            key={project.id}
            projectId={project.id}
            presentation={presentation}
          />
        ))}
    </Collection>
  );
};

export default Projects;
