import { faAnglesDown } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { BeinkPhase } from '../../../../types';
import Button from '../../../lib/Button';

import PanelNavigation from '../PanelNavigation';
import GenerateButton from '../GenerateButton';
import { usePanelContext } from '../context/usePanelContext';

const MobilePanelHeader = () => {
  const { setIsPanelOpen, setPhase } = usePanelContext();
  return (
    <motion.section
      className='bg-surface-secondary pointer-events-auto z-1000 flex w-[400px] max-w-screen
        items-center justify-between p-4'>
      {/* REDUCE BUTTON */}
      <motion.div
        layout
        className='items-centers flex'
        animate={{ rotate: 180 }}>
        <Button
          round
          variant='tertiary'
          iconSize='16px'
          leftIcon={faAnglesDown}
          onClick={() => {
            setIsPanelOpen((prev) => !prev);

            setPhase(BeinkPhase.Generate);
          }}
        />
      </motion.div>
      <PanelNavigation />
      <GenerateButton />
    </motion.section>
  );
};

export default MobilePanelHeader;
