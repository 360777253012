import { faTimes, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePanelContext } from './context/usePanelContext';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Button from '@/components/lib/Button';

export const ERROR_TYPE = {
  DEFAULT: 'default',
  WRONG_KEY: 'wrongKey',
  NO_ATTEMPTS_LEFT: 'noAttemptsLeft',
  UNSAFE: 'unsafe',
} as const;

type ObjectValues<T> = T[keyof T];

export type ErrorType = ObjectValues<typeof ERROR_TYPE>;

const ErrorContainer = ({ children }: { children: React.ReactNode }) => {
  const { setError } = usePanelContext();
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className='bg-surface-primary flex items-center justify-center gap-4 rounded-2xl p-4'>
      <Button
        className='absolute top-0 right-0'
        variant='primary'
        size='xs'
        iconSize='10px'
        round
        aria-label='Close'
        leftIcon={faTimes}
        onClick={() => setError(null)}
      />
      <div className='p-2'>
        <FontAwesomeIcon icon={faWarning} />
      </div>
      <div className='w-full items-center justify-center gap-4 text-sm tracking-tight'>
        {children}
      </div>
    </motion.div>
  );
};

const Error = () => {
  const { isPanelOpen, error, setError } = usePanelContext();
  const { t } = useTranslation(['panel']);

  useEffect(() => {
    let id: NodeJS.Timeout;

    if (error) {
      id = setTimeout(() => {
        setError(null);
      }, 10000);
    }
    return () => clearTimeout(id);
  }, [error]);

  const getContent = () => {
    switch (error) {
      case ERROR_TYPE.DEFAULT:
        return (
          <ErrorContainer>
            <p className='inline'>{t('panel:Error.default')}</p>
            <a
              className={'inline underline'}
              href='mailto:support@beink-dream.fr\'>
              {t('panel:Error.contact')}
            </a>
          </ErrorContainer>
        );
      case ERROR_TYPE.WRONG_KEY:
        return (
          <ErrorContainer>
            <p className='inline'>{t('panel:Error.wrongKey')}</p>
            <a
              className={'inline underline'}
              href='mailto:support@beink-dream.fr'>
              {t('panel:Error.contact')}
            </a>
          </ErrorContainer>
        );
      case ERROR_TYPE.NO_ATTEMPTS_LEFT:
        return (
          <ErrorContainer>
            <p className='inline'>{t('panel:Error.noAttemptLeft')}</p>
            <a
              className={'inline underline'}
              href='mailto:support@beink-dream.fr'>
              {t('panel:Error.contact')}
            </a>
          </ErrorContainer>
        );
      case ERROR_TYPE.UNSAFE:
        return (
          <ErrorContainer>
            <p className='inline'>{t('panel:Error.unsafe')}</p>
            <a
              className={'inline underline'}
              href='https://beink-dream.notion.site/Beink-Dream-Guide-des-Contenus-Acceptables-b1d03bfbc2ea4141b93c2dc0b30bc794?pvs=4'
              target='_blank'>
              {t('panel:Error.guidelines')}
            </a>
          </ErrorContainer>
        );
      default:
        return (
          <ErrorContainer>
            <p className='inline'>{t('panel:Error.default')}</p>
            <a
              className={'inline underline'}
              href='mailto:support@beink-dream.fr\'>
              {t('panel:Error.contact')}
            </a>
          </ErrorContainer>
        );
    }
  };

  return (
    <AnimatePresence>
      {error && isPanelOpen && getContent()}
      {error && !isPanelOpen && (
        <div
          className='flex aspect-square w-full flex-col items-center justify-center gap-4 rounded-xl
            border p-2'
          role='alert'>
          <FontAwesomeIcon icon={faWarning} />
        </div>
      )}
    </AnimatePresence>
  );
};

export default Error;
