import SidebarHeader from './SidebarHeader';
import SidebarLink from './SidebarLink';
import User from './User';

import { dashboardRoutes } from '../../../pages/router';

import { useHasRole } from '@/hooks/useHasRole';
import { useTranslation } from 'react-i18next';

import { motion } from 'framer-motion';

import useIsMobile from '@/hooks/useScreenQuery';

const Sidebar = () => {
  const hasRole = useHasRole();
  const { t } = useTranslation('dashboard');

  const isMobile = useIsMobile();

  return (
    <motion.div
      layout
      className='bg-surface-primary z-100 order-2 flex h-full w-full flex-col justify-between
        sm:order-0 sm:w-max'>
      <motion.div
        layout='position'
        className='flex h-full items-center justify-center py-2 sm:flex-col sm:justify-between'>
        <SidebarHeader />

        <div className='flex items-center justify-between w-full gap-0 px-3 sm:gap-2 sm:flex-col'>
          {dashboardRoutes.map((link) => {
            if (link.hideInSidebar) return null;
            if (link.necessaryRoles) {
              const hasRoles = link.necessaryRoles.every((role) =>
                hasRole(role)
              );
              if (!hasRoles) return null;
            }
            return (
              <SidebarLink
                key={link.path}
                activeSelectorId='sidebar'
                className='gap-3'
                to={link.path}
                icon={link.icon}>
                {t(link.label)}
              </SidebarLink>
            );
          })}
        </div>

        {!isMobile && <User />}
      </motion.div>
    </motion.div>
  );
};

export default Sidebar;
