import {
  faFolder,
  faLink,
  faPlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate } from 'react-router-dom';
import Button from '@/components/lib/Button';

import useGetProject from '@/hooks/projects/useGetProject';
import useArchiveProject from '@/hooks/projects/useArchiveProject';
import useRestoreProject from '@/hooks/projects/useRestoreProject';
import RenamableProject from '../../Common/EditableItems/RenamableProject';
import { WhiteboardImage } from '../Whiteboards/WhiteboardImage';
import { useTranslation } from 'react-i18next';
import Card, {
  NumberOfWhiteboardsTag,
  NumberOfAlbumsTag,
} from '@/components/lib/Containers/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { motion } from 'framer-motion';
import { Project } from '@/types/api';
import { useState } from 'react';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewWhiteboardForm } from '@/components/Common/ItemsForms/NewWhiteboardForm';

interface ProjectActions {
  onArchive: () => void;
  onRestore: () => void;
  onCopyLink: () => void;
  onNavigateToDetails: () => void;
}

interface ProjectViewProps {
  project: Project;
  actions: ProjectActions;
  presentation: 'grid' | 'list';
}

export const ProjectItem = ({
  projectId,
  presentation = 'grid',
}: {
  projectId: string;
  presentation?: 'grid' | 'list';
}) => {
  const navigate = useNavigate();
  const { project } = useGetProject(projectId);
  const { archiveProject } = useArchiveProject();
  const { restoreProject } = useRestoreProject();

  if (!project) return null;

  const actions: ProjectActions = {
    onArchive: () => archiveProject(projectId),
    onRestore: () => restoreProject(projectId),
    onCopyLink: () =>
      copyToClipboard(`${window.location.origin}/projects/${projectId}`),
    onNavigateToDetails: () => navigate('/projects/' + projectId),
  };

  return presentation === 'list' ? (
    <ProjectListView
      project={project}
      actions={actions}
      presentation={presentation}
    />
  ) : (
    <ProjectCardView
      project={project}
      actions={actions}
      presentation={presentation}
    />
  );
};

const ProjectListView = ({ project, actions }: ProjectViewProps) => {
  const { t } = useTranslation('dashboard');
  const { createdAt, status, whiteboards, albums } = project;
  const visibleWhiteboards =
    whiteboards?.filter((wb) => wb.status === 'VISIBLE') ?? [];

  return (
    <div
      className='bg-surface-primary col-span-3 grid grid-cols-subgrid items-center gap-6
        rounded-xl p-4'>
      <div className='flex items-center gap-3'>
        <FontAwesomeIcon icon={faFolder} className='text-accent text-xl' />
        <div className='flex flex-col'>
          <RenamableProject projectId={project.id} />
          <time className='text-text-secondary text-xs'>
            {new Date(createdAt).toLocaleDateString()}
          </time>
        </div>
      </div>

      <div className='flex items-center gap-4'>
        <div className='flex gap-2'>
          <NumberOfWhiteboardsTag number={visibleWhiteboards.length} />
          <NumberOfAlbumsTag number={albums?.length ?? 0} />
        </div>
      </div>

      <div className='flex justify-end gap-2'>
        <Button
          variant='danger'
          size='small'
          leftIcon={faTrash}
          onClick={
            status === 'VISIBLE' ? actions.onArchive : actions.onRestore
          }>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
        <Button
          variant='primary'
          size='small'
          leftIcon={faLink}
          onClick={actions.onCopyLink}
        />
        <Button
          variant='primary'
          size='small'
          onClick={actions.onNavigateToDetails}>
          {t('common.details')}
        </Button>
      </div>
    </div>
  );
};

const ProjectCardView = ({ project, actions }: ProjectViewProps) => {
  const { whiteboards, createdAt, status, albums } = project;
  const { t } = useTranslation('dashboard');
  const visibleWhiteboards =
    whiteboards?.filter((wb) => wb.status === 'VISIBLE') ?? [];

  const [createWhiteboardModalOpen, setCreateWhiteboardModalOpen] =
    useState(false);

  const navigate = useNavigate();

  return (
    <Card>
      <BlurModal
        isOpen={createWhiteboardModalOpen}
        setIsOpen={setCreateWhiteboardModalOpen}>
        <NewWhiteboardForm
          projectId={project.id}
          onClose={(whiteboard) => {
            if (whiteboard) {
              navigate(`/creator/${whiteboard.id}`);
            }
            setCreateWhiteboardModalOpen(false);
          }}
        />
      </BlurModal>

      <div className='h-min'>
        <Card.HeaderWithLink
          icon={faFolder}
          title={<RenamableProject projectId={project.id} />}
          linkTo={`/projects/${project.id}`}
        />
        <Card.TimeAgo date={new Date(createdAt)} />
      </div>

      <Card.Body>
        <div className='grid grid-cols-2 gap-3'>
          {visibleWhiteboards.slice(0, 3).map((whiteboard) => (
            <Link
              to={`/creator/${whiteboard.id}`}
              key={whiteboard.id}
              target='_blank'
              rel='noopener noreferrer'
              className='bg-surface-secondary aspect-16/14 w-full cursor-pointer rounded-xl'
              aria-label={`Open whiteboard ${whiteboard.name}`}>
              <WhiteboardImage whiteboardId={whiteboard.id} />
            </Link>
          ))}
          <motion.button
            aria-label='Create new whiteboard in this project'
            layout
            className='border-info-tag/20 text-info-tag flex aspect-16/14 cursor-pointer items-center
              justify-center rounded-xl border'
            onClick={() => setCreateWhiteboardModalOpen(true)}>
            <FontAwesomeIcon icon={faPlus} />
          </motion.button>
        </div>
      </Card.Body>

      <Card.Footer>
        <Card.Tags>
          <NumberOfWhiteboardsTag
            to={`/projects/${project.id}?tab=whiteboards`}
            number={visibleWhiteboards.length}
          />

          <NumberOfAlbumsTag
            number={albums?.length ?? 0}
            to={`/projects/${project.id}?tab=albums`}
          />
        </Card.Tags>
        <Card.DotMenu>
          <Button
            variant='danger'
            size='medium'
            aria-label={
              status === 'VISIBLE' ? t('common.archive') : t('common.restore')
            }
            leftIcon={faTrash}
            onClick={
              status === 'VISIBLE' ? actions.onArchive : actions.onRestore
            }>
            {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
          </Button>
          <Button
            variant='primary'
            size='medium'
            leftIcon={faLink}
            aria-label={t('common.copyLink')}
            onClick={actions.onCopyLink}>
            {t('common.copyLink')}
          </Button>
          <Button
            variant='primary'
            size='medium'
            aria-label={t('common.details')}
            onClick={actions.onNavigateToDetails}>
            {t('common.details')}
          </Button>
        </Card.DotMenu>
      </Card.Footer>
    </Card>
  );
};
