import { ReactNode, useState } from 'react';
import { motion } from 'framer-motion';

export type AccordionHeaderProps = {
  /**
   * Whether the accordion is currently expanded
   */
  isExpanded: boolean;
  /**
   * Function to update the expanded state
   */
  setIsExpanded: (value: boolean) => void;
};

/**
 * Props passed to a function child of the Accordion component
 */
export type AccordionChildrenProps = {
  /**
   * Whether the accordion is currently expanded
   */
  isExpanded: boolean;
};


type AccordionProps = {
  /**
   * The component to render as the clickable header
   * Will receive isExpanded and setIsExpanded as props
   */
  Header: React.FC<AccordionHeaderProps>;
  /**
   * The content to display when the accordion is expanded
   * Can be:
   * - A single ReactNode
   * - An array of ReactNodes
   * - A function component that receives { isExpanded } prop
   */
  children: ReactNode | ReactNode[] | React.FC<AccordionChildrenProps>;
  /**
   * Whether the accordion should be expanded by default
   * @default false
   */
  defaultExpanded?: boolean;
  /**
   * Optional ID for the accordion
   * Used for ARIA attributes to improve accessibility
   */
  id?: string;
};

/**
 * An accessible accordion component that can be toggled between expanded and collapsed states.
 * Supports multiple children formats including render props pattern.
 *
 * @example
 * Basic usage with a single child:
 * ```tsx
 * <Accordion Header={MyHeaderComponent} defaultExpanded={true}>
 *   <div>My accordion content</div>
 * </Accordion>
 * ```
 *
 * With render props to access the expanded state:
 * ```tsx
 * <Accordion Header={MyHeaderComponent} defaultExpanded={true}>
 *   {({ isExpanded }) => (
 *     <div style={{ opacity: isExpanded ? 1 : 0.7 }}>
 *       This content can react to the expanded state
 *     </div>
 *   )}
 * </Accordion>
 * ```
 */
const Accordion = ({
  Header,
  children,
  defaultExpanded = false,
  id,
}: AccordionProps) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);

  // Generate unique IDs for ARIA if not provided
  const accordionId =
    id || `accordion-${Math.random().toString(36).substring(2, 9)}`;
  const contentId = `${accordionId}-content`;
  const headerId = `${accordionId}-header`;

  const renderChildren = () => {
    if (typeof children === 'function') {
      // Pass the isExpanded state to the function component
      return (children)({ isExpanded });
    }
    return children;
  };

  return (
    <div className='w-full'>
      <div
        className='cursor-pointer'
        onClick={() => setIsExpanded(!isExpanded)}
        role='button'
        aria-expanded={isExpanded}
        aria-controls={contentId}
        id={headerId}
        tabIndex={0}
        onKeyDown={(e) => {
          if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault();
            setIsExpanded(!isExpanded);
          }
        }}>
        <Header isExpanded={isExpanded} setIsExpanded={setIsExpanded}></Header>
      </div>
      {isExpanded && (
        <motion.div
          id={contentId}
          role='region'
          aria-labelledby={headerId}
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.2 }}>
          {renderChildren()}
        </motion.div>
      )}
    </div>
  );
};

export default Accordion;
