import { ReactNode } from 'react';

const Collection = ({
  children,
  presentation = 'grid',
}: {
  children: ReactNode[] | ReactNode;
  presentation?: 'grid' | 'list';
}) => {
  return (
    <div className='@container h-full w-full overflow-hidden overflow-y-auto rounded-2xl'>
      <ul
        className={
          presentation === 'list'
            ? 'grid grid-cols-[min-content] gap-4'
            : '@8xl:grid-cols-5 grid grid-cols-1 gap-4 @xl:grid-cols-2 @4xl:grid-cols-3 @7xl:grid-cols-4'
        }>
        {children}
      </ul>
    </div>
  );
};

export default Collection;
