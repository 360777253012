import React, { ReactNode, useMemo, useState } from 'react';
import { faChalkboard } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '@/components/lib/Button';
import Card from '@/components/lib/Containers/Card';
import TextArea from '@/components/lib/Inputs/TextArea';
import { useCreateWhiteboard } from '@/hooks/whiteboard/useCreateWhiteboard';
import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';
import { Whiteboard } from '@/types/api';

interface WhiteboardFormProps {
  onClose: (whiteboardId?: Whiteboard) => void;
  projectId?: string;
  integrated?: boolean;
}

/**
 *
 * @param onClose
 * @param projectId
 * @param integrated
 * @constructor
 */
export const NewWhiteboardForm = ({
  onClose,
  projectId,
  integrated = false,
}: WhiteboardFormProps) => {
  const { t } = useTranslation('dashboard');
  const [newWhiteboardName, setNewWhiteboardName] = useState('');
  const [newWhiteboardDescription, setNewWhiteboardDescription] = useState('');
  const { createNewWhiteboard } = useCreateWhiteboard((whiteboard) => {
    onClose(whiteboard);
  });

  const { whiteboards } = useGetWhiteboards('archived');
  const { whiteboards: visibleWhiteboards } = useGetWhiteboards('visible');

  const allWhiteboards = useMemo(() => {
    return whiteboards?.concat(visibleWhiteboards ?? []);
  }, [whiteboards, visibleWhiteboards]);

  const isNewNameAlreadyTaken = useMemo(() => {
    return allWhiteboards?.some(
      (whiteboard) => whiteboard.name === newWhiteboardName
    );
  }, [allWhiteboards, newWhiteboardName]);


  const handleAddWhiteboard = () => {
    // TODO : Why not have duplicate whiteboard names ?
    if (!isNewNameAlreadyTaken) {
      createNewWhiteboard(newWhiteboardName, projectId);
    }
  };

  const Wrapper = integrated ? React.Fragment : NewWhiteboardFormWrapper;

  return (
    <Wrapper onClose={onClose}>
      <Card.SubSectionHeader icon={faChalkboard}>
        {t('forms.whiteboard.name')}
      </Card.SubSectionHeader>
      <TextArea
        rows={1}
        value={newWhiteboardName}
        onChange={(e) => setNewWhiteboardName(e.target.value)}
      />
      {isNewNameAlreadyTaken && (
        <div className='text-sm text-red-500'>
          {t('forms.whiteboard.nameExists')}
        </div>
      )}
      <Card.SubSectionHeader icon={faChalkboard}>
        {t('forms.whiteboard.description')}
      </Card.SubSectionHeader>
      <TextArea
        value={newWhiteboardDescription}
        onChange={(e) => setNewWhiteboardDescription(e.target.value)}
      />
      <Button
        onClick={handleAddWhiteboard}
        variant={'accent'}
        state={newWhiteboardName.length === 0 ? 'disabled' : 'default'}>
        {t('forms.whiteboard.create')}
      </Button>
    </Wrapper>
  );
};

/**
 *
 * @param children
 * @param onClose
 * @constructor
 */
const NewWhiteboardFormWrapper = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <Card className='overflow-auto'>
      <Card.Header icon={faChalkboard}>
        <div className='flex items-center justify-between gap-10'>
          {t('forms.whiteboard.title')}
          <Card.CloseButton onClose={() => onClose()} />
        </div>
      </Card.Header>
      <Card.Section>{children}</Card.Section>
    </Card>
  );
};
