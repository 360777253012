import { Dispatch, SetStateAction, useState, createContext } from 'react';

type AlbumContextType = {
  selectedAlbum: string | null;
  setSelectedAlbum: Dispatch<SetStateAction<string | null>>;

  albumToModify: string | null;
  setAlbumToModify: Dispatch<SetStateAction<string | null>>;
};

export const AlbumContext = createContext<AlbumContextType | undefined>(
  undefined
);

//TODO: this should probably be moved to jotai, and albumToModify should just be deleted
export const AlbumProvider = ({ children }: { children: React.ReactNode }) => {
  const [selectedAlbum, setSelectedAlbum] = useState<string | null>(null);
  const [albumToModify, setAlbumToModify] = useState<string | null>(null);

  return (
    <AlbumContext.Provider
      value={{
        albumToModify,
        setAlbumToModify,

        selectedAlbum,
        setSelectedAlbum,
      }}>
      {children}
    </AlbumContext.Provider>
  );
};
