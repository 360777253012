import { useCallback, useMemo } from 'react';
import { faBookOpen } from '@fortawesome/free-solid-svg-icons';

import useGetAlbums from '@/hooks/albums/useGetAlbums';
import Dropdown, {
  Option,
  DropdownProps,
} from '@/components/lib/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';

interface AlbumDropdownProps
  extends Omit<DropdownProps, 'options' | 'icon' | 'initialValue'> {
  albumIds?: string[];
  onSelectAlbums?: (albumIds: string[]) => void;
  status?: 'visible' | 'archived';
  albumsToExclude?: string[];

  projectId?: string;
  initialValue?: Option[];
}

export const AlbumDropdown = ({
  onSelectAlbums,
  status = 'visible',
  albumsToExclude,
  onChange,
  projectId,
  initialValue,
  ...dropdownProps
}: Omit<AlbumDropdownProps, 'placeholder'>) => {
  const { albums } = useGetAlbums(status, projectId);
  const { t } = useTranslation('dashboard');

  const handleChange = useCallback(
    (options: Option[]) => {
      const selectedIds = options.map((option) => option.id);
      onSelectAlbums?.(selectedIds);
      onChange?.(options);
    },
    [onChange, onSelectAlbums]
  );

  const albumsFiltered = albums?.filter((album) => {
    return !albumsToExclude?.includes(album.id);
  });

  const albumOptions: Option[] = useMemo(
    () =>
      albumsFiltered?.map((album) => ({
        id: album.id,
        label: album.name,
        value: album,
      })) ?? [],
    [albumsFiltered]
  );

  const initialValueInternal = useMemo(() => {
    if (initialValue) {
      return initialValue;
    }
    return [];
  }, [initialValue]);

  const placeholder = useMemo(() => t('choose.album'), [t]);

  return (
    <Dropdown
      options={albumOptions}
      search
      triggerStyles='border'
      isContentContained
      icon={faBookOpen}
      placeholder={placeholder}
      onChange={handleChange}
      initialValue={initialValueInternal}
      {...dropdownProps}
    />
  );
};

export default AlbumDropdown;
