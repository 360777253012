import React, { ReactNode, useMemo, useState } from 'react';
import { faBookOpen, faPlus } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

import Button from '@/components/lib/Button';
import Card from '@/components/lib/Containers/Card';
import TextArea from '@/components/lib/Inputs/TextArea';
import ImageDropzone from '@/components/lib/Inputs/ImageDropzone';
import useGetAlbums from '@/hooks/albums/useGetAlbums';
import useCreateAlbum from '@/hooks/albums/useCreateAlbum';
import { Album } from '@/types/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { addImagesDialog } from '@/helpers/fileHelpers';

interface AlbumFormProps {
  onClose: (album?: Album) => void;
  projectId?: string;
  integrated?: boolean;
}

export const NewAlbumForm = ({
  onClose,
  projectId,
  integrated = false,
}: AlbumFormProps) => {
  const { t } = useTranslation(['dashboard', 'panel']);
  const [newAlbumName, setNewAlbumName] = useState('');
  const [newAlbumDescription, setNewAlbumDescription] = useState('');
  const [images, setImages] = useState<File[]>([]);
  const { createAlbumFromImages } = useCreateAlbum((album) => {
    onClose(album);
  });

  const { albums } = useGetAlbums('archived');
  const { albums: visibleAlbums } = useGetAlbums('visible');

  const allAlbums = useMemo(() => {
    return albums?.concat(visibleAlbums ?? []);
  }, [albums, visibleAlbums]);

  const isNewNameAlreadyTaken = useMemo(() => {
    return allAlbums?.some((album) => album.name === newAlbumName);
  }, [allAlbums, newAlbumName]);

  const handleAddAlbum = () => {
    if (!isNewNameAlreadyTaken) {
      createAlbumFromImages(newAlbumName, images, projectId);
    }
  };

  const Wrapper = integrated ? React.Fragment : NewAlbumFormWrapper;

  const handleAddFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    addImagesDialog(async (files) => {
      if (files.length === 0) {
        return;
      }
      setImages((prev) => [...prev, ...files]);
    });
  };

  return (
    <Wrapper onClose={onClose}>
      <Card.SubSectionHeader icon={faBookOpen}>
        {t('forms.album.name')}
      </Card.SubSectionHeader>
      <TextArea
        rows={1}
        value={newAlbumName}
        onChange={(e) => setNewAlbumName(e.target.value)}
      />
      {isNewNameAlreadyTaken && (
        <div className='text-sm text-red-500'>
          {t('forms.album.nameExists')}
        </div>
      )}
      <Card.SubSectionHeader icon={faBookOpen}>
        {t('forms.album.description')}
      </Card.SubSectionHeader>
      <TextArea
        value={newAlbumDescription}
        onChange={(e) => setNewAlbumDescription(e.target.value)}
      />
      <Card.SubSectionHeader icon={faBookOpen}>
        {t('forms.album.images')}
      </Card.SubSectionHeader>
      <ImageDropzone
        className='p-1'
        disabled={images.length >= 5}
        onDrop={function (files: File[]): void {
          setImages((prev) => [...prev, ...files]);
        }}>
        <ul className='grid grid-cols-3 gap-4 w-full'>
          {images.length > 0 &&
            images.map((image) => (
              <li
                className='aspect-square w-full overflow-hidden rounded-xl'
                key={image.name}>
                <img
                  className='h-full w-full object-cover'
                  key={image.name}
                  alt={image.name}
                  src={URL.createObjectURL(image)}
                />
              </li>
            ))}
          {images.length < 5 && (
            <>
              <button
                onClick={handleAddFiles}
                className='border-grey flex aspect-square w-full cursor-pointer items-center justify-center
                  rounded-xl border border-dashed hover:opacity-50'>
                <FontAwesomeIcon icon={faPlus} className='text-2xl' />
              </button>
              {/* DROP CTA */}
              <div className='my-4 text-center text-sm col-span-full'>
                {t('panel:Inspire.NewAlbum.DropCTA')}
              </div>
            </>
          )}
        </ul>
      </ImageDropzone>

      <Button
        onClick={handleAddAlbum}
        variant={'accent'}
        state={newAlbumName.length === 0 ? 'disabled' : 'default'}>
        {t('forms.album.create')}
      </Button>
    </Wrapper>
  );
};

const NewAlbumFormWrapper = ({
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  return (
    <Card className='overflow-auto'>
      <Card.Header icon={faBookOpen}>
        <div className='flex items-center justify-between gap-10'>
          {t('forms.album.title')}
          <Card.CloseButton onClose={() => onClose()} />
        </div>
      </Card.Header>
      <Card.Section>{children}</Card.Section>
    </Card>
  );
};
