import {
  faBookOpen,
  faPlus,
  faTrashCan,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';
import Button from '@/components/lib/Button';
import useGetAlbums from '@/hooks/albums/useGetAlbums';
import { useTranslation } from 'react-i18next';
import Tabs from '@/components/lib/Tabs/Tabs';
import Collection from '@/components/lib/Containers/Collection';
import { AlbumItem } from '../Albums/AlbumItem';
/* import PresentationSwitch from '../common/PresentationSwitch'; */
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewAlbumForm } from '@/components/Common/ItemsForms/NewAlbumForm';
import { PageHeader } from '../common/PageHeader';
import { useIsScreen } from '@/hooks/useScreenQuery';

const Albums = () => {
  const [presentation /* , setPresentation */] = useState<'grid' | 'list'>(
    'grid'
  );
  const { t } = useTranslation('dashboard');
  const [showCreateAlbumModal, setShowCreateAlbumModal] = useState(false);

  const isLessThanScreen = useIsScreen();

  const tabs = useMemo(
    () => [
      {
        content: <VisibleAlbums presentation={presentation} />,
        id: 'visible',
        title: t('items.albums', { count: 2 }),
        icon: faBookOpen,
      },
      {
        content: <ArchivedAlbums presentation={presentation} />,
        id: 'archived',
        title: t('common.archived', { count: 2 }),
        icon: faTrashCan,
      },
    ],
    [presentation, t]
  );

  return (
    <section className='flex flex-col gap-4 overflow-hidden'>
      <BlurModal
        isOpen={showCreateAlbumModal}
        setIsOpen={setShowCreateAlbumModal}>
        <NewAlbumForm
          onClose={() => {
            setShowCreateAlbumModal(false);
          }}
        />
      </BlurModal>
      <Tabs
        className='overflow-auto'
        tabs={tabs}
        TabHeader={({ tabNav }) => (
          <PageHeader
            tabNav={tabNav}
            /*      presentationSwitch={
              <PresentationSwitch
                presentation={presentation}
                setPresentation={setPresentation}
              />
            } */
          >
            <Button
              rightIcon={faPlus}
              variant='accent'
              size='large'
              showLabel={!isLessThanScreen}
              onClick={() => setShowCreateAlbumModal(true)}>
              {t('newItems.newAlbum')}
            </Button>
          </PageHeader>
        )}
      />
    </section>
  );
};

const ArchivedAlbums = ({
  presentation = 'grid',
}: {
  presentation?: 'grid' | 'list';
}) => {
  const { albums } = useGetAlbums('archived');

  return (
    <Collection presentation={presentation}>
      {albums?.map((album) => (
        <AlbumItem
          key={album.id}
          albumId={album.id}
          presentation={presentation}
        />
      ))}
    </Collection>
  );
};

const VisibleAlbums = ({
  presentation = 'grid',
}: {
  presentation?: 'grid' | 'list';
}) => {
  const { albums } = useGetAlbums('visible');

  return (
    <Collection presentation={presentation}>
      {albums?.map((album) => (
        <AlbumItem
          key={album.id}
          albumId={album.id}
          presentation={presentation}
        />
      ))}
    </Collection>
  );
};

export default Albums;
