import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import { faChalkboard, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';

import Button from '@/components/lib/Button';

import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewWhiteboardForm } from '@/components/Common/ItemsForms/NewWhiteboardForm';
import { NewProjectForm } from '@/components/Common/ItemsForms/NewProjectForm';
import useClickOutside from '@/hooks/useClickOutside';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/**
 * Modal types supported by the CreateButton component
 */
type ModalType = 'whiteboard' | 'project' | null;

/**
 * CreateButton component that displays a dropdown menu with creation options
 *
 * Features:
 * - Displays a dropdown menu with options to create new items
 * - Supports creating whiteboards and projects
 * - Includes a quick access to the last whiteboard
 *
 */
const CreateButton = () => {
  const { t } = useTranslation('dashboard');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [modal, setModal] = useState<ModalType>(null);
  const navigate = useNavigate();
  /**
   * Handles closing both the modal and dropdown
   */
  const handleClose = () => {
    setModal(null);
    setIsDropdownOpen(false);
  };

  /**
   * Opens the specified modal and closes the dropdown
   * @param modalType - The type of modal to open ('whiteboard' or 'project')
   */
  const handleModalOpen = (modalType: NonNullable<ModalType>) => {
    setModal(modalType);
    setIsDropdownOpen(false);
  };

  const ref = useRef(null);

  useClickOutside(ref, () => setIsDropdownOpen(false));

  return (
    <div ref={ref} className='relative'>
      <BlurModal isOpen={!!modal} setIsOpen={handleClose}>
        {modal === 'whiteboard' && (
          <NewWhiteboardForm
            onClose={(whiteboard) => {
              if (whiteboard) {
                navigate(`/creator/${whiteboard.id}`);
              }
              handleClose();
            }}
          />
        )}
        {modal === 'project' && (
          <NewProjectForm
            onClose={(project) => {
              if (project) {
                navigate(`/projects/${project.id}`);
              }
              handleClose();
            }}
          />
        )}
      </BlurModal>

      {isDropdownOpen && (
        <ul
          style={{
            borderTopRightRadius: 0,
            backgroundPosition: 'right',
            backgroundSize: '300px',
          }}
          className='absolute p-4 rounded-2xl top-full right-0 w-max overflow-visible h-fit z-1000
            bg-gradient-to-r from-b-green to-b-purple'>
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            className='flex flex-col gap-2 w-full overflow-hidden'>
            <Link
              to='/creator'
              className='w-full'
              onClick={() => setIsDropdownOpen(false)}>
              <Button
                className='w-full'
                variant='secondary'
                size='large'
                leftIcon={faChalkboard}
                aria-label={t('header.create')}>
                {t('header.lastBoard')}
              </Button>
            </Link>
            <Button
              variant='secondary'
              size='large'
              leftIcon={faCirclePlus}
              onClick={() => handleModalOpen('whiteboard')}
              aria-label={t('header.create')}>
              {t('newItems.newWhiteboard')}
            </Button>
            <Button
              variant='secondary'
              size='large'
              leftIcon={faCirclePlus}
              onClick={() => handleModalOpen('project')}
              aria-label={t('header.create')}>
              {t('newItems.newProject')}
            </Button>
          </motion.div>
        </ul>
      )}
      <motion.button
        className='p-4 bg-gradient-to-r from-b-green to-b-purple rounded-2xl text-white flex
          items-center gap-2'
        animate={{
          borderBottomRightRadius: isDropdownOpen ? 0 : undefined,
          borderBottomLeftRadius: isDropdownOpen ? 0 : undefined,
          backgroundPosition: 'right',
          backgroundSize: '300px',
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        aria-label={t('header.create')}>
        <FontAwesomeIcon icon={faCirclePlus} />
        {t('header.create')}
      </motion.button>
    </div>
  );
};

export default CreateButton;
