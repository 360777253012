import { faImages } from '@fortawesome/free-solid-svg-icons';
import Button from '../../lib/Button';
import useGetCreation from '../../../hooks/creations/useGetCreation';
import { CreationImage } from './CreationImage';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Card from '@/components/lib/Containers/Card';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { CreationDetail } from './CreationDetail';
import { useQueryClient } from 'react-query';
import { useCreationsAPI } from '@/hooks/creations/useCreationsAPI';

type CreationCardProps = {
  creationId: string;
};

export const CreationCard = memo(({ creationId }: CreationCardProps) => {
  const { creation, isLoading } = useGetCreation(creationId);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const { t } = useTranslation('dashboard');
  const imagesIds = ['0', '1', '2', '3'];
  const queryClient = useQueryClient();
  const { getCreationImage } = useCreationsAPI();

  if (isLoading) return null;
  if (!creation) return <div>{t('notFound.creation')}</div>;

  //Prefetch the images on hover
  const prefetchData = async () => {
    for (const imageId of imagesIds) {
      queryClient.prefetchQuery({
        queryKey: ['albumImage', creationId, imageId, { small: false }],
        queryFn: () => getCreationImage(creationId, imageId, false),
      });
    }
  };

  return (
    <>
      <BlurModal isOpen={isDetailsOpen} setIsOpen={setIsDetailsOpen}>
        <CreationDetail
          creationId={creationId}
          onClose={() => setIsDetailsOpen(false)}
        />
      </BlurModal>

      <Card onMouseEnter={prefetchData}>
        <Card.HeaderWithLink
          icon={faImages}
          title={
            <span className='text-lg'>
              {creation.input.slice(0, 20) + '...'}
            </span>
          }
          onClick={() => setIsDetailsOpen(true)}
        />
        <Card.TimeAgo date={new Date(creation.createdAt)} />

        <Card.Body>
          <div
            className='grid grid-cols-2 gap-2 cursor-pointer'
            onClick={() => setIsDetailsOpen(true)}>
            {imagesIds.map((imageId, index) => (
              <CreationImage
                key={index + creationId}
                imageId={imageId}
                creationId={creationId}
              />
            ))}
          </div>
        </Card.Body>

        <Card.Footer>
          <Card.DotMenu>
            <Button
              className='w-full'
              variant='primary'
              onClick={() => setIsDetailsOpen(true)}>
              {t('common.details')}
            </Button>
          </Card.DotMenu>
        </Card.Footer>
      </Card>
    </>
  );
});
