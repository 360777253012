import { useAlbumContext } from '../../context/useAlbumContext';
import Dropdown, {
  DropdownHeaderProps,
  type Option,
} from '../../../../lib/Dropdown/Dropdown';
import {
  faBookOpen,
  faPlus,
  faUpRightFromSquare,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import useGetAlbums from '../../../../../hooks/albums/useGetAlbums';
import { motion } from 'framer-motion';

import { usePanelContext } from '../../context/usePanelContext';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { Link, useParams } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import Button from '@/components/lib/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProjectDropdown from '@/components/Common/Dropdowns/ProjectDropdown';
import useAddWhiteboardToProject from '@/hooks/whiteboard/useAddWhiteboardToProject';

const AlbumDropdown = () => {
  const { selectedAlbum, setSelectedAlbum, setAlbumToModify } =
    useAlbumContext();
  //Get the related whiteboard
  const { whiteboardId } = useParams<{ whiteboardId: string }>();
  const { whiteboard } = useGetWhiteboard(whiteboardId as string);

  const { albums, isLoading } = useGetAlbums(
    'visible',
    whiteboard?.project?.id
  );
  const { t } = useTranslation(['panel']);

  const triggerStyles = useMemo(() => {
    return selectedAlbum
      ? 'bg-surface-tertiary text-text-negative'
      : 'border-text-neutral border bg-transparent';
  }, [selectedAlbum]);

  const isWhiteboardInAProject = useMemo(() => {
    return whiteboard?.project?.id !== undefined;
  }, [whiteboard]);

  const albumOptions: Option[] = useMemo(() => {
    if (!albums) return [];
    if (whiteboard?.project?.id === undefined) return [];
    return albums.map((album) => ({
      id: album.id,
      label: album.name,
      value: album,
    }));
  }, [albums]);

  const handleAlbumSelectionChange = useCallback((options: Option[]) => {
    const value = options.length ? options[0].id : null;
    setSelectedAlbum(value);
    setAlbumToModify(value);
    localStorage.setItem(`imagineSelectedAlbum-${whiteboardId}`, value || '');
  }, []);

  const initialValue: Option[] = useMemo(() => {
    if (!albums) return [];

    if (!whiteboard?.project?.id) return [];

    const selectedAlbumFromStorage = localStorage.getItem(
      `imagineSelectedAlbum-${whiteboardId}`
    );
    if (selectedAlbumFromStorage) {
      setSelectedAlbum(selectedAlbumFromStorage);
    }

    const selectedAlbumData = albums.find(
      (album) => album.id === selectedAlbumFromStorage
    );
    if (selectedAlbumData) {
      return [
        {
          id: selectedAlbumData.id,
          label: selectedAlbumData.name,
        },
      ];
    }
    return [];
  }, [selectedAlbum, albums]);

  if (isLoading) return;
  if (!albums) return;

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={initialValue}
        options={albumOptions}
        Header={(props) => (
          <AlbumDropdownHeader
            whiteboardId={whiteboardId as string}
            isWhiteboardInAProject={isWhiteboardInAProject}
            {...props}
          />
        )}
        search={whiteboard?.project?.id ? true : false}
        isContentContained={false}
        icon={faBookOpen}
        showSelectedWithinList={true}
        placeholder={t('panel:Inspire.AlbumPlaceholder')}
        triggerStyles={triggerStyles}
        onChange={handleAlbumSelectionChange}
        selectedItemsPosition={'aboveList'}
      />
    </div>
  );
};

const AlbumDropdownHeader = ({
  close,
  isWhiteboardInAProject,
  whiteboardId,
}: DropdownHeaderProps & {
  whiteboardId: string;
  isWhiteboardInAProject: boolean;
}) => {
  const { t } = useTranslation(['panel']);
  const { setShowCreateNewAlbum } = usePanelContext();

  const { addWhiteboardToProject } = useAddWhiteboardToProject();

  const onChange = useCallback((option: Option[]) => {
    console.log('option', option);
    addWhiteboardToProject(whiteboardId, option[0].id);
  }, []);

  const initialAlbumValue = useMemo(() => [], []);

  return (
    <motion.div
      layout
      className='flex w-full items-center justify-between px-4'>
      <motion.div layout className='font-semibold'>
        {isWhiteboardInAProject ? (
          t('panel:Inspire.AlbumDropdownHeader')
        ) : (
          <p
            className='p-4 border-text-accent border rounded-md flex flex-col justify-center
              items-center gap-4 text-text-accent'>
            <span className='font-normal flex items-center gap-4'>
              <FontAwesomeIcon icon={faWarning} />
              Ce tableau n'est pas dans un projet. Ajoutez le à un projet si
              vous voulez utiliser ses albums inspirationnels.
            </span>
            <Link
              to={'/whiteboards/' + whiteboardId}
              className='underline flex items-center gap-2'>
              Voir ce tableau <FontAwesomeIcon icon={faUpRightFromSquare} />
            </Link>

            <p className='text-base font-bold self-start'>
              Ajouter à un projet
            </p>

            <ProjectDropdown
              onChange={onChange}
              status='visible'
              initialValue={initialAlbumValue}
              multipleSelect={false}
              selectedItemsPosition='aboveList'
            />
          </p>
        )}
      </motion.div>
      {isWhiteboardInAProject && (
        <Button
          leftIcon={faPlus}
          variant='accent'
          onClick={() => {
            close && close();
            setShowCreateNewAlbum(true);
          }}
        />
      )}
    </motion.div>
  );
};

export default AlbumDropdown;
