import { ReactNode } from 'react';

export const ItemPageWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className='lg:grid h-full space-y-2 lg:grid-cols-[min-content_1fr] gap-10 overflow-y-auto
        lg:overflow-hidden'>
      {children}
    </div>
  );
};
