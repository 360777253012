import ResultsGenerationImage from './ResultsGenerationImage';

import { usePanelContext } from '../context/usePanelContext';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { t } from 'i18next';
import Button from '../../../lib/Button';
import { BeinkPhase } from '../../../../types';
import { twMerge } from 'tailwind-merge';
import { motion } from 'framer-motion';

import useGetCreations from '@/hooks/creations/useGetCreations';
import { useParams } from 'react-router-dom';

const LastGeneration = () => {
  const { whiteboardId } = useParams();
  const { creations } = useGetCreations(whiteboardId);

  const { isPanelOpen, setPhase, inProgressGeneration } = usePanelContext();
  const firstGeneration = creations[0] ?? inProgressGeneration ?? null;
  const imagesIds = ['0', '1', '2', '3'];

  return (
    <motion.div
      layout
      className={twMerge(
        'bg-surface-primary flex w-full flex-col items-center gap-3 rounded-xl p-2',
        !isPanelOpen && 'bg-transparent p-2'
      )}>
      {firstGeneration && (
        <motion.div
          layout
          className={twMerge(
            'grid w-full justify-center gap-2',
            isPanelOpen ? 'grid-cols-4' : 'grid-cols-1'
          )}>
          {imagesIds.map((imageId, index) => (
            <motion.div
              layout
              key={index}
              className='bg-surface-bg-secondary aspect-square cursor-pointer overflow-hidden rounded-lg'>
              <ResultsGenerationImage
                imageId={imageId}
                creationId={firstGeneration.id}

                isLoading={!!inProgressGeneration}
                className='h-full w-full object-cover'
              />
            </motion.div>
          ))}
        </motion.div>
      )}
      {isPanelOpen && (
        <Button
          variant='tertiary'
          size={'small'}
          onClick={() => setPhase(BeinkPhase.Results)}
          leftIcon={faEye}
          round>
          {t('panel:Results.seeAllGenerations')}
        </Button>
      )}
    </motion.div>
  );
};

export default LastGeneration;
