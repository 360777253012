import { useMutation, useQueryClient } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { Whiteboard } from '@/types/api';

const useUpdateWhiteboard = () => {
  const { updateWhiteboard: updateWhiteboardAPI } = useWhiteboardAPI();
  const queryClient = useQueryClient();

  const { mutate, isLoading, isError } = useMutation({
    mutationFn: async ({
      whiteboardId,
      whiteboard,
    }: {
      whiteboardId: string;
      whiteboard: Partial<Omit<Whiteboard, 'id'>>;
    }) => await updateWhiteboardAPI(whiteboardId, whiteboard),
    onMutate: async (variables) => {
      await queryClient.cancelQueries(['whiteboards']);
      await queryClient.cancelQueries(['whiteboard', variables.whiteboardId]);

      const previousWhiteboard = queryClient.getQueryData<Whiteboard>([
        'whiteboard',
        variables.whiteboardId,
      ]);

      if (previousWhiteboard) {
        queryClient.setQueryData<Whiteboard>(
          ['whiteboard', variables.whiteboardId],
          {
            ...previousWhiteboard,
            ...variables.whiteboard,
          }
        );
      }

      const previousWhiteboards = queryClient.getQueryData<Whiteboard[]>([
        'whiteboards',
      ]);
      if (previousWhiteboards) {
        queryClient.setQueryData<Whiteboard[]>(
          ['whiteboards'],
          previousWhiteboards.map((whiteboard) => {
            if (
              whiteboard.id === variables.whiteboardId &&
              variables.whiteboard.name
            ) {
              return { ...whiteboard, name: variables.whiteboard.name };
            }
            return whiteboard;
          })
        );
      }

      return previousWhiteboard;
    },
    onError: (_, variables, context) => {
      if (context) {
        queryClient.setQueryData<Whiteboard>(
          ['whiteboard', variables.whiteboardId],
          context
        );
      }
    },
    onSettled: () => {
      queryClient.invalidateQueries(['whiteboards']);
    },
  });

  return {
    updateWhiteboard: mutate,
    isLoading,
    isError,
  };
};

export default useUpdateWhiteboard;
