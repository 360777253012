import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CategoryType, Action } from '../types';
import { ActionItem } from './ActionItem';

/**
 * Props for CategorySection component
 */
interface CategorySectionProps {
  category: CategoryType;
  selectedId: string | null;
  onItemSelect: (id: string) => void;
  onItemClick: (item: Action) => void;
}

/**
 * CategorySection Component
 *
 * Displays a group of related actions in the combobox.
 * Each category has a header with an icon and title, followed by a list of action items.
 *
 * @component
 * @param {CategorySectionProps} props
 * @returns {React.ReactElement}
 */
export const CategorySection: React.FC<CategorySectionProps> = ({
  category,
  selectedId,
  onItemSelect,
  onItemClick,
}) => {
  return (
    <div className='flex flex-col gap-2 bg-surface-secondary p-2 rounded-xl'>
      <div
        className='flex items-center gap-2 p-2 text-text-accent font-bold border-b
          border-text-accent/50 text-text-secondary'>
        <FontAwesomeIcon icon={category.icon} />
        <span>{category.title}</span>
      </div>
      <div className='flex flex-col gap-1'>
        {category.items.map((item) => (
          <ActionItem
            key={item.id}
            item={item}
            icon={category.icon}
            isSelected={selectedId === item.id}
            onSelect={() => onItemSelect(item.id)}
            onClick={() => onItemClick(item)}
          />
        ))}
      </div>
    </div>
  );
};
