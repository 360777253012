import useRenameProject from '@/hooks/projects/useRenameProject';
import useGetProject from '@/hooks/projects/useGetProject';
import RenamableItem from '@/components/lib/RenamableItem';

type RenamableProjectProps = {
  projectId: string;
  className?: string;
};

const RenamableProject = ({ projectId, className }: RenamableProjectProps) => {
  const { project } = useGetProject(projectId);
  const { renameProject } = useRenameProject();

  const handleRename = async (newName: string) => {
    if (!project) return;
    await renameProject({
      projectId: project.id,
      newName,
    });
  };

  return (
    <RenamableItem
      initialName={project?.name}
      onRename={handleRename}
      className={className}
    />
  );
};

export default RenamableProject;
