import {
  ReactElement,
  ReactNode,
  useState,
  useEffect,
  KeyboardEvent,
  memo,
} from 'react';
import Button from '../Button';
import { useSearchParams } from 'react-router-dom';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';
import { capitalizeFirstLetter } from '@/helpers/string';
import { useIsScreen } from '@/hooks/useScreenQuery';
import { LayoutGroup, motion } from 'framer-motion';

/**
 * Props for the Tabs component
 * @template T - String type for tab IDs
 */
interface TabsProps<T extends string> {
  /** Array of Tab objects that define the content and properties of each tab */
  tabs: Tab<T>[];

  /** Optional CSS classes to apply to the tabs container */
  className?: string;

  /** Optional custom header component that receives the tab navigation */
  TabHeader?: React.FC<{
    tabNav: ReactElement;
    currentTab?: Tab<T>;
  }>;

  /** When true, only the active tab will show its label */
  reduceClosedTabs?: boolean;

  /** Callback function that is called when the active tab changes */
  onTabChange?: (activeTab: T) => void;

  /** Selector ID for animation purposes */
  selectorId?: string;

  /** URL parameter name to sync the active tab with (if specified) */
  urlParam?: string;

  /** Whether to sync the active tab with URL parameters */
  syncWithUrl?: boolean;
}

/**
 * Represents a single tab item
 * @template TabId - String type for the tab's ID
 */
export type Tab<TabId extends string> = {
  /** Unique identifier for the tab */
  id: TabId;
  /** Display text for the tab */
  title: string;
  /** FontAwesome icon to display alongside the title */
  icon: IconDefinition;
  /** Content to render when this tab is active */
  content: ReactNode;
};

type TabNavProps<T extends string> = {
  tabs: Tab<T>[];
  activeTab: T;
  onTabChange: (tabId: T) => void;
  reduceClosedTabs: boolean;
  selectorId?: string;
};

const TabNav = <T extends string>({
  tabs,
  activeTab,
  onTabChange,
  reduceClosedTabs,
  selectorId,
}: TabNavProps<T>) => {
  //Keyboard navigation for tabs
  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    //find the index of the active tab
    const index = tabs.findIndex((tab) => tab.id === activeTab);

    const tabsLength = tabs.length;
    let newIndex = index;

    switch (e.key) {
      case 'ArrowRight':
        newIndex = (index + 1) % tabsLength;
        e.preventDefault();
        break;
      case 'ArrowLeft':
        newIndex = (index - 1 + tabsLength) % tabsLength;
        e.preventDefault();
        break;
      case 'Home':
        newIndex = 0;
        e.preventDefault();
        break;
      case 'End':
        newIndex = tabsLength - 1;
        e.preventDefault();
        break;
      default:
        return;
    }

    // Focus the next tab button after selection
    onTabChange(tabs[newIndex].id);
  };

  return (
    <motion.nav
      layout
      className='flex gap-4'
      role='tablist'
      aria-label='Tabs navigation'
      onKeyDown={handleKeyDown}>
      <LayoutGroup>
        {tabs.map((tab) => {
          const isActive = activeTab === tab.id;
          const tabId = `tab-${tab.id}`;
          const panelId = `panel-${tab.id}`;

          return (
            <Button
              key={tab.id}
              title={tab.title}
              variant={'tab'}
              aria-label={`${tab.title} tab${isActive ? ', selected' : ''}`}
              selectorId={selectorId}
              state={isActive ? 'active' : 'default'}
              size='large'
              showLabel={reduceClosedTabs ? isActive : true}
              onClick={() => onTabChange(tab.id)}
              leftIcon={tab.icon}
              role='tab'
              id={tabId}
              aria-selected={isActive}
              aria-controls={panelId}
              /*  tabIndex ={isActive ? 0 : -1} */
            >
              {capitalizeFirstLetter(tab.title)}
            </Button>
          );
        })}
      </LayoutGroup>
    </motion.nav>
  );
};

/**
 * A customizable tabs component that supports icons and custom header layouts
 * The nav is automatically generated from the tabs array
 * @template T - String type for tab IDs
 * @param {tabs} tabs - Array of Tab objects that define the content and properties of each tab
 * @param {className} className - Optional CSS classes to apply to the tabs container
 * @param {TabHeader} TabHeader - Optional custom header component that receives the tab navigation
 * @param {reduceClosedTabs} reduceClosedTabs - When true, only the active tab will show its label
 * @param {selectorId} selectorId - Providing a selectorID will enable the selection background to animate between tab buttons
 * @param {urlParam} urlParam - The URL parameter name to use for syncing active tab state (defaults to "tab")
 * @param {syncWithUrl} syncWithUrl - Whether to sync the active tab with URL parameters (defaults to false)
 * @returns {ReactElement} The rendered tabs component
 *
 * @example
 * const tabs = [
 *   {
 *     content: <VisibleProjects />,
 *     id: 'visible',
 *     title: t('items.projects_other'),
 *     icon: faImages,
 *   },
 *   {
 *     content: <ArchivedProjects />,
 *     id: 'archived',
 *     title: t('common.archived_other'),
 *     icon: faTrashCan,
 *   },
 * ];
 *
 * return (
 *   <Tabs
 *     className='overflow-auto'
 *     tabs={tabs}
 *     syncWithUrl={true}
 *     urlParam="projectStatus"
 *     TabHeader={({ tabNav }) => {
 *       return (
 *         <header className=''>
 *           {tabNav}
 *           <div className="otherdiv"></div>
 *         </header>
 *       );
 *     }}
 *   />
 * );
 */
const Tabs = <T extends string>({
  tabs,
  className,
  TabHeader,
  reduceClosedTabs = false,
  selectorId,

  urlParam = 'tab',
  syncWithUrl = true,
  onTabChange,
}: TabsProps<T>) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [activeTab, setActiveTab] = useState<T>(tabs[0]?.id || ('' as T));
  const currentTab = tabs.find((tab) => tab?.id === activeTab) || tabs[0];
  const isMobile = useIsScreen();

  // Initialize active tab from URL if syncWithUrl is enabled
  useEffect(() => {
    if (syncWithUrl && tabs.length > 0) {
      const tabFromUrl = searchParams.get(urlParam) as T;
      // Only set from URL if it's a valid tab ID
      if (tabFromUrl && tabs.some((tab) => tab.id === tabFromUrl)) {
        setActiveTab(tabFromUrl);
      } else if (activeTab) {
        // If no valid tab in URL but we have an active tab, update URL
        updateUrlParam(activeTab);
      }
    }
  }, [tabs, syncWithUrl, urlParam]);

  const updateUrlParam = (tabId: T) => {
    if (syncWithUrl) {
      // Update only the specific tab parameter, preserving other parameters
      setSearchParams((prevParams) => {
        const newParams = new URLSearchParams(prevParams);
        newParams.set(urlParam, tabId);
        return newParams;
      });
    }
  };

  const handleTabChange = (tabId: T) => {
    setActiveTab(tabId);

    // Update URL if sync is enabled
    if (syncWithUrl) {
      updateUrlParam(tabId);
    }

    if (onTabChange) {
      onTabChange(tabId);
    }
  };

  return (
    <section
      className={twMerge(
        'flex flex-col gap-2 xl:gap-10 overflow-y-auto',
        className
      )}>
      {TabHeader ? (
        <TabHeader
          currentTab={currentTab}
          tabNav={
            <TabNav
              tabs={tabs}
              activeTab={activeTab}
              onTabChange={handleTabChange}
              selectorId={selectorId}
              reduceClosedTabs={isMobile ? true : reduceClosedTabs}
            />
          }
        />
      ) : (
        <div className='flex gap-4'>
          <TabNav
            tabs={tabs}
            activeTab={activeTab}
            onTabChange={handleTabChange}
            /*      selectorId={selectorId} */ /* This is too laggy */
            reduceClosedTabs={isMobile ? true : reduceClosedTabs}
          />
        </div>
      )}
      <div
        className='lg:h-full lg:overflow-hidden'
        role='tabpanel'
        id={`panel-${currentTab?.id}`}
        aria-labelledby={`tab-${currentTab?.id}`}
        tabIndex={0}>
        {currentTab?.content}
      </div>
    </section>
  );
};

export default memo(Tabs);
