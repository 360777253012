import { MutateOptions, useMutation, useQueryClient } from 'react-query';
import { useWhiteboardAPI } from './useWhiteboardAPI';
import { Whiteboard, WhiteboardShort } from '../../types/api';


/**
 * Hook giving the access to the whiteboard repository.
 *
 * @param onSuccess
 */
export const useCreateWhiteboard = (
  onSuccess?: ((arg0: Whiteboard) => void) | undefined
) => {
  const queryClient = useQueryClient();
  const { createWhiteboard } = useWhiteboardAPI();

  const createWhiteboardMutation = useMutation({
    mutationFn: async ({
      name,
      projectId,
      description,
    }: {
      name: string;
      projectId?: string;
      description?: string;
    }) => {
      const whiteboard = await createWhiteboard(name, projectId, description);
      return whiteboard;
    },
    onSuccess: (whiteboard) => {
      queryClient.setQueryData(['whiteboard', whiteboard.id], whiteboard);
      queryClient.setQueryData<WhiteboardShort[]>(
        ['whiteboards'],
        (oldData) => {
          const whiteboardShort = { id: whiteboard.id, name: whiteboard.name };
          if (!oldData) return [whiteboardShort];
          return [whiteboardShort, ...oldData];
        }
      );
      onSuccess?.(whiteboard);
    },
    onSettled: (_, __, { projectId }) => {
      if (projectId) queryClient.invalidateQueries(['project', projectId]);
      queryClient.invalidateQueries('whiteboards');
    },
  });

  return {
    createNewWhiteboard: (
      name?: string,
      projectId?: string,
      description?: string,
      /* @Aurelien, on se demandait un moment comment gérer des callbacks après une mutation réussie, la fonction mutate que retourne reactQuery accepte en fait des options, dont un onSuccess, 
      Il n'y a donc pas forcément besoin de passer un onSuccess directement dans le hook 
      J'aurais probablement pas le temps de refactoriser tout react query avant la fin, donc je pose ça là ;) !
      Je pense que ça vaut vraiment le coup de garder cette librairie, qui gère les refetchs, le cache, les optimistic updates, et pleins d'autre trucs automatiquement, plutôt que de recoder tout ça avec Redux */
      options?: MutateOptions<
        Whiteboard,
        unknown,
        { name: string; projectId?: string; description?: string },
        unknown
      >
    ) =>
      createWhiteboardMutation.mutate(
        {
          name:
            name ??
            new Date().toLocaleDateString() +
              ' - ' +
              new Date().toLocaleTimeString(),
          projectId,
          description,
        },
        options
      ),
    isLoading: createWhiteboardMutation.isLoading,
    isError: createWhiteboardMutation.isError,
    error: createWhiteboardMutation.error,
  };
};
