import { TLShapeId, useEditor } from 'tldraw';
import { useOnSelection } from './useOnSelection';
import { useState } from 'react';

export const useSelectionWatcher = () => {
  const editor = useEditor();
  const [hasObjectSelected, setHasSelected] = useState(false);
  const [hasImageSelected, setHasImageSelected] = useState(false);
  const [hasDrawingSelected, setHasDrawingSelected] = useState(false);
  // Parameter that records if all the masks are transparent up to the point
  // where the inpaint does not detect it.
  // So we need to report that all the drawings are transparent and so can't be processed.
  const [hasOnlyTransparentDrawing, setHasOnlyTransparentDrawing] =
    useState(false);

  useOnSelection((shapes: TLShapeId[]) => {
    setHasSelected(shapes.length > 0);

    let hasImageSelectedTemp = false;
    let hasDrawingSelectedTemp = false;
    let hasOnlyTransparentDrawing = true;

    shapes.forEach((shapeId) => {
      if (!editor) return;

      const shape = editor.getShape(shapeId);
      if (!shape) return;
      if (shape.type === 'image') {
        hasImageSelectedTemp = true;
      }
      // Here we enable also geometric shapes to be considered as a mask.
      else if ('drawCustom' === shape.type || 'geoCustom' === shape.type) {
        hasDrawingSelectedTemp = true;
        // TODO : Improve the opacity to ensure minimum opacity of the masks.
        if (shape.opacity < 0.15 && hasOnlyTransparentDrawing) {
          hasOnlyTransparentDrawing = false;
        }
      } else {
        console.log('Other shape : ' + shape.type);
      }

      setHasDrawingSelected(hasDrawingSelectedTemp);
      setHasImageSelected(hasImageSelectedTemp);
      setHasOnlyTransparentDrawing(hasOnlyTransparentDrawing);
    });
  }, editor);

  return {
    hasObjectSelected,
    hasImageSelected,
    hasDrawingSelected,
    hasOnlyTransparentDrawing,
  };
};
