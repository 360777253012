import useGetProject from '@/hooks/projects/useGetProject';
import { WhiteboardItem } from '../../Whiteboards/WhiteboardItem';
import Collection from '@/components/lib/Containers/Collection';

interface AlbumTabProps {
  projectId: string;
  presentation: 'grid' | 'list';
}

const WhiteboardTab = ({ projectId, presentation }: AlbumTabProps) => {
  const { project } = useGetProject(projectId);

  if (!project) return null;
  const { whiteboards } = project;

  return (
    <Collection presentation={presentation}>
      {whiteboards
        ?.sort((a, b) => {
          const aCreatedAt = new Date(a.updatedAt).getTime();
          const bCreatedAt = new Date(b.updatedAt).getTime();

          return bCreatedAt - aCreatedAt;
        })
        .map((whiteboard) => {
          if (whiteboard.status === 'ARCHIVED') return null;
          return (
            <WhiteboardItem
              presentation={presentation}
              whiteboardId={whiteboard.id}
            />
          );
        })}
    </Collection>
  );
};

export default WhiteboardTab;
