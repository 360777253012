import { Dispatch, SetStateAction, useState, createContext } from 'react';
import { DesignStyle } from '../../../../types';

type PanelInputContextType = {
  imagineInputText: string;
  setImagineInputText: Dispatch<SetStateAction<string>>;
  inpaintInputText: string;
  setInpaintInputText: Dispatch<SetStateAction<string>>;
  graphicStyle: DesignStyle | null;
  setGraphicStyle: Dispatch<SetStateAction<DesignStyle | null>>;
  albumCursor: number;
  setAlbumCursor: Dispatch<SetStateAction<number>>;
  creativityCursor: number | undefined;
  setCreativityCursor: Dispatch<SetStateAction<number | undefined>>;
  creativityCursorOn: boolean;
  setCreativityCursorOn: Dispatch<SetStateAction<boolean>>;
};

export const PanelInputContext = createContext<
  PanelInputContextType | undefined
>(undefined);

export const PanelInputProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [imagineInputText, setImagineInputText] = useState<string>('');
  const [inpaintInputText, setInpaintInputText] = useState<string>('');
  const [graphicStyle, setGraphicStyle] = useState<DesignStyle | null>(null);

  const [albumCursor, setAlbumCursor] = useState<number>(0);

  /* Add the creativity cursor */
  const [creativityCursor, setCreativityCursor] = useState<number>();
  const [creativityCursorOn, setCreativityCursorOn] = useState(false);

  return (
    <PanelInputContext.Provider
      value={{
        imagineInputText,
        setImagineInputText,
        inpaintInputText,
        setInpaintInputText,
        graphicStyle,
        setGraphicStyle,
        albumCursor,
        setAlbumCursor,
        creativityCursor,
        setCreativityCursor,
        creativityCursorOn,
        setCreativityCursorOn,
      }}>
      {children}
    </PanelInputContext.Provider>
  );
};
