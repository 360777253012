import { faClone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { motion } from 'framer-motion';
import {TextareaHTMLAttributes, useCallback, useRef, useState} from 'react';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  className?: string;
}

/**
 *
 * @param param0
 * @returns
 */
const TextArea = ({
  label,
  onChange,
  value,
  className,
  ...props
}: TextAreaProps) => {
  const { t } = useTranslation('panel');

  const [copyText, setCopyText] = useState(t('Copy.toClipboard'));

  /**
   *
   * @param e
   */
  const toClipboard = useCallback(() => {
    const textContent: string = value as string;
    if (textContent && textContent.length > 0) {
      const textBlob = new Blob([textContent], { type: 'text/plain' });
      const htmlBlob = new Blob([textContent], { type: 'text/html' });
      const clipboardItem = new ClipboardItem({
        [textBlob.type]: textBlob,
        [htmlBlob.type]: htmlBlob,
      });

      navigator.clipboard
        .write([clipboardItem])
        .then(() => {
          // Handle temporary button change
          setCopyText(t('Copy.copied'));
        })
        .catch((err) => {
          console.log(`An error occurred: ${err}`);
          setCopyText(t('Copy.error'));
        })
        .finally(() => {
          setTimeout(() => {
            setCopyText(t('Copy.toClipboard'));
          }, 1000);
        });
    }
  }, [value, t]);

  /**
   * WHY : In the whiteboard, when user pastes a content in the text area box,
   * it can also be caught by the whiteboard tldraw and also paste the content on the board.
   *
   * To avoid this, we catch the paste event and avoid propagating.
   * Manually sestup the content of the text area at the cursor position in the text area if selected.
   * This does not impact TLDraw when the text area is not on focus.
   */
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const handlePaste = useCallback((event: React.ClipboardEvent<HTMLTextAreaElement>) => {
    event.stopPropagation(); // Stop event from bubbling up
    event.preventDefault(); // Prevent default paste behavior

    // Get pasted text
    const pasteText = event.clipboardData.getData('text');

    // Inject the text at the right position in text
    if (textAreaRef.current) {
      const cursorPos = textAreaRef.current.selectionStart;
      const text = textAreaRef.current.value;
      textAreaRef.current.value =
        text.slice(0, cursorPos) + pasteText + text.slice(cursorPos);

      // Force an onChange event to be triggered.
      const eventChange = new Event('change', {bubbles: true});
      textAreaRef.current.dispatchEvent(eventChange);
      if (onChange) {
        // Force change event to happen too. Not working without.
        onChange(eventChange as unknown as React.ChangeEvent<HTMLTextAreaElement>);
      }
    }
  }, [onChange, textAreaRef]);

  // TODO onclick for interval visible.

  return (
    <motion.div layout className='flex flex-col gap-4'>
      {label && (
        /* TODO: extract label component */
        <motion.div layout className='text-d-text-2 excalidraw:pl-2 text-sm'>
          {label}
        </motion.div>
      )}

      <motion.div className='relative w-full' layout>
        <textarea
          {...props}
          rows={4}
          value={value}
          ref={textAreaRef}
          onChange={(e) => {
            onChange && onChange(e);
          }}
          onPaste={handlePaste}
          className={twMerge(
            `bg-surface-secondary/50 text-d-text-neg w-full resize-none appearance-none
            rounded-xl border border-text-base/20 p-3 text-sm font-medium shadow-none
            focus:border-none`,
            className
          )}
        />
        {value && (value as string).length > 0 && (
          <div className='absolute right-2 bottom-2 flex cursor-pointer z-1000 text-text-base/50'>
            <button onClick={toClipboard}>
              <div className='inline-flex pr-1'>{copyText}</div>
              <FontAwesomeIcon icon={faClone} />
            </button>
          </div>
        )}
      </motion.div>
    </motion.div>
  );
};

export default TextArea;
