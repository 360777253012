import { useAxios } from '../../helpers/ProtectedLayout';
import { AxiosResponse, AxiosError } from 'axios';
import { Project, ProjectShort } from '../../types/api';

export class AlreadyExistsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AlreadyExistsError';
  }
}

export const useProjectAPI = () => {
  const axios = useAxios().apiInstance;

  const getProject = async (projectId: string): Promise<Project> => {
    try {
      const response: AxiosResponse<Project> = await axios.get<Project>(
        `/project/${projectId}`
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch project: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const getProjects = async (
    status?: 'archived' | 'visible'
  ): Promise<ProjectShort[]> => {
    try {
      const response: AxiosResponse<ProjectShort[]> = await axios.get<
        ProjectShort[]
      >(
        status ? `/project?short=true&status=${status}` : '/project?short=true'
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch projects: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const createProject = async (name: string): Promise<Project> => {
    try {
      const response = await axios.post<Project>('/project', {
        name,

        description: '',
      });

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === '400' &&
          error.response?.data?.error.includes('already exists')
        ) {
          throw new AlreadyExistsError(
            'A project with this name already exists'
          );
        }

        throw new Error(
          `failed to create project: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const updateProject = async (
    projectId: string,
    project: Partial<Omit<Project, 'id'>>
  ): Promise<void> => {
    try {
      await axios.put(`/project/${projectId}`, project);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to update project: ${error.message}`);
      }

      throw error;
    }
  };

  const renameProject = async (
    projectId: string,
    newName: string
  ): Promise<void> => {
    try {
      await axios.put(`/project/${projectId}`, {
        name: newName,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === '400' &&
          error.response?.data?.error.includes('already exists')
        ) {
          throw new AlreadyExistsError(
            'a project with this name already exists'
          );
        }

        throw new Error(`failed to rename project: ${error.message}`);
      }

      throw error;
    }
  };

  const archiveProject = async (projectId: string): Promise<void> => {
    try {
      await axios.put(`/project/${projectId}`, {
        status: 'ARCHIVED',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to archive project: ${error.message}`);
      }

      throw error;
    }
  };

  const restoreProject = async (projectId: string): Promise<void> => {
    try {
      await axios.put(`/project/${projectId}`, {
        status: 'VISIBLE',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to restore project: ${error.message}`);
      }

      throw error;
    }
  };

  return {
    updateProject,
    createProject,
    getProject,
    getProjects,
    renameProject,
    archiveProject,
    restoreProject,
  };
};
