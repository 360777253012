import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { Action } from '../types';

type ActionItemProps = {
  item: Action;
  icon: IconDefinition;
  isSelected: boolean;
  onSelect: () => void;
  onClick: () => void;
};

export const ActionItem: React.FC<ActionItemProps> = ({
  item,
  icon,
  isSelected,
  onSelect,
  onClick,
}) => (
  <div
    className={`flex items-center gap-2 rounded p-2 ${
      isSelected ? 'bg-surface-tertiary text-text-negative' : '' }`}
    onMouseEnter={onSelect}
    onClick={onClick}>
    <FontAwesomeIcon
      icon={icon}
      className={`text-base ${isSelected ? '' : 'text-opacity-30'}`}
    />
    <div className='flex cursor-pointer items-center gap-2'>
      <span>{item.title}</span>
    </div>
  </div>
);
