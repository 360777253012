import { useCallback, useMemo } from 'react';
import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';

import Dropdown, {
  Option,
  DropdownProps,
} from '@/components/lib/Dropdown/Dropdown';
import useGetProjects from '@/hooks/projects/useGetProjects';
import { useTranslation } from 'react-i18next';

interface ProjectDropdownProps extends Omit<DropdownProps, 'options' | 'icon'> {
  projectIds?: string[];
  onSelectProjects?: (projectIds: string[]) => void;
  status?: 'archived' | 'visible';
  projectsToExclude?: string[];
  icon?: DropdownProps['icon'];
}

export const ProjectDropdown = ({
  onSelectProjects,
  status = 'visible',
  projectsToExclude = [],
  icon = faFolderOpen,
  onChange,
  ...dropdownProps
}: Omit<ProjectDropdownProps, 'placeholder'>) => {
  const { projects } = useGetProjects(status);
  const { t } = useTranslation('dashboard');

  const handleChange = useCallback(
    (options: Option[]) => {
      const selectedIds = options.map((option) => option.id);
      onSelectProjects?.(selectedIds);
      onChange?.(options);
    },
    [onChange, onSelectProjects]
  );

  const projectsFiltered = useMemo(() => {
    if (!projects) return [];

    return projects.filter(
      (project) => !projectsToExclude.includes(project.id)
    );
  }, [projects, projectsToExclude]);

  const projectOptions: Option[] = useMemo(
    () =>
      projectsFiltered?.map((project) => ({
        id: project.id,
        label: project.name,
        value: project,
      })) ?? [],
    [projectsFiltered]
  );

  const placeholder = useMemo(() => t('choose.project'), [t]);

  return (
    <Dropdown
      options={projectOptions}
      search
      triggerStyles='border'
      isContentContained
      icon={icon}
      placeholder={placeholder}
      onChange={handleChange}
      {...dropdownProps}
    />
  );
};

export default ProjectDropdown;
