import { useState, useEffect } from 'react';

//TODO: refactor all those hooks to be explicit that they are mobile first and using tailwind breakpoints

const useIsMobile = (): boolean => {
  const [isMobile, setIsMobile] = useState(
    window.matchMedia('(max-width: 640px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 640px)');

    const handleResize = () => {
      setIsMobile(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isMobile;
};

export const useIsSmallTablet = (): boolean => {
  const [isSmallTablet, setIsSmallTablet] = useState(
    window.matchMedia('(max-width: 730px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');

    const handleResize = () => {
      setIsSmallTablet(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isSmallTablet;
};

export const useIsTablet = (): boolean => {
  const [isTablet, setIsTablet] = useState(
    window.matchMedia('(max-width: 1024px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1024px)');

    const handleResize = () => {
      setIsTablet(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isTablet;
};

export const useIsLargeTablet = (): boolean => {
  const [isLargeTablet, setIsLargeTablet] = useState(
    window.matchMedia('(max-width: 1280px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1280px)');

    const handleResize = () => {
      setIsLargeTablet(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isLargeTablet;
};
export const useIsScreen = (): boolean => {
  const [isLargeTablet, setIsLargeTablet] = useState(
    window.matchMedia('(max-width: 1536px)').matches
  );

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1280px)');

    const handleResize = () => {
      setIsLargeTablet(mediaQuery.matches);
    };

    mediaQuery.addEventListener('change', handleResize);

    return () => {
      mediaQuery.removeEventListener('change', handleResize);
    };
  }, []);

  return isLargeTablet;
};

export default useIsMobile;
