import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { twMerge } from 'tailwind-merge';

type RenamableItemProps = {
  initialName: string | undefined;
  onRename: (newName: string) => Promise<void>;
  className?: string;
  rows?: number;
};

/**
 * A reusable component that provides inline text editing functionality.
 * It displays text that can be edited by clicking an edit icon or double-clicking the text.
 *
 * @component
 * @param {Object} props - Component props
 * @param {string | undefined} props.initialName - The initial text to display
 * @param {(newName: string) => Promise<void>} props.onRename - Callback function called when the text is changed
 * @param {string} [props.className] - Optional CSS classes to apply to the container
 *
 * @example
 * // Basic usage
 * <RenamableItem
 *   initialName="My Item"
 *   onRename={async (newName) => {
 *     await updateItemInDatabase(newName);
 *   }}
 * />
 *
 * @example
 * // With custom styling
 * <RenamableItem
 *   initialName="Custom Styled Item"
 *   onRename={handleRename}
 *   className="text-blue-500 font-bold"
 * />
 *
 * @example
 * // Usage with data fetching
 * const MyComponent = ({ itemId }) => {
 *   const { item } = useGetItem(itemId);
 *   const { updateItem } = useUpdateItem();
 *
 *   const handleRename = async (newName) => {
 *     if (!item) return;
 *     await updateItem(item.id, newName);
 *   };
 *
 *   return (
 *     <RenamableItem
 *       initialName={item?.name}
 *       onRename={handleRename}
 *     />
 *   );
 * };
 */
const RenamableItem = ({
  initialName,
  onRename,
  className,
  rows = 1,
}: RenamableItemProps) => {
  const [newName, setNewName] = useState(initialName);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setNewName(initialName);
  }, [initialName]);

  const changeName = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setNewName(e.target.value);
  };

  const editName = async () => {
    if (!newName || newName === '') {
      setNewName(initialName);
      setIsEditing(false);
      return;
    }
    try {
      await onRename(newName);
      setIsEditing(false);
    } catch (error) {
      console.error('Failed to update name', error);
    }
  };

  const keyDownName = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter') {
      editName();
    }
  };

  return (
    <div
      className={twMerge(
        'grid w-fit grid-cols-[1fr_min-content] gap-2 overflow-hidden',
        className
      )}
      onDoubleClick={() => setIsEditing(true)}>
      {isEditing ? (
        <textarea
          rows={rows}
          value={newName}
          onChange={changeName}
          onKeyDown={keyDownName}
          onBlur={editName}
          autoFocus
          className='w-full border-b border-gray-300 bg-transparent focus:outline-hidden'
        />
      ) : (
        <div
          className={
            rows < 2
              ? 'overflow-hidden text-ellipsis text-nowrap'
              : 'overflow-hidden '
          }>
          {initialName}
        </div>
      )}
      <div
        className='cursor-pointer opacity-40'
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setIsEditing(true);
        }}>
        <FontAwesomeIcon icon={faPencil} height={10} width={10} />
      </div>
    </div>
  );
};

export default RenamableItem;
