import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { Link, useParams } from 'react-router-dom';
import {
  faFolder,
  faLink,
  faTrash,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@/components/lib/Button';
import useGetCreations from '@/hooks/creations/useGetCreations';
import { useTranslation } from 'react-i18next';
import Card, {
  CreatedAtInfo,
  NumberOfGenerationsInfo,
  OverviewCard,
} from '@/components/lib/Containers/Card';
import RenamableWhiteboard from '../../Common/EditableItems/RenamableWhiteboard';
import useArchiveWhiteboard from '@/hooks/whiteboard/useArchiveWhiteboard';
import useRestoreWhiteboard from '@/hooks/whiteboard/useRestoreWhiteboard';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import Collection from '@/components/lib/Containers/Collection';
import { CreationCard } from '../Creations/CreationCard';
import { ItemPageWrapper } from '../common/ItemPageWrapper';
import ProjectDropdown from '@/components/Common/Dropdowns/ProjectDropdown';
import { Option } from '@/components/lib/Dropdown/Dropdown';
import { useCallback, useMemo } from 'react';
import useAddWhiteboardToProject from '@/hooks/whiteboard/useAddWhiteboardToProject';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const WhiteboardPage = () => {
  const { whiteboardId } = useParams<{ whiteboardId: string }>();

  const { creations } = useGetCreations(whiteboardId);

  if (!whiteboardId) return null;

  return (
    <ItemPageWrapper>
      <WhiteboardOverview
        whiteboardId={whiteboardId}
        creationsNumber={creations.length}
      />
      <div className=''>
        <Collection>
          {creations.map((creation) => {
            return <CreationCard creationId={creation.id} key={creation.id} />;
          })}
        </Collection>
      </div>
    </ItemPageWrapper>
  );
};

const WhiteboardOverview = ({
  whiteboardId,
  creationsNumber,
}: {
  whiteboardId: string;
  creationsNumber: number;
}) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId ?? '');

  const { t } = useTranslation('dashboard');

  const { archiveWhiteboard } = useArchiveWhiteboard();
  const { restoreWhiteboard } = useRestoreWhiteboard();

  const { addWhiteboardToProject } = useAddWhiteboardToProject();

  console.log(whiteboard);

  const initialProject = useMemo(() => {
    const project = whiteboard?.project;
    if (project) {
      return [
        {
          id: project.id,
          label: project.name,
          value: project,
        },
      ];
    }
    return [];
  }, [whiteboard]);

  const onChange = useCallback(
    (option: Option[]) => {
      addWhiteboardToProject(whiteboardId, option[0].id);
    },
    [addWhiteboardToProject, whiteboardId]
  );

  if (!whiteboard) {
    return null;
  }

  const { status, createdAt, project } = whiteboard;

  return (
    <OverviewCard>
      <div className='flex flex-col gap-10'>
        <RenamableWhiteboard
          className='border-grey bg-surface-secondary/50 w-full rounded-xl border p-4'
          whiteboardId={whiteboardId}
        />

        <Card.Section>
          <Card.SectionHeader>{t('common.details')}</Card.SectionHeader>
          <Card.Separator />
          <CreatedAtInfo date={new Date(createdAt)} />
          <NumberOfGenerationsInfo number={creationsNumber} />

          <Card.Info icon={faFolder}>
            <p>Dans le projet</p>
            <p className='flex items-center gap-2'>
              <ProjectDropdown
                initialValue={initialProject}
                multipleSelect={false}
                onChange={onChange}
                selectedItemsPosition={'withinList'}
              />
              {project && (
                <Link to={`/projects/${project.id}`}>
                  <FontAwesomeIcon icon={faLink} />
                </Link>
              )}
            </p>
          </Card.Info>
        </Card.Section>
        <Card.Section>
          <Link to={`/creator/${whiteboardId}`}>
            <Button className='w-full' variant={'accent'}>
              Ouvrir dans le Creator
            </Button>
          </Link>
          <Button
            leftIcon={faLink}
            iconSize='20px'
            className='w-full'
            onClick={() => {
              const url = `${window.location.origin}/creator/${whiteboardId}`;
              copyToClipboard(url);
            }}
            variant={'primary'}>
            Copier le lien
          </Button>
        </Card.Section>
      </div>

      <div className='w-full self-end'>
        <Button
          variant='danger'
          size='medium'
          className='w-full'
          leftIcon={status === 'VISIBLE' ? faTrash : faTrashRestore}
          onClick={() => {
            if (status === 'VISIBLE') {
              archiveWhiteboard(whiteboardId);
            } else {
              restoreWhiteboard(whiteboardId);
            }
          }}>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
      </div>
    </OverviewCard>
  );
};

export default WhiteboardPage;
