// React imports
import { HTMLAttributes, ReactNode, useState } from 'react';

// Third-party libraries
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { twMerge } from 'tailwind-merge';

// Icon imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBookOpen,
  faCalendar,
  faChalkboard,
  faEllipsisV,
  faFolder,
  faImages,
  faTimes,
  faUpRightFromSquare,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

// Project components
import Button from '../Button';
import ProjectName from '@/components/Dashboard/Whiteboards/ProjectName';

// Hooks
import useTimeAgo from '@/hooks/useTimeAgo';

// Define an interface for event props
interface CardEventProps
  extends Pick<
    HTMLAttributes<HTMLLIElement>,
    'onClick' | 'onMouseEnter' | 'onMouseLeave' | 'onFocus' | 'onBlur'
  > {
  // You can add additional event props specific to your Card component here
}

/**
 * Card Component
 *
 * A flexible card container with a three-row grid layout:
 * - Top row (header): Takes minimum required height
 * - Middle row (body): Expands to fill available space
 * - Bottom row (footer): Takes minimum required height
 *
 * The component is built with motion animations and accepts various primitive
 * components as children to compose complex card layouts.
 *
 * Example usage:
 * ```tsx
 * <Card>
 *   <Card.Header icon={faFolder}>Project Title</Card.Header>
 *   <Card.Body>
 *     <Card.Section>
 *       <Card.SectionHeader>Section Title</Card.SectionHeader>
 *       <Card.SectionParagraph>Content goes here</Card.SectionParagraph>
 *     </Card.Section>
 *   </Card.Body>
 *   <Card.Footer>
 *     <Card.Tags>
 *       <NumberOfWhiteboardsTag number={5} />
 *     </Card.Tags>
 *     <Card.DotMenu>
 *       <Button>Action</Button>
 *     </Card.DotMenu>
 *   </Card.Footer>
 * </Card>
 * ```
 *
 * @param {ReactNode} children - The content to render inside the card
 * @param {boolean} square - Whether the card should maintain a square aspect ratio
 * @param {string} className - Optional CSS class to override default styles (escape hatch)
 * @returns {JSX.Element} A card component with the specified layout and styling
 */
const Card = ({
  children,
  square = false,
  hideScrollbar = true,
  className,
  ...events
}: {
  children: ReactNode;
  square?: boolean;
  hideScrollbar?: boolean;
  className?: string;
} & CardEventProps) => {
  return (
    <li
      style={{ borderRadius: '16px' }}
      className={twMerge(
        `relative flex ${square ? 'aspect-square' : 'aspect-auto'} text-text-base
        bg-surface-primary grid h-full w-full grid-cols-1
        grid-rows-[min-content_1fr_min-content] justify-between gap-1 p-4 max-w-[95vw]
        max-h-[95vh] overflow-visible overflow-y-auto`,
        hideScrollbar && 'no-scrollbar',
        className
      )}
      {...events}>
      {children}
    </li>
  );
};

// PRIMITIVES - MAIN STRUCTURAL COMPONENTS

/**
 * Body
 *
 * Container for the main content area of a card.
 * Designed to occupy the middle row in the card's grid layout and expand to fill available space.
 *
 * @param {ReactNode} children - Main content
 * @returns {JSX.Element} A body container
 */
const Body = ({ children }: { children: ReactNode }) => {
  return <div className='flex flex-col gap-4 py-2'>{children}</div>;
};

/**
 * CardHeader
 *
 * A basic header component for the Card, typically used in the top row.
 * Consists of an optional icon and children content.
 *
 * @param {IconDefinition} icon - Optional FontAwesome icon to display
 * @param {ReactNode} children - Content to display in the header
 * @returns {JSX.Element} A header with icon and content
 */
const CardHeader = ({
  icon,
  children,
}: {
  icon?: IconDefinition;
  children?: ReactNode;
}) => {
  return (
    <div className='flex h-min items-center gap-3 text-xl font-bold'>
      {icon && <FontAwesomeIcon icon={icon} />}
      <h1 className='w-full'>{children}</h1>
    </div>
  );
};

/**
 * Footer
 *
 * Container for the bottom section of a card.
 * Typically contains tags and actions, positioned in the bottom row of the grid layout.
 *
 * @param {ReactNode} children - Footer content
 * @returns {JSX.Element} A footer container
 */
const Footer = ({ children }: { children: ReactNode }) => {
  return (
    <div className='flex items-center justify-end w-full self-end overflow-hidden'>
      {children}
    </div>
  );
};

// OTHER PRIMITIVES

/**
 * CardHeaderWithLink
 *
 * An enhanced header component that includes a link or click action.
 * Used for cards that need navigation capabilities.
 *
 * @param {IconDefinition} icon - Optional FontAwesome icon to display
 * @param {ReactNode} title - Title content to display
 * @param {string} linkTo - Optional URL to navigate to when clicked
 * @param {Function} onClick - Optional click handler function
 * @returns {JSX.Element} A header with icon, title and navigation link
 */
const CardHeaderWithLink = ({
  icon,
  title,
  linkTo,
  onClick,
}: {
  icon?: IconDefinition;
  title: ReactNode;
  linkTo?: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <CardHeader icon={icon}>
      <div className='flex items-center gap-4 justify-between'>
        {title}
        {(linkTo || onClick) &&
          (onClick ? (
            <span onClick={onClick} className='cursor-pointer'>
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className='text-text-base-saturated/70 hover:translate-x-1/6 hover:-translate-y-1/6
                  transition-transform'
              />
            </span>
          ) : (
            <Link
              to={linkTo as string}
              title={t('common.details')}
              aria-label='View details'>
              <FontAwesomeIcon
                icon={faUpRightFromSquare}
                className='text-text-base-saturated/70 hover:translate-x-1/6 hover:-translate-y-1/6
                  transition-transform'
              />
            </Link>
          ))}
      </div>
    </CardHeader>
  );
};

/**
 * CloseButton
 *
 * A button for closing or dismissing a card.
 *
 * @param {Function} onClose - Function to call when the close button is clicked
 * @returns {JSX.Element} A close button with X icon
 */
const CloseButton = ({ onClose }: { onClose: () => void }) => {
  return (
    <Button
      aria-label='Close Card'
      leftIcon={faTimes}
      round
      onClick={onClose}
    />
  );
};

/**
 * TimeAgo
 *
 * Displays a relative time indicator (e.g., "2 days ago").
 *
 * @param {Date} date - The date to display as a relative time
 * @returns {JSX.Element} A span with the relative time text
 */
const TimeAgo = ({ date }: { date: Date }) => {
  const time = useTimeAgo(date);

  return (
    <span className='text-text-accent h-min' title={date.toLocaleString()}>
      {time}
    </span>
  );
};

/**
 * DotMenu
 *
 * A dropdown menu triggered by an ellipsis (three dots) button.
 * Used for additional actions that don't need to be always visible.
 *
 * @param {ReactNode} children - Menu items to display when open
 * @returns {JSX.Element} A dropdown menu with toggle button
 */
const DotMenu = ({ children }: { children: ReactNode }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuId = `dot-menu-${Math.random().toString(36).substr(2, 9)}`;

  return (
    <div className='flex items-center gap-2'>
      <Button
        aria-label={isOpen ? 'Close detail menu' : 'Open detail menu'}
        leftIcon={isOpen ? faTimes : faEllipsisV}
        round
        className={`${isOpen ? 'z-100' : 'z-40'}`}
        size={'small'}
        onClick={() => setIsOpen((prev) => !prev)}
        aria-expanded={isOpen}
        aria-controls={menuId}
        aria-haspopup='menu'
      />

      {isOpen && (
        <>
          <motion.div
            id={menuId}
            role='menu'
            aria-orientation='vertical'
            aria-labelledby='menu-button'
            initial={{ y: 200, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 10, opacity: 0 }}
            transition={{ duration: 0.2 }}
            onClick={() => {
              setIsOpen(false);
            }}
            className={`bg-surface-primary absolute bottom-0 left-0 z-90 flex w-full flex-col gap-3
            rounded-xl pb-20 px-4 pt-4`}>
            {children}
          </motion.div>
          {/* Overlay/backdrop */}
          <div
            role='presentation'
            aria-hidden='true'
            className='bg-opacity-25 fixed top-0 left-0 z-50 h-full w-full bg-black/20 backdrop-blur-xs'
            onClick={() => setIsOpen(false)}></div>
        </>
      )}
    </div>
  );
};

/**
 * DotMenuOption
 *
 * A single option within a DotMenu.
 *
 *
 * @param {ReactNode} children - Content of the menu option
 * @returns {JSX.Element} A menu option component
 */
const DotMenuOption = ({ children }: { children: ReactNode }) => {
  return (
    <div role='menuitem' tabIndex={0}>
      {children}
    </div>
  );
};

/**
 * Section
 *
 * A container for grouping related content within a card.
 * Can be arranged in column or row direction.
 *
 * @param {ReactNode} children - Section content
 * @param {'column'|'row'} direction - Layout direction
 * @returns {JSX.Element} A section container
 */
const Section = ({
  children,
  direction = 'column',
}: {
  children: ReactNode;
  direction?: 'column' | 'row';
}) => {
  return (
    <div
      className={`flex ${direction === 'column' ? 'flex-col gap-2' : 'flex-row gap-4'} w-full`}>
      {children}
    </div>
  );
};

/**
 * SectionParagraph
 *
 * A paragraph of text styled for use within a Section.
 *
 * @param {ReactNode} children - Text content
 * @returns {JSX.Element} A styled paragraph
 */
const SectionParagraph = ({ children }: { children: ReactNode }) => {
  return <p className='text-text-base text-xl font-semibold'>{children}</p>;
};

/**
 * SectionHeader
 *
 * A header for a Section component.
 *
 * @param {ReactNode} children - Header text
 * @returns {JSX.Element} A styled section header
 */
const SectionHeader = ({ children }: { children: ReactNode }) => {
  return (
    <h1 className='text-text-base-saturated text-xl font-semibold tracking-tighter'>
      {children}
    </h1>
  );
};

/**
 * Separator
 *
 * A horizontal line to visually separate content within a card.
 *
 * @returns {JSX.Element} A horizontal separator line
 */
const Separator = () => {
  return <div className='bg-text-base-saturated/40 h-[1px]'></div>;
};

/**
 * SubSection
 *
 * A smaller grouping of content within a Section.
 * Can be arranged in column or row direction.
 *
 * @param {ReactNode} children - SubSection content
 * @param {'column'|'row'} direction - Layout direction
 * @returns {JSX.Element} A subsection container
 */
const SubSection = ({
  children,
  direction = 'column',
}: {
  children: ReactNode;
  direction?: 'column' | 'row';
}) => {
  return (
    <div
      className={`flex ${direction === 'column' ? 'flex-col gap-1' : 'flex-row gap-4'}`}>
      {children}
    </div>
  );
};

/**
 * SubSectionHeader
 *
 * A header for a SubSection component.
 *
 * @param {ReactNode} children - Header text
 * @param {IconDefinition} icon - Optional FontAwesome icon
 * @returns {JSX.Element} A styled subsection header
 */
const SubSectionHeader = ({
  children,
  icon,
}: {
  children: ReactNode;
  icon?: IconDefinition;
}) => {
  return (
    <h2 className='flex items-center gap-2 text-base opacity-50'>
      {icon && <FontAwesomeIcon icon={icon} />}
      <p>{children}</p>
    </h2>
  );
};

/**
 * Tags
 *
 * A container for displaying multiple Tag elements.
 *
 * @param {ReactNode} children - Tag elements
 * @returns {JSX.Element} A tags container
 */
const Tags = ({ children }: { children: ReactNode }) => {
  return (
    <div className='relative flex basis-full gap-2 overflow-hidden'>
      {children}
    </div>
  );
};

/**
 * Tag
 *
 * A small label with an icon, used to display metadata.
 *
 * @param {IconDefinition} icon - FontAwesome icon for the tag
 * @param {ReactNode} children - Tag text
 * @returns {JSX.Element} A tag with icon and text
 */
const Tag = ({
  icon,
  children,
  to,
}: {
  icon: IconDefinition;
  children: ReactNode;
  to?: string;
}) => {
  return to ? (
    <Link
      className='text-text-accent items-center max-w-1/2 gap-1 flex overflow-hidden'
      to={to}>
      <FontAwesomeIcon icon={icon} />
      {children}
    </Link>
  ) : (
    <div className='text-text-accent items-center max-w-1/2 gap-1 flex overflow-hidden'>
      <FontAwesomeIcon icon={icon} />
      {children}
    </div>
  );
};

/**
 * Info
 *
 * Displays a piece of information with an icon and label.
 * Used for showing metadata about a resource.
 *
 * @param {IconDefinition} icon - FontAwesome icon
 * @param {ReactNode} children - Information content
 * @returns {JSX.Element} An info component with icon and content
 */
const Info = ({
  icon,
  children,
}: {
  icon: IconDefinition;
  children: ReactNode;
}) => {
  return (
    <>
      <div className='grid grid-cols-[min-content_1fr] items-center gap-2 px-2 py-2'>
        <FontAwesomeIcon icon={icon} />
        <div className='flex w-full items-center justify-between gap-2 font-semibold'>
          {children}
        </div>
      </div>

      <Separator />
    </>
  );
};

// TEMPLATES
/**
 * Templates
 *
 * The following components are specialized templates built using the primitive Card components.
 * They provide pre-configured card layouts for common use cases in the application.
 *
 * - OverviewCard: A responsive card with responsive sizing for overview displays
 * - Info components (CreatedAtInfo, NumberOfWhiteboardsInfo, etc.): Display specific metadata
 * - Tag components (ProjectTag, NumberOfWhiteboardsTag, etc.): Compact metadata indicators
 */

/**
 * OverviewCard
 *
 * A specialized card template for overview displays.
 * Has responsive width behavior for different screen sizes to ensure optimal display
 * on various devices and screen widths.
 *
 * @param {ReactNode} children - Card content
 * @param {boolean} square - Whether the card should be square
 * @returns {JSX.Element} An overview card with responsive sizing
 */
export const OverviewCard = ({
  children,
  square = false,
}: {
  children: ReactNode;
  square?: boolean;
  className?: string;
}) => {
  return (
    <Card
      square={square}
      className='h-min w-full lg:w-[300px] 2xl:w-[400px] lg:h-full'>
      {children}
    </Card>
  );
};

/**
 * CreatedAtInfo
 *
 * Template component for displaying creation date information.
 * Uses localization for date formatting to show when a resource was created
 * in the user's preferred date format.
 *
 * @param {Date} date - Creation date
 * @returns {JSX.Element} A formatted creation date display
 */
export const CreatedAtInfo = ({ date }: { date: Date }) => {
  const { t, i18n } = useTranslation('dashboard');
  const tf = Intl.DateTimeFormat(i18n.language, {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
  });

  return (
    <Info icon={faCalendar}>
      {t('common.createdOn')} {tf.format(date)}
    </Info>
  );
};

/**
 * NumberOfWhiteboardsInfo
 *
 * Template for displaying whiteboard count information.
 * Shows the total number of whiteboards associated with a resource.
 *
 * @param {number} number - Count of whiteboards
 * @returns {JSX.Element} A formatted whiteboard count display
 */
export const NumberOfWhiteboardsInfo = ({ number }: { number?: number }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Info icon={faChalkboard}>
      <p>{t('pageNames.whiteboards')}</p>
      <p>{number}</p>
    </Info>
  );
};

/**
 * NumberOfAlbumsInfo
 *
 * Template for displaying album count information.
 * Shows the total number of albums associated with a resource.
 *
 * @param {number} number - Count of albums
 * @returns {JSX.Element} A formatted album count display
 */
export const NumberOfAlbumsInfo = ({ number }: { number?: number }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Info icon={faBookOpen}>
      <p>{t('pageNames.albums')}</p>
      <p>{number}</p>
    </Info>
  );
};

/**
 * NumberOfGenerationsInfo
 *
 * Template for displaying generation count information.
 * Shows the total number of AI-generated creations associated with a resource.
 *
 * @param {number} number - Count of generations
 * @returns {JSX.Element} A formatted generation count display
 */
export const NumberOfGenerationsInfo = ({ number }: { number?: number }) => {
  const { t } = useTranslation('dashboard');
  return (
    <Info icon={faImages}>
      <p>{t('pageNames.creations')}</p>
      <p>{number}</p>
    </Info>
  );
};

/**
 * NumberOfImagesInfo
 *
 * Template for displaying image count information.
 * Shows the total number of images associated with a resource.
 *
 * @param {number} number - Count of images
 * @returns {JSX.Element} A formatted image count display
 */
export const NumberOfImagesInfo = ({ number }: { number?: number }) => {
  return (
    <Info icon={faImages}>
      <p>Images</p>
      <p>{number}</p>
    </Info>
  );
};

/**
 * ProjectTag
 *
 * Template tag for displaying a clickable project reference.
 * Shows the project name and links to the project detail page.
 *
 * @param {string} projectId - ID of the project to link to
 * @returns {JSX.Element} A project tag with name and link
 */
export const ProjectTag = ({ projectId }: { projectId: string }) => {
  return (
    <Tag icon={faFolder}>
      <Link
        to={projectId ? `/projects/${projectId}` : ''}
        className={`${projectId ? 'cursor-pointer' : 'cursor-default'} overflow-hidden w-min
          text-nowrap overflow-ellipsis`}>
        <ProjectName projectId={projectId}></ProjectName>
      </Link>
    </Tag>
  );
};

/**
 * NumberOfWhiteboardsTag
 *
 * Template tag for displaying whiteboard count.
 * A compact indicator showing the number of whiteboards in a resource.
 *
 * @param {number} number - Count of whiteboards
 * @returns {JSX.Element} A whiteboard count tag
 */
export const NumberOfWhiteboardsTag = ({
  number,
  to,
}: {
  number: number;
  to?: string;
}) => {
  return (
    <Tag icon={faChalkboard} to={to}>
      {number}
    </Tag>
  );
};

/**
 * NumberOfAlbumsTag
 *
 * Template tag for displaying album count.
 * A compact indicator showing the number of albums in a resource.
 *
 * @param {number} number - Count of albums
 * @returns {JSX.Element} An album count tag
 */
export const NumberOfAlbumsTag = ({
  number,
  to,
}: {
  number: number;
  to?: string;
}) => {
  return (
    <Tag icon={faBookOpen} to={to}>
      {number}
    </Tag>
  );
};

/**
 * NumberOfGenerationsTag
 *
 * Template tag for displaying generation count.
 * A compact indicator showing the number of AI-generated items in a resource.
 *
 * @param {number} number - Count of generations
 * @returns {JSX.Element} A generation count tag
 */
export const NumberOfGenerationsTag = ({
  number,
  to,
}: {
  number: number;
  to?: string;
}) => {
  return (
    <Tag icon={faImages} to={to}>
      {number}
    </Tag>
  );
};

// Attach all subcomponents to Card
Card.Header = CardHeader;
Card.HeaderWithLink = CardHeaderWithLink;
Card.Body = Body;
Card.Separator = Separator;
Card.CloseButton = CloseButton;
Card.Info = Info;

Card.Tag = Tag;
Card.Tags = Tags;
Card.Section = Section;
Card.SectionParagraph = SectionParagraph;
Card.SectionHeader = SectionHeader;
Card.SubSection = SubSection;
Card.SubSectionHeader = SubSectionHeader;
Card.TimeAgo = TimeAgo;
Card.DotMenu = DotMenu;
Card.DotMenuOption = DotMenuOption;
Card.Footer = Footer;

export default Card;
