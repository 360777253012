import { CustomEraser } from '../CustomTools/CustomEraser/CustomEraserTool';
import { CustomDrawShapeTool } from '../CustomTools/CustomDraw/CustomDrawShapeTool';
import {
  CustomEllipseShapeTool,
  CustomRectangleShapeTool,
  CustomTriangleShapeTool,
  CustomDiamondShapeTool,
  CustomHexagonShapeTool,
  CustomStarShapeTool,
  CustomCloudShapeTool,
  CustomOvalShapeTool,
  CustomRhombusShapeTool,
  CustomXBoxShapeTool,
  CustomCheckBoxShapeTool,
  CustomArrowLeftShapeTool,
  CustomArrowUpShapeTool,
  CustomArrowDownShapeTool,
  CustomArrowRightShapeTool,
} from '../CustomTools/CustomGeo/CustomGeoShapeTool';
import { CustomTextShapeTool } from '../CustomTools/CustomText/CustomTextShapeTool';
import { ColorSampler } from '../CustomTools/ColorSampler/ColorSampler';
import { CustomLineShapeTool } from '../CustomTools/CustomLineTool/CustomLineShapeTool';

export const customTools = [
  CustomDrawShapeTool,
  CustomRectangleShapeTool,
  CustomEllipseShapeTool,
  CustomTriangleShapeTool,
  CustomDiamondShapeTool,
  CustomHexagonShapeTool,
  CustomStarShapeTool,
  CustomCloudShapeTool,
  CustomOvalShapeTool,
  CustomRhombusShapeTool,
  CustomXBoxShapeTool,
  CustomCheckBoxShapeTool,
  CustomArrowLeftShapeTool,
  CustomArrowUpShapeTool,
  CustomArrowDownShapeTool,
  CustomArrowRightShapeTool,
  CustomEraser,
  CustomTextShapeTool,
  ColorSampler,
  CustomLineShapeTool,
];
