import { useCallback, useMemo } from 'react';
import { faChalkboard } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';
import Dropdown, {
  Option,
  DropdownProps,
} from '@/components/lib/Dropdown/Dropdown';

interface WhiteboardDropdownProps
  extends Omit<DropdownProps, 'options' | 'icon'> {
  whiteboardIds?: string[];
  onSelectWhiteboards?: (whiteboardIds: string[]) => void;
  status?: 'visible' | 'archived';
  whiteboardsToExclude?: string[];
}

export const WhiteboardDropdown = ({
  onSelectWhiteboards,
  status = 'visible',
  whiteboardsToExclude,
  onChange,
  ...dropdownProps
}: Omit<WhiteboardDropdownProps, 'placeholder'>) => {
  const { t } = useTranslation('dashboard');
  const { whiteboards } = useGetWhiteboards(status);

  const handleChange = useCallback(
    (options: Option[]) => {
      const selectedIds = options.map((option) => option.id);
      onSelectWhiteboards?.(selectedIds);
      onChange?.(options);
    },
    [onChange, onSelectWhiteboards]
  );

  const whiteboardsFiltered = whiteboards?.filter((whiteboard) => {
    return !whiteboardsToExclude?.includes(whiteboard.id);
  });

  const whiteboardOptions: Option[] = useMemo(
    () =>
      whiteboardsFiltered?.map((whiteboard) => ({
        id: whiteboard.id,
        label: whiteboard.name,
        value: whiteboard,
      })) ?? [],
    [whiteboardsFiltered]
  );

  const placeholder = useMemo(() => t('choose.whiteboard'), [t]);

  return (
    <Dropdown
      options={whiteboardOptions}
      search
      triggerStyles='border'
      isContentContained
      icon={faChalkboard}
      placeholder={placeholder}
      onChange={handleChange}
      {...dropdownProps}
    />
  );
};

export default WhiteboardDropdown;
