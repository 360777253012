import { useCallback } from 'react';
import {
  TLShape,
  TLDrawShape,
  AssetRecordType,
  exportToBlob,
  createShapesForAssets,
  useEditor,
  getHashForBuffer,
  TLAssetId,
  createMediaAssetInfoSkeleton,
  containBoxSize,
} from 'tldraw';

import computeImagesBounds from '../helpers/computeImageBounds';
import { useWhiteboardAPI } from '@/hooks/whiteboard/useWhiteboardAPI';
import { throttle } from 'lodash';
import { useParams } from 'react-router-dom';

const useEditorIO = () => {
  const editor = useEditor();
  const { whiteboardId } = useParams();

  const { saveWhiteboardPreviewImage } = useWhiteboardAPI();

  const exportPreviewImage = useCallback(
    throttle(async (): Promise<void> => {
      if (!editor) {
        console.debug('no editor for export preview image');
        return;
      }

      if (!whiteboardId) {
        console.error('no whiteboardId for export preview image');
        return;
      }

      // Get all selected element IDs
      const shapes = editor.getCurrentPageShapesSorted();
      if (!shapes.length) return;

      try {
        // Use exportToBlob to generate the image Blob
        // const blob = await exportToBlob({
        //   editor: editor,
        //   format: 'image/jpeg',
        //   shapes: shapes,
        //   bounds: editor.getViewportPageBounds(),
        //   quality: 0.6,
        // });

        const blob = await exportToBlob({
          editor: editor,
          format: 'jpeg',
          ids: shapes.map((s) => s.id),
          opts: {
            padding: 0,
            pixelRatio: 1, // 2 by default,
            quality: 0.6,
            bounds: editor.getViewportScreenBounds(),
          },
        });

        if (!blob) {
          console.error('failed to generate preview image blob');
          return;
        }

        console.log(whiteboardId, blob);

        saveWhiteboardPreviewImage(whiteboardId, blob)
          .then(() => {
            console.log('Generating preview image...');
            console.debug('preview image saved');
          })
          .catch((err) => {
            console.log('Generating preview image FAIL');
            console.error(`failed to save whiteboard preview image: ${err}`);
          });
      } catch (error) {
        console.error(`error exporting preview image: ${error}`);
        return;
      }
    }, 10000),
    [whiteboardId]
  );

  const exportForImagine = useCallback(async (): Promise<string> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return '';
    }

    // Get all selected element IDs
    const selecteShapes = editor.getSelectedShapes();
    if (!selecteShapes.length) {
      console.log('No elements selected');
      return '';
    }

    const bounds = editor.getSelectionPageBounds();
    if (!bounds) {
      console.error('No bounds found for selected elements');
      return '';
    }

    try {
      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selecteShapes.map((s) => s.id),
        opts: {
          padding: 0,
          pixelRatio: 1, // 2 by default,
          quality: 1,
          bounds: bounds,
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return '';
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve(dataImg);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return '';
    }
  }, [editor]);

  const exportForInpainting = useCallback(async (): Promise<string[]> => {
    if (!editor) {
      console.error('Editor is not initialized');
      return [];
    }

    // Get all selected element IDs
    const selectedIds = editor.getSelectedShapeIds();

    if (selectedIds.length === 0) {
      console.error('No elements selected');
      return [];
    }

    const selectedShapes = editor.getSelectedShapes();
    const selectedImages = selectedShapes.filter((shape) => {
      return shape.type === 'image';
    });

    if (!selectedImages.length) return [];

    const bounds = computeImagesBounds(selectedImages);

    const drawings = selectedShapes
      .filter((shape) => {
        return 'drawCustom' === shape.type || 'geoCustom' === shape.type;
      })
      .map((shape: TLShape) => {
        if (shape.type === 'drawCustom') {
          const draw = shape as TLDrawShape;
          // Shape is not closed, close it
          if (!draw.props.isClosed) {
            editor.updateShape({
              id: draw.id,
              type: draw.type,
              props: { isClosed: true },
            });
          }

          return draw;
        }
        return shape;
      }, []);

    // Create canvas
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    if (!ctx) return [];

    // Set canvas size
    canvas.width = bounds.w;
    canvas.height = bounds.h;

    // Get shapes position in bounding box space
    const shapesInBounds = drawings.map((shape) => {
      const x = shape.x - bounds.x;
      const y = shape.y - bounds.y;
      return { ...shape, x, y };
    });

    // Get the svg for each shape
    const svgstoDraw: SVGElement[] = [];

    for (let i = 0; i < shapesInBounds.length; i++) {
      const shape = shapesInBounds[i];
      const fillValue = (shape as TLDrawShape).props.fill;

      // Fill the drawing
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: 'solid' },
      });

      const svg = await editor.getSvgElement([shape.id], {
        bounds: bounds,
        padding: 0,
      });

      // Reset fill value
      editor.updateShape({
        id: shape.id,
        type: shape.type,
        props: { fill: fillValue },
      });

      if (!svg) continue;
      svgstoDraw.push(svg.svg);
    }

    svgstoDraw.forEach((svg) => {
      const paths = svg.querySelectorAll('path');
      paths.forEach((path) => {
        path.setAttribute('stroke', 'white');
        path.setAttribute('fill', 'white');
      });
    });

    // convert every svg to an image
    const images = await Promise.all(
      svgstoDraw.map((svg) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.onload = () => {
            resolve(img);
          };
          img.src = `data:image/svg+xml;base64,${btoa(
            new XMLSerializer().serializeToString(svg)
          )}`;
        });
      })
    );

    // draw black background
    ctx.fillStyle = 'black';
    ctx.fillRect(0, 0, bounds.w, bounds.h);

    //draw the images on the canvas
    images.forEach((img) => {
      if (!ctx) return;

      ctx.drawImage(img as CanvasImageSource, 0, 0);
    });

    try {
      // Use exportToBlob to generate the image Blob
      const blob = await exportToBlob({
        editor: editor,
        format: 'png',
        ids: selectedImages.map((s) => s.id),
        opts: {
          padding: 0,
          pixelRatio: 1, // 2 by default
        },
      });

      if (!blob) {
        console.error('Failed to generate image blob');
        return [];
      }

      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = async () => {
          try {
            const dataImg = reader.result?.toString() || '';
            resolve([dataImg, canvas.toDataURL('image/png')]);
          } catch (err) {
            reject(err);
          }
        };
        reader.readAsDataURL(blob);
      });
    } catch (error) {
      console.error('Error exporting image:', error);
      return [];
    }
  }, [editor]);

  /**
   *  @file See: node_modules\tldraw\src\lib\defaultExternalContentHandlers.ts
   * Explanations on file above
   * This file defines how external contents are handled
   * For "files" input, it first flows through editor.registerExternalContentHandler('files', async ({ point, files })...
   * ...which calls const newAsset = await editor.getAssetForExternalContent({ type: 'files', files })... on line 297
   * ...which flows to editor.registerExternalAssetHandler('file', async ({ file, assetId })
   *
   * @function This function is a mix of the two handlers, which enables us to specify a size, different from the editor maxDimension
   * If you don't need to specify a size, just use importDataUrlImage
   * It both creates the asset and the shape */
  const importImageFromDOMElement = useCallback(
    async (image: HTMLImageElement, maxSize?: number) => {
      const response = await fetch(image.src);
      const blob = await response.blob();

      const imgFile = new File([blob], 'image.png', { type: 'image/png' });

      //Create an id based on the file content
      const hash = getHashForBuffer(await imgFile.arrayBuffer());
      const assetId: TLAssetId = AssetRecordType.createId(hash);

      const assetInfo = await createMediaAssetInfoSkeleton(
        imgFile,
        assetId,
        true, //is image
        false //is video
      );

      //Resizing the image
      if (maxSize) {
        if (isFinite(maxSize)) {
          const size = { w: assetInfo.props.w, h: assetInfo.props.h };
          const resizedSize = containBoxSize(size, { w: maxSize, h: maxSize });
          if (size !== resizedSize) {
            assetInfo.props.w = resizedSize.w;
            assetInfo.props.h = resizedSize.h;
          }
        }
      }

      //So the image appears even before its uploaded
      editor.createTemporaryAssetPreview(assetInfo.id, imgFile);

      //Uploading the asset
      assetInfo.props.src = await editor.uploadAsset(assetInfo, imgFile);

      const newAsset = AssetRecordType.create(assetInfo);
      const updated = { ...newAsset, id: assetInfo.id };

      editor.updateAssets([updated]);

      const centerOfScreen = editor.getViewportPageBounds().center;
      const [id] = await createShapesForAssets(
        editor,
        [updated],
        centerOfScreen
      );

      // Selecting the image after creation
      editor.setCurrentTool('select');
      editor.select(id);
    },
    [editor]
  );

  const importDataUrlImage = useCallback(
    async (dataUrl: string) => {
      if (!editor) return;

      // Convert data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      const imgFile = new File([blob], 'image.png', { type: 'image/png' });

      editor.putExternalContent({
        type: 'files',
        files: [imgFile],

        ignoreParent: true,
      });
    },
    [editor]
  );

  return {
    importDataUrlImage,
    exportPreviewImage,
    exportForImagine,
    exportForInpainting,
    importImageFromDOMElement,
  };
};

export default useEditorIO;
