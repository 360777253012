import { useMutation, useQueryClient } from 'react-query';

import { useAlbumAPI } from './useAlbumAPI';

import { Album } from '../../types/api';

const useCreateAlbum = (onSuccess?: (album: Album) => void) => {
  const queryClient = useQueryClient();
  const { createAlbum, addImagesToAlbum, getAlbum } = useAlbumAPI();

  const createAlbumMutation = useMutation({
    mutationFn: async ({
      name,
      images,
      projectId,
    }: {
      name: string;
      images: File[];
      projectId?: string;
    }) => {
      const album = await createAlbum(name, projectId);

      await addImagesToAlbum(album.id, images);

      const albumWithImages = await getAlbum(album.id);

      return albumWithImages;
    },
    onSuccess: (album) => {
      queryClient.setQueryData(['album', album.id], album);
      queryClient.setQueryData<Album[]>(['albums'], (oldData) => {
        if (!oldData) return [album];
        return [album, ...oldData];
      });
      onSuccess?.(album);
    },
    onSettled: () => {
      queryClient.invalidateQueries('albums');
      queryClient.invalidateQueries('project');
    },
  });

  return {
    createAlbumFromImages: (name: string, images: File[], projectId?: string) =>
      createAlbumMutation.mutate({ name, images, projectId }),
    isLoading: createAlbumMutation.isLoading,
    isError: createAlbumMutation.isError,
    error: createAlbumMutation.error,
  };
};

export default useCreateAlbum;
