import { motion } from 'framer-motion';
import { useCallback, useState } from 'react';

import 'tldraw/tldraw.css';

import React from 'react';

//Custom tools

import { useCollabStore } from '../TlDraw/hooks/useCollabStore';
import { setDefaultPalette } from '../TlDraw/tldrawConfig';

import { TldrawCustomEditor } from '../TlDraw/TlDrawCustomEditor';
import {
  Editor,
  getDefaultColorTheme,
  TLDrawShape,

  useReactor,
} from 'tldraw';
import { TLDrawShapeCustom } from '../TlDraw/CustomTools/CustomDraw/CustomDrawShapeUtil';

declare global {
  interface Window {
    tldrawReady: boolean;
  }
}

setDefaultPalette();

export default function TldrawWhiteboard({
  children,
  whiteboardId,
}: {
  children: React.ReactNode;
  whiteboardId: string;
}) {
  //Editor instance
  const [editor, setEditor] = useState<Editor | null>(null);

  //Store
  const { store } = useCollabStore(editor, whiteboardId);

  //save camera position to local storage
  useReactor(
    'save camera position',
    () => {
      if (!editor) return;
      const cameraPos = editor.getCamera();
      /*       console.log('cameraPos', cameraPos); */

      localStorage.setItem(
        'cameraPos' + whiteboardId,
        JSON.stringify(cameraPos)
      );
    },
    [editor]
  );

  const convertDrawShapeToCustomShape = useCallback(
    (shape: TLDrawShape, editor: Editor) => {
      const color = shape.props.color;
      const isDarkMode = editor.user.getIsDarkMode();
      const theme = getDefaultColorTheme({ isDarkMode });

      const hexColor = theme[color].solid;

      editor.createShape<TLDrawShapeCustom>({
        ...shape,
        props: {
          ...shape.props,
          hexColor,
        },
        type: 'drawCustom',
      });
    },
    []
  );

  return (
    <div className='relative grid h-dvh w-screen overflow-hidden md:grid-rows-1'>
      <TldrawCustomEditor
        initialState='select'
        store={store}
        maxImageDimension={600}
        onMount={(editorInstance) => {
          //Here we extract the editor instance
          setEditor(editorInstance);

          // Converting shapes to corresponding custom shapes
          // Our image shape just overrides tldraw base image shape, so we don't need to convert it
          const shapes = editorInstance.getCurrentPageShapesSorted();
          shapes.forEach((shape) => {
            switch (shape.type) {
              case 'draw':
                convertDrawShapeToCustomShape(
                  shape as TLDrawShape,
                  editorInstance
                );
                break;
              case 'geo':
                editorInstance.createShape({
                  ...shape,
                  type: 'geoCustom',
                });
                break;
              case 'text':
                editorInstance.createShape({
                  ...shape,
                  type: 'textCustom',
                });
                break;

              default:
                break;
            }
          });

          //Set to previous camera position
          const cameraPos = localStorage.getItem('cameraPos' + whiteboardId);
          if (cameraPos) {
            const cameraPosObj = JSON.parse(cameraPos);

            editorInstance.setCamera(cameraPosObj);
          }
        }}>
        <motion.div
          layout
          className='pointer-events-none absolute z-350 flex h-full w-full items-end justify-end
            overflow-hidden'>
          {/* The panel is rendered inside the tldraw editor, so it has access to useEditor and all hooks that depend on it like useEditorIO */}
          {children}
        </motion.div>
      </TldrawCustomEditor>
    </div>
  );
}
