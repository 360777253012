import { useCallback, useMemo, useState } from 'react';
import { faChalkboard, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Whiteboard } from '@/types/api';

import { type Option } from '@/components/lib/Dropdown/Dropdown';

import useAddWhiteboardToProject from '@/hooks/whiteboard/useAddWhiteboardToProject';

import Button from '@/components/lib/Button';

import Card from '@/components/lib/Containers/Card';
import BlurModal from '@/components/lib/Containers/BlurModal';

import { NewWhiteboardForm } from './NewWhiteboardForm';
import WhiteboardDropdown from '@/components/Common/Dropdowns/WhiteboardDropdown';
import {useTranslation} from "react-i18next";

interface AddWhiteboardModalProps {
  isOpen: boolean;
  onClose: () => void;
  projectId: string;
  existingWhiteboards?: Whiteboard[];
}

export const AddWhiteboardModal = ({
  isOpen,
  onClose,
  projectId,

  existingWhiteboards,
}: AddWhiteboardModalProps) => {
  const { t} = useTranslation('dashboard');
  const { addWhiteboardToProject } = useAddWhiteboardToProject();

  const [selected, setSelected] = useState<string[]>([]);

  const onChange = useCallback(
    (option: Option[]) => {
      setSelected(option.map((el) => el.id));
    },
    [setSelected]
  );

  const dropdownInitialValue = useMemo(() => [], []);

  return (
    <BlurModal
      isOpen={isOpen}
      setIsOpen={() => {
        onClose();
      }}>
      <Card className='gap-6 max-w-[95vw] w-150'>
        <Card.Header icon={faChalkboard}>
          <div className='flex items-center justify-between gap-10'>
            {t("pages.project.whiteboards.addWhiteboard")}
            <Button leftIcon={faTimes} round onClick={onClose} />
          </div>
        </Card.Header>
        <Card.Section>
          <Card.SubSectionHeader icon={faChalkboard}>
            {t("pages.project.whiteboards.addAnyWhiteboard")}
          </Card.SubSectionHeader>

          <WhiteboardDropdown
            whiteboardsToExclude={existingWhiteboards?.map((el) => el.id)}
            onChange={onChange}
            multipleSelect
            initialValue={dropdownInitialValue}
            selectedItemsPosition={'aboveList'}
          />
          <Button
            variant={'accent'}
            onClick={() => {
              for (const whiteboardId of selected) {
                console.log('adding whiteboard', whiteboardId);
                addWhiteboardToProject(whiteboardId, projectId);
                onClose();
              }
            }}
            state={selected.length === 0 ? 'disabled' : 'default'}>
            {t("pages.project.whiteboards.addSelectedWhiteboards")}
          </Button>
        </Card.Section>
        <Card.Section>
          <Card.SectionHeader>
            {t("pages.project.whiteboards.createNewWhiteboard")}
          </Card.SectionHeader>
          <Card.Separator></Card.Separator>
          <NewWhiteboardForm
            integrated={true}
            onClose={onClose}
            projectId={projectId}
          />
        </Card.Section>
      </Card>
    </BlurModal>
  );
};
