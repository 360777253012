import { motion } from 'framer-motion';

import DropdownSearch from './DropdownSearch';
import { useDropdownSearch } from '../hooks/useDropdownSearch';
import {
  DropdownElementProps,
  DropdownHeaderProps,
  type Option,
} from '../Dropdown';
import Button from '../../Button';
import { faTimes, IconDefinition } from '@fortawesome/free-solid-svg-icons';


type DropdownContentProps = {
  dropdownPosition: 'top' | 'bottom';
  Header?: React.FC<DropdownHeaderProps>;
  search?: boolean;
  options: Option[];
  selected: Option[];
  icon?: IconDefinition;

  selectedItemsPosition: 'withinList' | 'aboveList';

  onSelect: (option: Option) => void;
  onClose: () => void;
  onReset: () => void;
  maximumVisibleOptions: number;
};

const DropdownContent = ({
  dropdownPosition,
  Header,
  search,
  options,
  selected,
  icon,

  selectedItemsPosition,
  onSelect,
  onClose,
  onReset,
  maximumVisibleOptions,
}: DropdownContentProps) => {
  //TODO: use the more general useSearch hook instead? or use it inside the useDropdownSearch
  const { searchValue, setSearchValue, filteredOptions } = useDropdownSearch(
    options,
    maximumVisibleOptions
  );


  return (
    <motion.div
      layout
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -20 }}
      style={{ borderRadius: '12px' }}
      className={`absolute left-0 ${dropdownPosition === 'bottom' ? 'top-full' : 'bottom-full'}
        bg-surface-primary z-4000 mt-2 mb-2 flex w-full flex-col gap-2 shadow-2xl
        rounded-xl py-2`}>
      {/* CUSTOM HEADER */}
      {Header && <Header close={onClose} reset={onReset} selected={selected} />}
      {/* SEARCH */}
      {search && (
        <DropdownSearch
          searchValue={searchValue}
          setSearchValue={setSearchValue}
        />
      )}
      <motion.div
        layout
        className='bg-surface-primary flex max-h-[250px] flex-col overflow-y-scroll'>
        {/* SELECTED OPTIONS */}
        {selected.length > 0 && selectedItemsPosition === 'aboveList' && (
          <div className='border-text-accent bg-surface-tertiary/4 border rounded-lg'>
            {selected.map((option) => (
              <motion.div
                layout
                key={option.id}
                className=''
                onClick={() => onSelect(option)}>
                {/* If no element is provided, use the default element */}
                {option.element ? (
                  <option.element
                    reset={onReset}
                    close={onClose}
                    isSelected={true}
                  />
                ) : (
                  <DefaultDropdownElement
                    option={option}
                    isSelected={true}
                    icon={icon}
                  />
                )}
              </motion.div>
            ))}
          </div>
        )}

        {/* OPTIONS */}
        {filteredOptions.map((option) => {
          const isOptionSelected = selected.some((el) => el.id === option.id);
          if (selectedItemsPosition === 'aboveList' && isOptionSelected)
            return null;

          return (
            <motion.div
              layout
              key={option.id}
              className={
                isOptionSelected
                  ? 'border-text-accent bg-surface-tertiary/4 border rounded-lg'
                  : ''
              }
              onClick={() => onSelect(option)}>
              {/* If no element is provided, use the default element */}
              {option.element ? (
                <option.element
                  reset={onReset}
                  close={onClose}
                  isSelected={isOptionSelected}
                />
              ) : (
                <DefaultDropdownElement
                  option={option}
                  isSelected={isOptionSelected}
                  icon={icon}
                />
              )}
            </motion.div>
          );
        })}
      </motion.div>
    </motion.div>
  );
};

type DefaultDropdownElementProps = {
  option: Option;
  icon?: IconDefinition;
} & DropdownElementProps;

const DefaultDropdownElement = ({
  option,
  isSelected,
  icon,
}: DefaultDropdownElementProps) => {
  return (
    <div
      className='hover:bg-surface-tertiary hover:text-text-negative border-b-surface-secondary
        flex cursor-pointer items-center gap-2 border-b p-1'>
      <div className='aspect-square rounded-md p-1'>
        {icon && (
          <Button
            size='xs'
            iconSize='10px'
            variant='primary'
            leftIcon={isSelected ? faTimes : icon}
          />
        )}
      </div>
      <p>{option.label}</p>
    </div>
  );
};

export default DropdownContent;
