import { Editor } from 'tldraw';
import { TLImageShapeCustom } from '../CustomShapeUtil/CustomImageShapeUtil';
import { resizeEraserPaths } from '../CustomTools/sharedFeatures/EraserMaskFeature';

export const lockMaxImageDimension = (
  editor: Editor,
  maxImageDimension: number
) => {
  editor.sideEffects.registerBeforeChangeHandler('shape', (prev, next) => {
    if (prev.type === 'image') {
      const prevShape = prev as TLImageShapeCustom;
      const nextShape = next as TLImageShapeCustom;

      if (nextShape.props.h > maxImageDimension) {
        console.log('shape too big');

        //If the shape has shrunk, we allow it
        if (nextShape.props.h < prevShape.props.h) {
          return next;
        }

        //If the size has not changed, we allow all other changes
        if (nextShape.props.h === prevShape.props.h) {
          return next;
        }

        //If the shape has grown, we lock it to 500px height, and also lock the eraser Paths
        return {
          ...next,
          x: prevShape.x,
          y: prevShape.y,
          props: {
            ...next.props,
            h: prevShape.props.h,
            w: prevShape.props.w,
            eraserPaths: prevShape.props.eraserPaths,
          },
        };
      }
    }
    return next;
  });
  // On create
  editor.sideEffects.registerBeforeCreateHandler('shape', (shape) => {
    if (shape.type === 'image') {
      const image = shape as TLImageShapeCustom;

      if (image.props.h > maxImageDimension) {
        //If the image being created already has eraserPath (for example if it has been deleted, then the user wants to undo the deletion), we need to scale them, or just return the image as is
        if (image.props.eraserPaths.length > 0) {
          //Return as is
          return image;

          //Calculate the scaling factor between the original and the new image
          const scaleFactor = maxImageDimension / image.props.h;

          //Scale the eraser paths
          const newEraserSegments = resizeEraserPaths(image, {
            scaleX: scaleFactor,
            scaleY: scaleFactor,
          });

          //Return the new image with the scaled eraser paths and the new dimensions
          return {
            ...image,
            props: {
              ...image.props,
              h: maxImageDimension,
              w: (image.props.w / image.props.h) * maxImageDimension,
              eraserPaths: newEraserSegments,
            },
          };
        }

        return {
          ...image,
          props: {
            ...image.props,
            h: maxImageDimension,
            w: (image.props.w / image.props.h) * maxImageDimension,
          },
        };
      }
    }
    return shape;
  });
};
