import useClickOutside from '@/hooks/useClickOutside';
import { faBoltLightning, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from '@mui/material';
import { Children, useCallback, useRef, useState } from 'react';
import { mergeProps, useLongPress, usePress } from 'react-aria';
import { ReactNode } from 'react';

import {
  DefaultToolbarProps,
  DefaultToolbar,
  ArrowToolbarItem,
  AssetToolbarItem,
  SelectToolbarItem,
  TldrawUiMenuToolItem,
  useTools,
  ToolbarItemProps,
  TLUiToolItem,
  useEditor,
  useValue,
  track,
  HandToolbarItem,
  useUiEvents,
  TLUiEventData,
  TLUiEventMap,
  TldrawUiMenuContextProvider,
  PORTRAIT_BREAKPOINT,
  useBreakpoint,
  useTldrawUiComponents,
  MobileStylePanel,
} from 'tldraw';
import { useOnUiEvent } from '../hooks/useOnUiEvent';
import classNames from 'classnames';

function CustomToolbar(props: DefaultToolbarProps) {
  const isMobile = useMediaQuery('(max-width: 729px)');

  // Reposition the toolbar on mobile so it's not behind the panel bar
  // (which moves at the bottom of the screen at this breakpoint)
  if (isMobile) return <MobileToolBar />;

  return (
    <DefaultToolbar {...props}>
      <ToolbarContent />
    </DefaultToolbar>
  );
}

const ToolbarContent = () => {
  /// Redefine useIsToolSelected because the tldraw version
  /// does not work with our custom geo tools.
  /* Originale code:
 export function useIsToolSelected(tool: TLUiToolItem) {
   const editor = useEditor()
   const geo = tool.meta?.geo
   return useValue(
     'is tool selected',
     () => {
       const activeToolId = editor.getCurrentToolId()
       const geoState = editor.getSharedStyles().getAsKnownValue(GeoShapeGeoStyle)
       return geo ? activeToolId === 'geo' && geoState === geo : activeToolId === tool.id
     },
     [editor, tool.id, geo]
   )
} */

  /// Copy ToolbarItem code from tldraw in order to use our useIsToolSelected
  /// hook (code unchanged).

  return (
    <TldrawUiMenuContextProvider type='toolbar' sourceId='toolbar'>
      <div className='flex items-center'>
        {/* This outer div is necessary to prevent the default overflow behavior of the tldraw toolbar (see OverflowingToolbar.tsx component) */}
        <ToolbarItem tool='drawToolCustom' />

        <ToolSwitch id='shapes'>
          <ToolbarItem tool='rectangleToolCustom' />
          <ToolbarItem tool='ellipseToolCustom' />
          <ToolbarItem tool='triangleToolCustom' />
          <ToolbarItem tool='diamondToolCustom' />
          <ToolbarItem tool='hexagonToolCustom' />
          <ToolbarItem tool='ovalToolCustom' />
          <ToolbarItem tool='rhombusToolCustom' />
          <ToolbarItem tool='starToolCustom' />
          <ToolbarItem tool='cloudToolCustom' />
          <ToolbarItem tool='x-boxToolCustom' />
          <ToolbarItem tool='check-boxToolCustom' />
          <ToolbarItem tool='arrow-leftToolCustom' />
          <ToolbarItem tool='arrow-upToolCustom' />
          <ToolbarItem tool='arrow-downToolCustom' />
          <ToolbarItem tool='arrow-rightToolCustom' />
          <ToolbarItem tool='lineToolCustom' />
        </ToolSwitch>

        <AssetToolbarItem />

        <ToolSwitch id='erasers'>
          <ToolbarItem tool='customEraser' />
          <ToolbarFastEraserItem />
        </ToolSwitch>

        <ArrowToolbarItem />
        <ToolbarItem tool='textToolCustom' />

        <ToolbarSeparator />

        <SelectToolbarItem />
        <HandToolbarItem />
      </div>
    </TldrawUiMenuContextProvider>
  );
};

const MobileToolBar = () => {
  const editor = useEditor();

  const { ActionsMenu, QuickActions } = useTldrawUiComponents();

  const breakpoint = useBreakpoint();

  const showQuickActions =
    editor.options.actionShortcutsLocation === 'menu'
      ? false
      : editor.options.actionShortcutsLocation === 'toolbar'
        ? true
        : breakpoint < PORTRAIT_BREAKPOINT.TABLET;

  return (
    <div
      style={{
        position: 'relative',
        bottom: 105,
        left: 0,
      }}>
      <div className='absolute top-0 right-0 tlui-toolbar__tools"'>
        <MobileStylePanel />
      </div>
      <div className='tlui-toolbar__extras'>
        {showQuickActions && (
          <div className='tlui-toolbar__extras__controls tlui-buttons__horizontal'>
            {QuickActions && <QuickActions />}
            {ActionsMenu && <ActionsMenu />}
          </div>
        )}
      </div>
      <div
        className={classNames('tlui-toolbar__tools', {
          'tlui-toolbar__tools__mobile':
            breakpoint < PORTRAIT_BREAKPOINT.TABLET_SM,
        })}>
        <ToolbarContent />
      </div>
    </div>
  );
};

const ToolSwitch = track(
  ({ children, id }: { children: ReactNode[]; id: string }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);

    const [selectedChild, setSelectedChild] = useState(children[0]);

    const ref = useRef(null);
    // const editor = useEditor();

    // //Subscribing to other tool switches to close the modal when another one is opened
    useOnUiEvent((name, data) => {
      console.log('UI event', name, data);
      if (name === 'open-menu' && data.source === 'toolbar') {
        const { id: otherId } = data as TLUiEventData<
          TLUiEventMap['open-menu']
        >;
        if ('toolswitch opened' + id !== otherId) {
          setIsModalOpen(false);
        }
      }
    });

    //Emitting event when opening the switch
    const trackEvent = useUiEvents();
    const emitEvent = useCallback(() => {
      trackEvent('open-menu', {
        source: 'toolbar',
        id: 'toolswitch opened' + id,
      });
    }, [trackEvent, id]);

    //React-aria hooks for long press and press
    const { longPressProps } = useLongPress({
      accessibilityDescription: 'Long press to activate modal',
      onLongPress: () => {
        setIsModalOpen(true);
        emitEvent();
      },
    });

    const { pressProps } = usePress({
      onPressUp: () => {
        emitEvent();
      },
    });

    useClickOutside(ref, () => {
      setIsModalOpen(false);
    });

    return (
      <div className='relative' {...mergeProps(longPressProps, pressProps)}>
        {/* The modal containing the other versions of the tool */}
        {isModalOpen ? (
          <div
            ref={ref}
            className='tlui-menu absolute bottom-full left-0 grid h-min w-fit'
            style={{
              gridTemplateColumns:
                children.length > 4
                  ? 'repeat(4, 1fr)'
                  : `repeat(${children.length}, 1fr)`,
            }}>
            {/* We map over the children to wrap them in a div that handles
              - closing the modal
              - setting the child as the selected one
              - emitting an event to track the selection              
              */}
            {Children.map(children, (child, index) => (
              <div
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsModalOpen(false);
                  setSelectedChild(child);
                }}
                onTouchStart={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsModalOpen(false);
                  setSelectedChild(child);
                }}>
                {child}
              </div>
            ))}
          </div>
        ) : null}

        {/* Current selected variation of the tool */}
        <div className='relative'>
          {/* Carret to indicate it's a switch for other versions
          No longpress needed when clicking directly on the carret */}

          <div
            className=''
            onClick={() => {
              console.log('clicked');
              emitEvent();
              setIsModalOpen(true);
            }}>
            <FontAwesomeIcon
              icon={faCaretUp}
              className='absolute top-0 right-1 z-100 rotate-45 p-1'
            />
          </div>
          {selectedChild}
        </div>
      </div>
    );
  }
);

const ToolbarFastEraserItem = () => {
  const editor = useEditor();

  const isNormalEraserSelected = useValue(
    'is normal eraser',
    () => {
      const currentTool = editor.getCurrentToolId();
      return currentTool === 'eraser';
    },
    [editor]
  );

  return (
    <div className='relative'>
      <FontAwesomeIcon
        icon={faBoltLightning}
        className='absolute top-5 left-[55%] z-100 rotate-[20deg]'
        style={{ color: isNormalEraserSelected ? 'white' : 'black' }}
      />
      <ToolbarItem tool='eraser' />
    </div>
  );
};

export function ToolbarItem({ tool }: ToolbarItemProps) {
  const tools = useTools();
  const isSelected = useIsToolSelected(tools[tool]);
  return <TldrawUiMenuToolItem toolId={tool} isSelected={isSelected} />;
}

function useIsToolSelected(tool: TLUiToolItem) {
  const editor = useEditor();
  return useValue(
    'is tool selected',
    () => {
      return tool.id === editor.getCurrentToolId();
    },
    [editor, tool.id]
  );
}

const ToolbarSeparator = () => {
  return <div className='mx-1 h-6 w-px bg-gray-400' />;
};

export default CustomToolbar;
