import useGetCreations from '@/hooks/creations/useGetCreations';

import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';

import useGetProjects from '@/hooks/projects/useGetProjects';
import { ProjectItem } from '../Projects/ProjectItem';
import { WhiteboardItem } from '../Whiteboards/WhiteboardItem';
import { CreationCard } from '../Creations/CreationCard';
import { ReactElement, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  faBookOpen,
  faChalkboard,
  faFolder,
  faImages,
  faPlusCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/lib/Button';
import { useCreateWhiteboard } from '@/hooks/whiteboard/useCreateWhiteboard';
import useCreateProject from '@/hooks/projects/useCreateProject';
import Tabs from '@/components/lib/Tabs/Tabs';

import Collection from '@/components/lib/Containers/Collection';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewWhiteboardForm } from '@/components/Common/ItemsForms/NewWhiteboardForm';

import { NewProjectForm } from '@/components/Common/ItemsForms/NewProjectForm';
import { NewAlbumForm } from '@/components/Common/ItemsForms/NewAlbumForm';
import {useNavigate} from "react-router-dom";

const Homepage = () => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const { createNewWhiteboard } = useCreateWhiteboard((whiteboard) => {
    window.open('/creator/' + whiteboard.id, '_blank')?.focus();
  });

  const { createProject } = useCreateProject((project) => {
    window.open('/projects/' + project.id, '_blank')?.focus();
  });

  const [activeAddModal, setActiveAddModal] = useState<
    'whiteboard' | 'album' | 'project' | null
  >(null);

  const createCards = useMemo(
    () => [
      {
        title: t('newItems.newWhiteboard') as string,
        desc: t('homepage.newWhiteboardDesc'),
        icon: faChalkboard,
        action: () => {
          setActiveAddModal('whiteboard');
        },
      },
      {
        title: t('newItems.newProject') as string,
        desc: t('homepage.newProjectDesc'),
        icon: faFolder,
        action: () => {
          setActiveAddModal('project');
        },
      },
      {
        title: t('newItems.newAlbum') as string,
        desc: t('homepage.newAlbumDesc'),
        icon: faBookOpen,
        action: () => {
          setActiveAddModal('album');
        },
      },
    ],
    [t, createNewWhiteboard, createProject]
  );

  return (
    <div className='bg-surface-secondary flex h-full w-full flex-col gap-12 overflow-y-auto'>
      <BlurModal
        isOpen={!!activeAddModal}
        setIsOpen={() => {
          setActiveAddModal(null);
        }}>
        {activeAddModal === 'whiteboard' && (
          <NewWhiteboardForm
            onClose={(whiteboard) => {
              if (whiteboard != null && whiteboard.id != null) {
                // Navigate to dashboard.
                navigate(`/creator/${whiteboard.id}`);
              }
              setActiveAddModal(null);
            }}
          />
        )}
        {activeAddModal === 'project' && (
          <NewProjectForm
            onClose={(project) => {
              if (project != null && project.id != null) {
                navigate(`/projects/${ project.id}`);
              }
              setActiveAddModal(null);
            }}
          />
        )}
        {activeAddModal === 'album' && (
          <NewAlbumForm
            onClose={(album) => {
              if (album != null && album.id != null) {
                navigate(`/albums/${ album.id }`);
              }
              setActiveAddModal(null);
            }}
          />
        )}
      </BlurModal>

      <Section>
        <SectionTitle title={t('homepage.newIdeation')} />
        <div className='flex max-w-[1200px] flex-col gap-4 md:flex-row xl:gap-8'>
          {createCards.map((card) => (
            <CreateCard
              icon={card.icon}
              key={card.title}
              title={card.title}
              desc={card.desc}
              action={card.action}
            />
          ))}
        </div>
      </Section>
      <Section>
        <Tabs
          selectorId='homepage-tabs'
          TabHeader={({ tabNav }) => {
            return (
              <div className='flex flex-col items-center gap-4 md:flex-row'>
                <SectionTitle title={t('homepage.resumeIdeation')} />
                {tabNav}
              </div>
            );
          }}
          tabs={[
            {
              content: <RecentWhiteboards />,
              title: t('homepage.recent-whiteboards'),
              id: 'whiteboards',
              icon: faChalkboard,
            },
            {
              content: <RecentProjects />,
              title: t('homepage.recent-projects'),
              id: 'projects',
              icon: faFolder,
            },
            {
              content: <RecentCreations />,
              title: t('homepage.recent-creations'),
              id: 'creations',
              icon: faImages,
            },
          ]}
        />
      </Section>
    </div>
  );
};

const SectionTitle = ({ title }: { title: string }) => {
  return (
    <h1 className='text-xl sm:text-2xl text-center md:text-left font-bold'>
      {title}
    </h1>
  );
};

const Section = ({ children }: { children: ReactElement[] | ReactElement }) => {
  return <div className='flex flex-col gap-8'>{children}</div>;
};

const CreateCard = ({
  title,
  desc,
  action,
  icon,
}: {
  title: string;
  desc: string;
  action: () => void;
  icon: IconDefinition;
}) => {
  const { t } = useTranslation('dashboard');

  return (
    <div
      className='bg-surface-primary flex w-full flex-col items-center justify-center gap-2
        overflow-hidden rounded-2xl p-4 text-center transition-all hover:scale-101
        hover:saturate-150 sm:gap-6 md:aspect-square lg:p-8 xl:p-10 hover:shadow-lg'>
      <div className='hidden aspect-square rounded-full border-2 border-gray-400 p-4 lg:block'>
        <FontAwesomeIcon icon={icon} className='text-base xl:text-5xl' />
      </div>
      <h2 className='text-xl font-bold xl:text-2xl'>{title}</h2>
      <p className='text-center'>{desc}</p>
      <Button onClick={action} variant={'accent'} leftIcon={faPlusCircle}>
        {t('header.create')}{' '}
      </Button>
    </div>
  );
};

const RecentWhiteboards = () => {
  const { whiteboards } = useGetWhiteboards('visible');
  if (!whiteboards) return null;

  return (
    <Collection>
      {whiteboards.slice(0, 3).map((whiteboard) => (
        <WhiteboardItem key={whiteboard.id} whiteboardId={whiteboard.id} />
      ))}
    </Collection>
  );
};

const RecentProjects = () => {
  const { projects } = useGetProjects('visible');
  if (!projects) return null;

  return (
    <Collection>
      {projects.slice(0, 3).map((project) => (
        <ProjectItem projectId={project.id} key={project.id} />
      ))}
    </Collection>
  );
};

const RecentCreations = () => {
  const { creations } = useGetCreations();
  if (!creations) return null;

  return (
    <Collection>
      {creations.slice(0, 3).map((creation) => (
        <CreationCard creationId={creation.id} key={creation.id} />
      ))}
    </Collection>
  );
};

export default Homepage;
