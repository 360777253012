import { useSearch } from '@/components/lib/Dropdown/hooks/useSearch';
import {
  faBolt,
  faBookOpen,
  faChalkboard,
  faFolder,
  faImages,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState, useEffect, KeyboardEvent, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewProjectForm } from '@/components/Common/ItemsForms/NewProjectForm';
import { NewWhiteboardForm } from '@/components/Common/ItemsForms/NewWhiteboardForm';
import { CreationDetail } from '../Creations/CreationDetail';
import useClickOutside from '@/hooks/useClickOutside';

// Import types
import { ActionType, Action, CategoryType } from './types';

// Import hooks
import { useComboboxActions } from './hooks/useComboboxActions';
import { useRecentActions } from './hooks/useRecentActions';
import { useKeyboardNavigation } from './hooks/useKeyboardNavigation';

// Import components
import { CategorySection } from './components/CategorySection';

/**
 * Combobox Component
 *
 * A command palette-like component that provides a unified search interface for:
 * - Navigating to different pages
 * - Creating new items (projects, whiteboards)
 * - Accessing existing creations, albums, projects, and whiteboards
 *
 * Features:
 * - Keyboard navigation (Arrow Up/Down, Enter, Escape)
 * - Fuzzy search
 * - Recent actions tracking
 * - Category-based organization
 * - Modals for creation and details
 *
 * Keyboard shortcuts:
 * - Ctrl+K: Toggle combobox
 * - Arrow Up/Down: Navigate through items
 * - Enter: Select current item
 * - Escape: Close combobox
 *
 * @component
 * @example
 * // To add to a page:
 * <Combobox />
 */
const Combobox = () => {
  const { t } = useTranslation('dashboard');
  const navigate = useNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const comboboxRef = useRef<HTMLDivElement>(null);

  const [isFocused, setIsFocused] = useState(false);
  const [selectedId, setSelectedId] = useState<string | null>(null);

  const {
    actions,
    creationDetailModalOpen,
    setCreationDetailModalOpen,
    createModalOpen,
    setCreateModalOpen,
  } = useComboboxActions();

  const { lastActionsItems, saveExecutedAction } = useRecentActions(actions);

  useKeyboardNavigation(inputRef, setIsFocused);
  useClickOutside(comboboxRef, () => setIsFocused(false));

  const { filteredItems, searchValue, setSearchValue } = useSearch(actions, {
    keys: ['searchLabel'],
    threshold: 0.3,
  });

  // Reset selection when search changes
  useEffect(() => {
    setSelectedId(null);
  }, [searchValue]);

  /**
   * Define action categories that will appear in the combobox
   *
   * To add a new category:
   * 1. Add a new entry to this array with:
   *    - type: unique identifier (must match Action.type values)
   *    - icon: FontAwesome icon to display
   *    - title: Display name of the category
   * 2. Add corresponding actions in useComboboxActions.ts
   */
  const actionTypes: ActionType[] = useMemo(
    () => [
      { type: 'creation', icon: faImages, title: t('pageNames.creations') },
      { type: 'album', icon: faBookOpen, title: t('pageNames.albums') },
      { type: 'project', icon: faFolder, title: t('pageNames.projects') },
      {
        type: 'whiteboard',
        icon: faChalkboard,
        title: t('pageNames.whiteboards'),
      },
      { type: 'page', icon: faBolt, title: 'Actions' },
      { type: 'create', icon: faPlus, title: t('header.create') },
    ],
    [t]
  );

  // Group items by category
  const categorizedItems = useMemo(() => {
    const categories = actionTypes
      .map((actionType) => {
        const items =
          filteredItems?.filter((item) => item.type === actionType.type) || [];
        if (items.length === 0) return null;

        return {
          title: actionType.title,
          type: actionType.type,
          icon: actionType.icon,
          items,
        };
      })
      .filter(Boolean) as CategoryType[];

    // Add recent actions category if available
    if (lastActionsItems.length > 0) {
      categories.unshift({
        title: t('combobox.recents'),
        type: 'lastActions',
        icon: faBolt,
        items: lastActionsItems,
      });
    }

    return categories;
  }, [actionTypes, filteredItems, lastActionsItems, t]);

  // Get all items for keyboard navigation
  const allItems = useMemo(() => {
    return categorizedItems.flatMap((category) => category.items);
  }, [categorizedItems]);

  /**
   * Handle keyboard navigation through the combobox
   *
   * @param {KeyboardEvent} e - Keyboard event
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (!isFocused || allItems.length === 0) return;

    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        if (!selectedId) {
          setSelectedId(allItems[0].id);
        } else {
          const currentIndex = allItems.findIndex(
            (item) => item.id === selectedId
          );
          const nextIndex = (currentIndex + 1) % allItems.length;
          setSelectedId(allItems[nextIndex].id);
        }
        break;
      case 'ArrowUp':
        e.preventDefault();
        if (!selectedId) {
          setSelectedId(allItems[allItems.length - 1].id);
        } else {
          const currentIndex = allItems.findIndex(
            (item) => item.id === selectedId
          );
          const nextIndex =
            (currentIndex - 1 + allItems.length) % allItems.length;
          setSelectedId(allItems[nextIndex].id);
        }
        break;
      case 'Enter':
        if (selectedId) {
          const selectedItem = allItems.find((item) => item.id === selectedId);
          if (selectedItem) {
            saveExecutedAction(selectedItem.id);
            selectedItem.action();
            setIsFocused(false);
          }
        }
        break;
      case 'Escape':
        setIsFocused(false);
        inputRef.current?.blur();
        break;
    }
  };

  /**
   * Handle click on an action item
   *
   * @param {Action} item - The action item that was clicked
   */
  const handleItemClick = (item: Action) => {
    saveExecutedAction(item.id);
    item.action();
    setIsFocused(false);
  };

  return (
    <div
      ref={comboboxRef}
      className='relative w-full bg-surface-secondary text-base md:w-[30vw]'>
      {/* Create modals */}
      <BlurModal
        isOpen={!!createModalOpen}
        setIsOpen={() => setCreateModalOpen(null)}>
        {createModalOpen === 'whiteboard' ? (
          <NewWhiteboardForm
            onClose={(whiteboard) => {
              if (whiteboard) {
                navigate(`/creator/${whiteboard.id}`);
              }
              setCreateModalOpen(null);
            }}
          />
        ) : (
          <NewProjectForm
            onClose={(project) => {
              if (project) {
                navigate(`/projects/${project.id}`);
              }
              setCreateModalOpen(null);
            }}
          />
        )}
      </BlurModal>

      {/* Creation detail modal */}
      <BlurModal
        isOpen={!!creationDetailModalOpen}
        setIsOpen={() => setCreationDetailModalOpen(null)}>
        <CreationDetail
          creationId={creationDetailModalOpen!}
          onClose={() => setCreationDetailModalOpen(null)}
        />
      </BlurModal>

      {/* Search input */}
      <input
        ref={inputRef}
        className='w-full rounded-2xl bg-surface-primary px-4 py-2 text-sm'
        type='text'
        placeholder={t('combobox.placeholder')}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          setIsFocused(true);
        }}
        onFocus={() => setIsFocused(true)}
        onKeyDown={handleKeyDown}
      />

      {/* Dropdown */}

      {isFocused && allItems.length > 0 && (
        <div
          className='backdrop-brightness-20 bg-surface-primary absolute z-100 mt-4 flex max-h-[50vh]
            w-full flex-col gap-3 overflow-y-auto rounded-xl p-4 text-base backdrop-blur-lg'>
          {categorizedItems.map((category) => (
            <CategorySection
              key={category.type}
              category={category}
              selectedId={selectedId}
              onItemSelect={setSelectedId}
              onItemClick={handleItemClick}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default Combobox;
