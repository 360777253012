import {
  Box,
  TLDefaultFillStyle,
  TLDefaultFontStyle,
  TLDefaultHorizontalAlignStyle,
  TLDefaultVerticalAlignStyle,
  TLShapeId,
} from '@tldraw/editor';
import React, { ReactNode, useEffect, useState } from 'react';
import { TextHelpers } from '../TldrawExports';
import { TextArea, useEditableText } from 'tldraw';


///Eraser function is implemented but commented out
///It doesn't work well with text, because you can edit them, hence changing their size

/** @public */
export interface TextLabelProps {
  shapeId: TLShapeId;
  type: string;
  font: TLDefaultFontStyle;
  fontSize: number;
  lineHeight: number;
  fill?: TLDefaultFillStyle;
  align: TLDefaultHorizontalAlignStyle;
  verticalAlign: TLDefaultVerticalAlignStyle;
  wrap?: boolean;
  text: string;
  labelColor: string;
  bounds?: Box;
  isNote?: boolean;
  isSelected: boolean;
  onKeyDown?(e: React.KeyboardEvent<HTMLTextAreaElement>): void;
  classNamePrefix?: string;
  style?: React.CSSProperties;
  textWidth?: number;
  textHeight?: number;
  padding?: number;
  eraserMask?: ReactNode;
}

/** @public @react */
export const TextLabel = React.memo(function TextLabel({
  shapeId: shapeId,
  type,
  text,
  labelColor,
  font,
  fontSize,
  lineHeight,
  align,
  verticalAlign,
  wrap,
  isSelected,
  padding = 0,
  onKeyDown: handleKeyDownCustom,
  classNamePrefix,
  style,
  textWidth,
  textHeight,
  eraserMask,
}: TextLabelProps) {
  const { rInput, isEmpty, isEditing, isEditingAnything, ...editableTextRest } =
    useEditableText(shapeId, type, text);
  const [initialText, setInitialText] = useState(text);

  useEffect(() => {
    if (!isEditing) setInitialText(text);
  }, [isEditing, text]);

  const finalText = TextHelpers.normalizeTextForDom(text);
  const hasText = finalText.length > 0;

  /*  console.log(eraserMask); */

  if (!isEditing && !hasText) {
    return null;
  }

  // TODO: probably combine tl-text and tl-arrow eventually
  const cssPrefix = classNamePrefix || 'tl-text';
  return (
    <div
      className={`${cssPrefix}-label tl-text-wrapper relative`}
      data-font={font}
      data-align={align}
      data-hastext={!isEmpty}
      data-isediting={isEditing}
      data-iseditinganything={isEditingAnything}
      data-textwrap={!!wrap}
      data-isselected={isSelected}
      style={{
        justifyContent: align === 'middle' ? 'center' : align,
        alignItems: verticalAlign === 'middle' ? 'center' : verticalAlign,
        padding,
        ...style,
      }}>
      <div
        className={`${cssPrefix}-label__inner tl-text-content__wrapper z-10`}
        style={{
          fontSize,
          lineHeight: Math.floor(fontSize * lineHeight) + 'px',
          minHeight: Math.floor(fontSize * lineHeight) + 'px',
          minWidth: Math.ceil(textWidth || 0),
          color: labelColor,
          width: textWidth ? Math.ceil(textWidth) : undefined,
          height: textHeight ? Math.ceil(textHeight) : undefined,
        }}>
        <div
          className={`${cssPrefix} tl-text tl-text-content`}
          dir='auto'
          /*  style={{ maskImage: 'url(#eraserMask' + shapeId + ')' }} */
        >
          {finalText.split('\n').map((lineOfText, index) => (
            <div key={index} dir='auto'>
              {lineOfText}
            </div>
          ))}
        </div>
        {(isEditingAnything || isSelected) && (
          <TextArea
            ref={rInput}
            // We need to add the initial value as the key here because we need this component to
            // 'reset' when this state changes and grab the latest defaultValue.
            key={initialText}
            text={text}
            isEditing={isEditing}
            {...editableTextRest}
            handleKeyDown={
              handleKeyDownCustom ?? editableTextRest.handleKeyDown
            }
          />
        )}
      </div>

      {/* MASK */}
      <div className='absolute inset-0 mix-blend-multiply'>
        <svg id={'text-' + shapeId} height={'100%'} width={'100%'}>
          {eraserMask}
        </svg>
      </div>
    </div>
  );
});
