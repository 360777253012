import { useOutlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import { motion } from 'framer-motion';
/* import { useLocation } from 'react-router-dom';
import PageAnimationWrapper from './PageAnimationWrapper'; */
import PageHeader from './PageHeader';

const Layout = () => {
  /*   const location = useLocation(); */
  const element = useOutlet(); //We use the hook instead of component for the animation to work

  return (
    <div
      className='text-text-base grid- relative grid h-dvh w-full grid-cols-1
        grid-rows-[1fr_min-content] sm:grid-flow-row sm:grid-cols-[min-content_1fr]
        overflow-hidden'>
      <Sidebar />
      <motion.div
        layout='position'
        className='bg-surface-secondary relative order-1 flex h-full w-full flex-col
          overflow-hidden p-2 md:p-6'>
        <PageHeader />

        {/* FIXME: the animation sometimes causes the page not to appear */}
        {/*      <AnimatePresence mode='wait' initial={false}>
            <PageAnimationWrapper key={location.pathname}> */}
        {element && element}
        {/*         </PageAnimationWrapper>
          </AnimatePresence> */}
      </motion.div>
    </div>
  );
};

export default Layout;
