import { useState, useRef, useEffect, memo, useCallback } from 'react';
import { motion } from 'framer-motion';

import DropdownTrigger from './DropdownTrigger';
import DropdownContent from './components/DropdownContent';
import useClickOutside from '../../../hooks/useClickOutside';
import useDropdownPosition from '../../../hooks/useDropdownPosition';

import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

//TODO: add a default dropdown element for when the element prop is not provided
export type Option = {
  label: string;
  id: string;
  element?: React.FC<DropdownElementProps>;
};

export type DropdownElementProps = Partial<{
  isSelected: boolean;
  reset: () => void;
  close: () => void;
}>;

export type DropdownHeaderProps = {
  close?: () => void;
  reset?: () => void;
  selected?: Option[];
};

export type DropdownProps = {
  initialValue: Option[];
  multipleSelect?: boolean;
  icon?: IconDefinition;
  placeholder: string;
  onChange: (value: Option[]) => void;
  options: Option[];
  label?: string;
  resetString?: string;
  Header?: React.FC<DropdownHeaderProps>;
  selectedItemsPosition: 'withinList' | 'aboveList';
  isContentContained?: boolean;
  showSelectedWithinList?: boolean;
  maximumVisibleOptions?: number;
  search?: boolean;
  triggerStyles?: string;
};

/**
 * A customizable dropdown component that supports single and multiple selection.
 *
 * @example
 * // Basic single selection dropdown
 * const options = [
 *   {
 *     id: '1',
 *     label: 'Option 1',
 *     element: ({ isSelected, close }) => <div>Option 1</div>
 *   }
 * ];
 *
 * Be sure to wrap all props in useMemo and useCallback!!
 *
 * <Dropdown
 *   initialValue={[]}
 *   options={options}
 *   placeholder="Select an option"
 *   onChange={(selected) => console.log(selected)}
 *   selectedItemsPosition="withinList"
 * />
 *
 * @example
 * // Multiple selection dropdown with search and custom header
 * const CustomHeader = ({ selected, reset }) => (
 *   <div>
 *     <span>Selected: {selected.length}</span>
 *     <button onClick={reset}>Clear</button>
 *   </div>
 * );
 *
 * <Dropdown
 *   initialValue={[]}
 *   multipleSelect={true}
 *   options={options}
 *   placeholder="Select options"
 *   onChange={(selected) => console.log(selected)}
 *   Header={CustomHeader}
 *   search={true}
 *   selectedItemsPosition="aboveList"
 *   maximumVisibleOptions={5}
 * />
 *
 * @param {DropdownProps} props - The dropdown component props
 * @param {Option[]} props.initialValue - Initial selected values, Options must have an id, a label, and a render element, the render element receives isSelected, close, and reset functions as its props
 * @param {boolean} [props.multipleSelect=false] - Enable multiple selection mode
 * @param {Option[]} props.options - Available options to select from
 * @param {React.FC<DropdownHeaderProps>} [props.Header] - Optional custom header component
 * @param {(value: Option[]) => void} props.onChange - Callback when selection changes
 * @param {string} props.placeholder - Placeholder text when no selection
 * @param {boolean} [props.isContentContained=true] - Whether dropdown content is contained within parent
 * @param {IconDefinition} [props.icon] - Optional icon for the dropdown
 * @param {number} [props.maximumVisibleOptions=Infinity] - Maximum number of visible options
 * @param {boolean} [props.search=false] - Enable search functionality, it searches by option label. TODO: Add search by arbitrary key.
 * @param {string} [props.triggerStyles] - Additional styles for the dropdown trigger
 * @param {'withinList' | 'aboveList'} [props.selectedItemsPosition='withinList'] - Position of selected items
 *
 * @returns {JSX.Element} The Dropdown component
 */
const Dropdown = ({
  initialValue,
  multipleSelect = false,
  options,
  Header,
  onChange,
  placeholder,
  isContentContained = true,
  icon,
  maximumVisibleOptions = Infinity,
  search = false,
  triggerStyles,
  selectedItemsPosition = 'withinList',
}: DropdownProps): JSX.Element => {
  const [selected, setSelected] = useState(initialValue);
  const [isOpen, setIsOpen] = useState(false);

  // Update the selected value when the initialValue prop changes, causes a rerender
  // This could be achieved with a key prop when using the component, but with this method, we don't have to think about it
  useEffect(() => {
    setSelected(initialValue);
  }, [initialValue]);

  // Ref for the dropdown element
  const dropdownRef = useRef<HTMLDivElement>(null);
  // Should the dropdown open above or below the trigger
  const dropdownPosition = useDropdownPosition(dropdownRef);

  useClickOutside(dropdownRef, () => setIsOpen(false));

  const handleSelect = useCallback(
    (option: Option) => {
      // If multiple select is enabled
      if (multipleSelect) {
        console.log('selected', selected);
        //Check if the option is already selected...
        if (selected.find((el) => el.id === option.id)) {
          //Remove the option from the selected array
          const newSelected = selected.filter((el) => el.id !== option.id);
          console.log('newSelected', newSelected);
          setSelected(newSelected);
          onChange(newSelected);
          return;
        }

        //... if the option is not already selected, add it to the selected array
        setSelected((prev) => [...prev, option]);
        onChange([...selected, option]);
        return;
      }

      // Single option mode, just replace or unselect the selected option, close the dropdown
      if (selected.length && option.id === selected[0].id) {
        setSelected([]);
        onChange([]);
        setIsOpen(false);
        return;
      }
      setSelected([option]);
      onChange([option]);
      setIsOpen(false);
    },
    [selected]
  );

  // Function to reset the selected options, close the dropdown and call the onChange function with an empty array
  const handleReset = () => {
    setSelected([]);
    onChange([]);
    setIsOpen(false);
  };

  const { t } = useTranslation('lib');

  const selectedValue = (() => {
    // No options selected, return placeholder
    if (selected.length === 0) {
      return placeholder;
    }

    // Multiple selection mode with items selected
    if (multipleSelect) {
      return `${placeholder}  (${t('Dropdown.itemsSelected', { count: selected.length })})`;
    }

    // Single selection mode with an item selected
    return selected[0].label;
  })();

  return (
    <motion.div
      /* "relative" makes the dropdown content use this div as its anchor
          if not contained, the dropdown content will be positioned relative to closest parent with relative positioning
     */
      className={`${isContentContained && 'relative'} w-full`}
      /* Ref to check position and outside clicks */
      ref={dropdownRef}>
      <motion.div layout className='flex items-center gap-2'>
        {/* The Dropdown trigger */}
        <DropdownTrigger
          openingDirection={dropdownPosition}
          selectedValue={selectedValue}
          icon={icon}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          className={triggerStyles}
        />
      </motion.div>
      {isOpen && (
        /* The Dropdown Content */
        <DropdownContent
          dropdownPosition={dropdownPosition}
          Header={Header}
          search={search}
          options={options}
          icon={icon}
          selected={selected}
          selectedItemsPosition={selectedItemsPosition}
          onSelect={handleSelect}
          onClose={() => setIsOpen(false)}
          onReset={handleReset}
          maximumVisibleOptions={maximumVisibleOptions}
        />
      )}
    </motion.div>
  );
};

export default memo(Dropdown);
