import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import App from './App.tsx';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import './styles/main.css';

Sentry.init({
  dsn: 'https://58734e64443a604365b8b3dc8fe4ef2a@o4507424831766528.ingest.de.sentry.io/4507458949218384',
  integrations: [],
  enabled: import.meta.env.PROD,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <I18nextProvider i18n={i18n}>
    <App />
  </I18nextProvider>
);
