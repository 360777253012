import { motion } from 'framer-motion';
import { Loader2 } from 'lucide-react';

const ImageLoading = () => {
  return (
    <motion.div
      className='flex h-full w-full items-center justify-center border-text-base/10 border
        overflow-hidden rounded-xl'>
      <Loader2 className='animate-spin text-text-base' size={30} />
    </motion.div>
  );
};

export default ImageLoading;
