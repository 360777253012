import { Editor } from 'tldraw';

//Reference node_modules\tldraw\src\lib\defaultExternalContentHandlers.ts

/**
 * Handle the paste from clipboard to a textCustom object.
 * TODO : Refactor, this is quite ugly method to handle all cases!!!
 */
export const registerCustomExternalContentHandlers = (editor: Editor) => {
  /**
   * Because many cases are handled by the editor, we let the editor handle the paste
   * from the global state.
   */
  editor.registerExternalContentHandler('text', async (content) => {
    // Generate the TextCustom.
    const htmlSource = content.sources?.find(
      (s) => s.type === 'text' && s.subtype === 'html'
    );
    // Use the current cursor position to paste the element.
    const anchorPoint = editor.inputs.currentPagePoint;

    let text;
    if (htmlSource) {
      // Ensure to extract only the text from any html.
      const tempDiv = document.createElement('div');
      tempDiv.innerHTML = htmlSource.data;
      text = tempDiv.textContent || tempDiv.innerText || htmlSource.data;
    } else {
      const textSource = content.sources?.find(
        (s) => s.type === 'text' && s.subtype === 'text'
      );
      if (textSource) {
        text = textSource.data;
      }
    }
    if (text && text.length > 0) {
      // Create the text custom
      editor.createShape({
        type: 'textCustom',
        x: anchorPoint.x + 10,
        y: anchorPoint.y + 10,
        props: { text: text },
      });
    }
  });

  /**
   * Handle the native paste from clipboard not handled.
   *
   * Strangely, text/plain is not handled by the native tldraw paste, but the text/html is handled.
   * So, here, we handle native paste from the browser.
   */
  const handlePaste = (event: ClipboardEvent) => {
    // Custom paste handling logic
    // console.log('Paste event detected:', event);
    const anchorPoint = editor.inputs.currentPagePoint;
    // Allow event to propagate to other handlers
    const clipboardData = event.clipboardData;
    // Retrieve plain text from the clipboard
    const plainText = clipboardData?.getData('text/plain');
    const htmlData = clipboardData?.getData('text/html');
    if (plainText && !htmlData) {
      console.log('plain');
      editor.createShape({
        type: 'textCustom',
        x: anchorPoint.x,
        y: anchorPoint.y,
        props: { text: plainText },
      });
    }
    event.stopPropagation();
  };

  window.addEventListener('paste', handlePaste);
  return () => {
    window.removeEventListener('paste', handlePaste);
  };
};
