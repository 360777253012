import {
  faPalette,
  faImages,
  faRulerVertical,
  faArrowLeft,
  faArrowRight,
  faChalkboard,
  faUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

import Card, { CreatedAtInfo } from '@/components/lib/Containers/Card';
import { CreationImage } from './CreationImage';

import AlbumName from '../Albums/AlbumName';
import { motion } from 'framer-motion';
import { ReactNode, useState } from 'react';

import Button from '@/components/lib/Button';
import useGetCreation from '@/hooks/creations/useGetCreation';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CreationDetailProps = {
  creationId: string;
  onClose: () => void;
};

export const CreationDetail = ({
  creationId,
  onClose,
}: CreationDetailProps) => {
  const { creation } = useGetCreation(creationId);
  if (!creation) return null;
  const { whiteboard, input, inspire, createdAt, graphicStyle } = creation;
  const imagesIds = ['0', '1', '2', '3'];

  console.log(whiteboard);

  return (
    <Card
      hideScrollbar={false}
      className='p-8 overflow-auto block max-w-[1200px] lg:grid lg:grid-cols-[1fr_max-content]
        grid-rows-1 w-[95vw] gap-6 items-center'>
      <Carousel>
        {imagesIds.map((imageId, index) => (
          <CreationImage
            enableCopy
            key={index + creation.id}
            imageId={imageId}
            creationId={creation.id}
            contain={true}
            small={false}
          />
        ))}
      </Carousel>
      <Card.Body>
        <div className='absolute top-4 right-4'>
          <Card.CloseButton onClose={onClose}></Card.CloseButton>
        </div>

        <Card.Section>
          <Card.SectionHeader> Prompt</Card.SectionHeader>
          <Card.Separator></Card.Separator>
          {input}
        </Card.Section>
        <Card.Section>
          <Card.SectionHeader> Détails</Card.SectionHeader>
          <Card.Separator></Card.Separator>
          <CreatedAtInfo date={new Date(createdAt)}></CreatedAtInfo>
          <Card.Info icon={faPalette}>
            <div className='flex justify-between w-full'>
              <span>Style graphique</span>
              <span className='text-text-accent'>{graphicStyle}</span>
            </div>
          </Card.Info>
        </Card.Section>
        {whiteboard && (
          <Card.Section>
            <Card.Info icon={faChalkboard}>
              <Link
                to={'/whiteboards/' + whiteboard.id}
                className='flex justify-between w-full'>
                <span>Tableau</span>
                <span className='flex items-center text-text-accent gap-2'>
                  <span className='overflow-hidden text-ellipsis'>
                    {whiteboard.name}
                  </span>
                  <FontAwesomeIcon
                    className='text-xs'
                    icon={faUpRightFromSquare}
                  />
                </span>
              </Link>
            </Card.Info>
          </Card.Section>
        )}

        {inspire && (
          <Card.Section>
            <Card.Info icon={faImages}>
              <div className='flex justify-between w-full'>
                <span>Album</span>
                <span className='text-text-accent'>
                  <AlbumName albumId={inspire.albumId}></AlbumName>
                </span>
              </div>
            </Card.Info>
            <Card.Info icon={faRulerVertical}>
              <div className='flex justify-between w-full'>
                <span>Degrès d'inspiration</span>
                <span className='text-text-accent'>{inspire.weight}</span>
              </div>
            </Card.Info>
          </Card.Section>
        )}
      </Card.Body>
    </Card>
  );
};

// Move Carousel component to its own file if needed
export const Carousel = ({ children }: { children: ReactNode[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? children.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === children.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className='relative w-full overflow-hidden'>
      <div className='absolute inset-0 flex items-center justify-between p-4'>
        <Button
          round
          className='z-1000'
          variant={'secondary'}
          onClick={handlePrev}
          leftIcon={faArrowLeft}
        />
        <Button
          round
          className='z-1000'
          variant={'secondary'}
          onClick={handleNext}
          leftIcon={faArrowRight}
        />
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className='h-[300px] md:h-[80vh] w-full overflow-hidden max-h-[1000px]'
        key={currentIndex}>
        {children[currentIndex]}
      </motion.div>
    </div>
  );
};
