import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';

type DropdownSearchProps = {
  searchValue: string;
  setSearchValue: (value: string) => void;
};

const DropdownSearch = ({
  searchValue,
  setSearchValue,
}: DropdownSearchProps) => {
  const { t } = useTranslation(['lib']);
  return (
    <motion.div layout className='relative flex items-center gap-2 px-4'>
      <motion.div layout>
        <FontAwesomeIcon icon={faSearch} />
      </motion.div>
      <motion.input
        layout
        type='text'
        placeholder={t('lib:Dropdown.Search.Placeholder')}
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
        className='bg-surface-primary w-full rounded-xl'
        onClick={(e) => e.stopPropagation()}
      />
    </motion.div>
  );
};

export default DropdownSearch;
