import {
  faBookOpen,
  faFolder,
  faLink,
  faPen,
  faTimesCircle,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@/components/lib/Button';
import { useTranslation } from 'react-i18next';
import Card, {
  NumberOfGenerationsTag,
  ProjectTag,
} from '@/components/lib/Containers/Card';
import { Album } from '@/types/api';
import useGetAlbum from '@/hooks/albums/useGetAlbum';
import useArchiveAlbum from '@/hooks/albums/useArchiveAlbum';
import useRestoreAlbum from '@/hooks/albums/useRestoreAlbum';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import RenamableAlbum from '../../Common/EditableItems/RenamableAlbum';
import AlbumImage from '../../Creator/Panel/Album/AlbumImage';

import { useNavigate, useParams } from 'react-router-dom';
import useRemoveAlbumFromProjects from '@/hooks/albums/useRemoveAlbumFromProjects';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { useState } from 'react';
import { ModifyAlbumForm } from '@/components/Common/ItemsForms/ModifyAlbumForm';
import AddAlbumToProjectsForm from '@/components/Common/ItemsForms/AddAlbumToProjectsForm';

interface AlbumActions {
  onArchive: () => void;
  onRestore: () => void;
  onCopyLink: () => void;
  onNavigateToDetails: () => void;
  onModify: () => void;
}

interface AlbumViewProps {
  album: Album;
  actions: AlbumActions;
  presentation: 'grid' | 'list';
}

export const AlbumItem = ({
  albumId,
  presentation = 'grid',
}: {
  albumId: string;
  presentation?: 'grid' | 'list';
}) => {
  const { album } = useGetAlbum(albumId);
  const { archiveAlbum } = useArchiveAlbum();
  const { restoreAlbum } = useRestoreAlbum();

  const [modifyAlbum, setModifyAlbum] = useState<boolean>(false);
  const navigate = useNavigate();

  if (!album) return null;

  const actions: AlbumActions = {
    onArchive: () => archiveAlbum(album, albumId),
    onRestore: () => restoreAlbum(album, albumId),
    onCopyLink: async () => {
      const url = `${window.location.origin}/albums/${albumId}`;
      await copyToClipboard(url);
    },
    onNavigateToDetails: () => navigate(`/albums/${albumId}`),
    onModify: () => setModifyAlbum(true),
  };

  return (
    <>
      <BlurModal
        isOpen={!!modifyAlbum}
        setIsOpen={() => {
          setModifyAlbum(false);
        }}>
        <ModifyAlbumForm
          albumId={albumId}
          onClose={() => {
            setModifyAlbum(false);
          }}
          integrated={false}></ModifyAlbumForm>
      </BlurModal>

      {presentation === 'list' ? (
        <AlbumListView
          album={album}
          actions={actions}
          presentation={presentation}
        />
      ) : (
        <AlbumCardView
          album={album}
          actions={actions}
          presentation={presentation}
        />
      )}
    </>
  );
};

const AlbumListView = ({ album, actions }: AlbumViewProps) => {
  const { t } = useTranslation('dashboard');
  const { images, createdAt, status, projects } = album;

  return (
    <div
      className='col-span-3 grid grid-cols-subgrid items-center gap-6 rounded-xl
        bg-surface-primary p-4'>
      <div className='flex items-center gap-3'>
        <FontAwesomeIcon icon={faBookOpen} className='text-accent text-xl' />
        <div className='flex flex-col'>
          <RenamableAlbum albumId={album.id} />
          <time className='text-text-secondary text-xs'>
            {new Date(createdAt).toLocaleDateString()}
          </time>
        </div>
      </div>

      <div className='flex items-center gap-4'>
        <div className='flex gap-2'>
          <NumberOfGenerationsTag number={images.length} />
          {projects?.map((project) => (
            <ProjectTag key={project.id} projectId={project.id} />
          ))}
        </div>
      </div>

      <div className='flex justify-end gap-2'>
        <Button
          variant='danger'
          size='small'
          leftIcon={faTrash}
          onClick={status === 'VISIBLE' ? actions.onArchive : actions.onRestore}
          aria-label={
            status === 'VISIBLE' ? t('common.archive') : t('common.restore')
          }>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
        <Button
          variant='primary'
          size='small'
          leftIcon={faLink}
          onClick={actions.onCopyLink}
          aria-label={t('common.copyLink')}
        />
        <Button
          variant='primary'
          size='small'
          leftIcon={faPen}
          onClick={actions.onModify}
          aria-label={t('common.modify')}
        />
        <Button
          variant='primary'
          size='small'
          onClick={actions.onNavigateToDetails}
          aria-label={t('common.details')}>
          {t('common.details')}
        </Button>
      </div>
    </div>
  );
};

const AlbumCardView = ({ album, actions }: AlbumViewProps) => {
  const { t } = useTranslation('dashboard');
  const { removeAlbumFromProjects } = useRemoveAlbumFromProjects();
  const { projectId } = useParams();
  const { images, createdAt, status, projects } = album;
  const moreThanFourImages = images.length > 4;

  const [isAddToProjectsOpen, setIsAddToProjectsOpen] = useState(false);

  return (
    <Card>
      <BlurModal
        isOpen={isAddToProjectsOpen}
        setIsOpen={setIsAddToProjectsOpen}>
        <AddAlbumToProjectsForm
          albumId={album.id}
          onClose={() => setIsAddToProjectsOpen(false)}
        />
      </BlurModal>

      {/* If we're in a project page, we can remove this album for the project */}
      {projectId && (
        <button
          className='absolute right-2 top-1 z-50 cursor-pointer'
          onClick={() => removeAlbumFromProjects(album.id, [projectId])}
          aria-label="Enlever l'album du projet">
          <FontAwesomeIcon icon={faTimesCircle} />
        </button>
      )}
      <Card.HeaderWithLink
        icon={faBookOpen}
        title={<RenamableAlbum albumId={album.id} />}
        linkTo={`/albums/${album.id}`}
      />
      <Card.TimeAgo date={new Date(createdAt)} />

      <Card.Body>
        <div className='grid grid-cols-2 gap-2'>
          {images.slice(0, moreThanFourImages ? 3 : 4).map((image) => (
            <div key={image.id}>
              <AlbumImage albumId={album.id} imageId={image.id} />
            </div>
          ))}
          {moreThanFourImages && (
            <div className='relative overflow-hidden rounded-2xl'>
              <AlbumImage albumId={album.id} imageId={images[3].id} />
              <div
                className='absolute left-0 top-0 flex h-full w-full items-center justify-center
                  bg-slate-800 bg-opacity-70'>
                <div className='text-base font-bold'>+{images.length - 3}</div>
              </div>
            </div>
          )}
        </div>
      </Card.Body>

      <Card.Footer>
        <Card.Tags>
          <NumberOfGenerationsTag number={images.length} />
          <Card.Tag icon={faFolder}>
            <p
              className='cursor-pointer'
              onClick={() => setIsAddToProjectsOpen(true)}>
              {t('common.inNProjects', { count: projects?.length })}
            </p>
          </Card.Tag>
        </Card.Tags>
        <Card.DotMenu>
          <Button
            className='w-full'
            variant='danger'
            leftIcon={faTrash}
            onClick={
              status === 'VISIBLE' ? actions.onArchive : actions.onRestore
            }
            aria-label={
              status === 'VISIBLE' ? t('common.archive') : t('common.restore')
            }>
            {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
          </Button>
          <Button
            className='w-full'
            variant='primary'
            leftIcon={faLink}
            onClick={actions.onCopyLink}
            aria-label={t('common.copyLink')}>
            {t('common.copyLink')}
          </Button>
          <Button
            className='w-full'
            variant='primary'
            onClick={actions.onModify}
            aria-label={t('common.modify')}>
            {t('common.modify')}
          </Button>
          <Button
            className='w-full'
            variant='primary'
            onClick={actions.onNavigateToDetails}
            aria-label={t('common.details')}>
            {t('common.details')}
          </Button>
        </Card.DotMenu>
      </Card.Footer>
    </Card>
  );
};
