import { useNavigate, useParams } from 'react-router-dom';
import TldrawWhiteboard from '../components/Creator/TldrawWhiteboard';
import { AlbumProvider } from '../components/Creator/Panel/context/AlbumContext';
import { PanelProvider } from '../components/Creator/Panel/context/PanelContext';
import { PanelInputProvider } from '../components/Creator/Panel/context/PanelInputContext';
import Panel from '../components/Creator/Panel/Panel';
import {
  AlreadyExistsError,
  useWhiteboardAPI,
} from '../hooks/whiteboard/useWhiteboardAPI';
import { useEffect, useState } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { Helmet } from 'react-helmet';
import usePreventSwipeBack from "@/components/TlDraw/hooks/usePreventSwipeBack.ts";

const Creator = () => {
  // Used to prevent swipe back two finger on mac trackpad.
  usePreventSwipeBack(true);
  const [whiteboardId, setWhiteboardId] = useState<string | null>(null);
  const navigate = useNavigate();
  const { createWhiteboard, getLastUpdatedWhiteboard } = useWhiteboardAPI();
  const params = useParams();
  const [loading, setLoading] = useState<boolean>(true);

  const { keycloak } = useKeycloak();

  // Pre-establish the websocket connection with the collab server to prevent
  // the infinite loading bug of TLDraw whiteboard
  useEffect(() => {
    if (!keycloak || !whiteboardId) return;

    // Initialize WebSocket
    const socket = new WebSocket(
      `${import.meta.env.VITE_COLLAB_SERVER_URL}/connect/${whiteboardId}?token=${keycloak.token}`
    );

    // Open connection
    socket.onopen = () => {
      console.debug('WebSocket connection established');
      setLoading(false);
    };

    // Handle errors
    socket.onerror = (error) => {
      console.log('webSocket error:', error);
      navigate('/404');
    };

    // Cleanup on unmount
    return () => {
      socket.close();
    };
  }, [keycloak, whiteboardId]);

  // Get whiteboard ID from URL path param and set it as last whiteboard ID in local storage
  const paramWhiteboardId = params['whiteboardId'];

  useEffect(() => {
    if (paramWhiteboardId) {
      console.log('Found whiteboard ID in path param:', paramWhiteboardId);
      setWhiteboardId(paramWhiteboardId);
      return;
    }

    // Try to get the last used whiteboard from backend
    getLastUpdatedWhiteboard()
      .then((whiteboard) => {
        if (whiteboard) {
          console.log(`found last updated whiteboard`, whiteboard);
          setWhiteboardId(whiteboard.id);
          navigate(`/creator/${whiteboard.id}`);
          return;
        }

        // No whiteboard found, create one
        createWhiteboard(
          'whiteboard ' +
            new Date().toLocaleDateString() +
            ' - ' +
            new Date().toLocaleTimeString()
        )
          .then((whiteboard) => {
            console.debug('created whiteboard:', whiteboard);
            setWhiteboardId(whiteboard.id);

            // Redirect to the new whiteboard
            navigate(`/creator/${whiteboard.id}`);
          })
          .catch((err) => {
            // Ignore already exists error
            if (err instanceof AlreadyExistsError) {
              console.error(`failed to create default whiteboard: ${err}`);
            } else {
              console.debug('whiteboard already exists');
            }
          });
      })
      .catch((err) => {
        console.error(`failed to fetch last updated whiteboard: ${err}`);
      });

    return () => {
      setWhiteboardId(null);
    };
  }, []);

  if (!whiteboardId) return null;
  if (loading) return <div>Loading...</div>;

  return (
    <AlbumProvider>
      <PanelProvider>
        <PanelInputProvider>
          <TldrawWhiteboard whiteboardId={whiteboardId}>
            <Helmet>
              <title>Creator - Beink</title>
            </Helmet>
            <Panel />
          </TldrawWhiteboard>
        </PanelInputProvider>
      </PanelProvider>
    </AlbumProvider>
  );
};

export default Creator;
