import { Loader2 } from 'lucide-react';
import useGetCreationImage from '../../../hooks/creations/useGetCreationImage';
import Button from '@/components/lib/Button';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

type CreationImageProps = {
  imageId: string;
  creationId: string;
  small?: boolean;
  enableCopy?: boolean;
  contain?: boolean;
};

export const CreationImage = ({
  imageId,
  creationId,
  small,
  contain = false,
  enableCopy = false,
}: CreationImageProps) => {
  const { image, isLoading } = useGetCreationImage(creationId, imageId, small);

  async function copyImageToClipboard(dataUrl: string) {
    try {
      // Convert data URL to Blob
      const response = await fetch(dataUrl);
      const blob = await response.blob();

      // Create a new ClipboardItem with the image blob
      const clipboardItem = new ClipboardItem({ 'image/png': blob });

      // Write the ClipboardItem to the clipboard
      await navigator.clipboard.write([clipboardItem]);

      console.log('Image copied to clipboard successfully!');
    } catch (error) {
      console.error('Failed to copy image to clipboard:', error);
    }
  }

  return (
    <div
      className='relative flex w-full items-center h-full justify-center overflow-hidden
        rounded-xl'>
      {enableCopy && image && (
        <Button
          className='absolute top-2 right-2'
          round
          variant={'accent'}
          size={'small'}
          leftIcon={faCopy}
          onClick={() => copyImageToClipboard(image)}>
          Copy image
        </Button>
      )}
      {isLoading ? (
        <Loader2 className='text-primary mx-auto animate-spin' size={40} />
      ) : (
        <img
          src={image}
          className={`h-full w-full ${contain ? 'object-contain' : 'object-cover '} `}
          alt='Creation image'
        />
      )}
    </div>
  );
};
