import {
  faChalkboard,
  faLink,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { Link, useNavigate, useParams } from 'react-router-dom';
import Button from '../../lib/Button';

import { useGetWhiteboard } from '../../../hooks/whiteboard/useGetWhiteboard';
import useArchiveWhiteboard from '../../../hooks/whiteboard/useArchiveWhiteboard';
import useRestoreWhiteboard from '../../../hooks/whiteboard/useRestoreWhiteboard';
import RenamableWhiteboard from '../../Common/EditableItems/RenamableWhiteboard';
import { WhiteboardImage } from './WhiteboardImage';

import { useTranslation } from 'react-i18next';
import Card, {
  NumberOfGenerationsTag,
  ProjectTag,
} from '@/components/lib/Containers/Card';
import { copyToClipboard } from '@/helpers/copyToClipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Whiteboard } from '@/types/api';
import useGetCreations from '@/hooks/creations/useGetCreations';

type WhiteboardCardProps = {
  whiteboardId: string;
  width?: string;
  presentation?: 'grid' | 'list';
};

interface WhiteboardActions {
  onArchive: () => void;
  onRestore: () => void;
  onCopyLink: () => void;
  onNavigateToDetails: () => void;
  onNavigateToCreator: () => void;
}

interface WhiteboardViewProps {
  whiteboard: Whiteboard;
  actions: WhiteboardActions;
  width?: string;
}

export const WhiteboardItem = ({
  whiteboardId,
  width,
  presentation = 'grid',
}: WhiteboardCardProps) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);

  const { archiveWhiteboard } = useArchiveWhiteboard();
  const { restoreWhiteboard } = useRestoreWhiteboard();
  const { projectId: projectIdParam } = useParams();
  const navigate = useNavigate();

  if (!whiteboard) return null;

  const actions: WhiteboardActions = {
    onArchive: () => archiveWhiteboard(whiteboardId),
    onRestore: () => restoreWhiteboard(whiteboardId),
    onCopyLink: async () => {
      const url = `${window.location.origin}/creator/${whiteboardId}`;
      await copyToClipboard(url);
    },
    onNavigateToDetails: () => {
      if (projectIdParam)
        navigate(
          '/projects/' + projectIdParam + '/whiteboard/' + whiteboard.id
        );
      else navigate('/whiteboards/' + whiteboard.id);
    },
    onNavigateToCreator: () => navigate('/creator/' + whiteboardId),
  };

  return presentation === 'list' ? (
    <WhiteboardListView
      whiteboard={whiteboard}
      actions={actions}
      width={width}
    />
  ) : (
    <WhiteboardCardView
      whiteboard={whiteboard}
      actions={actions}
      width={width}
    />
  );
};

const WhiteboardListView = ({ whiteboard, actions }: WhiteboardViewProps) => {
  const { t } = useTranslation('dashboard');
  const { status } = whiteboard;
  const { creations } = useGetCreations(whiteboard.id, 200);

  return (
    <div
      className='bg-surface-primary col-span-3 grid grid-cols-subgrid items-center gap-6
        rounded-xl p-4'>
      <div className='flex items-center gap-3'>
        <FontAwesomeIcon icon={faChalkboard} className='text-accent text-xl' />
        <div className='flex flex-col'>
          <RenamableWhiteboard whiteboardId={whiteboard.id} />
          <time className='text-text-secondary text-xs'>
            {new Date(whiteboard.updatedAt).toLocaleDateString()}
          </time>
        </div>
      </div>

      <div className='flex items-center gap-4'>
        <div className='flex gap-2'>
          <ProjectTag projectId={whiteboard.projectId} />
          <NumberOfGenerationsTag number={creations.length} />
        </div>
      </div>

      <div className='flex justify-end gap-2'>
        <Button
          variant='danger'
          size='small'
          leftIcon={faTrash}
          onClick={
            status === 'VISIBLE' ? actions.onArchive : actions.onRestore
          }>
          {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
        </Button>
        <Button
          variant='primary'
          size='small'
          leftIcon={faLink}
          onClick={actions.onCopyLink}
        />
        <Button
          variant='primary'
          size='small'
          onClick={actions.onNavigateToDetails}>
          {t('common.details')}
        </Button>
      </div>
    </div>
  );
};

const WhiteboardCardView = ({ whiteboard, actions }: WhiteboardViewProps) => {
  const { t } = useTranslation('dashboard');
  const { status } = whiteboard;

  const { creations } = useGetCreations(whiteboard.id, 200);

  return (
    <Card>
      <Card.HeaderWithLink
        icon={faChalkboard}
        title={<RenamableWhiteboard whiteboardId={whiteboard.id} />}
        linkTo={`/whiteboards/${whiteboard.id}`}
      />
      <Card.TimeAgo date={new Date(whiteboard.updatedAt)} />

      <Card.Body>
        <button
          className='bg-surface-secondary aspect-16/11 w-full cursor-pointer overflow-hidden
            rounded-xl'
          aria-label='Navigate to this whiteboard in the creator'
          onClick={actions.onNavigateToCreator}>
          <WhiteboardImage whiteboardId={whiteboard.id} />
        </button>
      </Card.Body>

      <Card.Footer>
        <Card.Tags>
          {whiteboard.projectId && (
            <ProjectTag projectId={whiteboard.projectId} />
          )}
          <Link to={`/whiteboards/${whiteboard.id}`} className='w-full'>
            <NumberOfGenerationsTag number={creations.length} />
          </Link>
        </Card.Tags>

        <Card.DotMenu>
          <Button
            className='w-full'
            variant='danger'
            leftIcon={faTrash}
            onClick={
              status === 'VISIBLE' ? actions.onArchive : actions.onRestore
            }>
            {status === 'VISIBLE' ? t('common.archive') : t('common.restore')}
          </Button>
          <Button
            className='w-full'
            variant='primary'
            leftIcon={faLink}
            onClick={actions.onCopyLink}>
            {t('common.copyLink')}
          </Button>
          <Button
            className='w-full'
            variant='primary'
            onClick={actions.onNavigateToDetails}>
            {t('common.details')}
          </Button>
        </Card.DotMenu>
      </Card.Footer>
    </Card>
  );
};
