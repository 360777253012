import Dropdown, { type Option } from '../../../../lib/Dropdown/Dropdown';
import { useTranslation } from 'react-i18next';
import { DesignStyle } from '../../../../../types';
import { usePanelInputContext } from '../../context/usePanelInputContext';
import { faPalette } from '@fortawesome/free-solid-svg-icons';

import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

/**
 *
 * @returns
 */
const GraphicStyleDropdown = () => {
  const { t } = useTranslation(['panel']);
  const { whiteboardId } = useParams();
  const { setGraphicStyle, graphicStyle } = usePanelInputContext();

  const SUGGESTIONS: Record<DesignStyle, string> = {
    [DesignStyle.Industrial]: t('panel:Imagine.DesignStyle.Industrial'),
    [DesignStyle.Line]: t('panel:Imagine.DesignStyle.Line'),
    [DesignStyle.Davinci]: t('panel:Imagine.DesignStyle.Davinci'),
    [DesignStyle.HandSketch]: t('panel:Imagine.DesignStyle.HandSketch'),
    [DesignStyle.FashionDrawing]: t('panel:Imagine.DesignStyle.FashionDrawing'),
    [DesignStyle.Scientific]: t('panel:Imagine.DesignStyle.Scientific'),
    [DesignStyle.Photorealistic]: t('panel:Imagine.DesignStyle.Photorealistic'),
  };

  const initialStyle: Option[] = useMemo(() => {
    const selectedStyleFromStorage = localStorage.getItem(
      `imagineSelectedStyle-${whiteboardId}`
    );
    if (selectedStyleFromStorage) {
      const style = selectedStyleFromStorage as DesignStyle;
      setGraphicStyle(style);
      return [
        {
          id: selectedStyleFromStorage,
          label: t(`panel:Imagine.DesignStyle.${style}`),
        },
      ];
    }
    return [];
  }, [whiteboardId, setGraphicStyle, t]);

  const styleOptions: Option[] = useMemo(() => {
    return Object.entries(SUGGESTIONS).map(([key, value]) => ({
      id: key,
      label: value,
    }));
  }, []);

  const handleOnChange = (value: DesignStyle | null) => {
    setGraphicStyle(value);
    localStorage.setItem(`imagineSelectedStyle-${whiteboardId}`, value || '');
  };

  return (
    <div className='flex w-full flex-col gap-2'>
      <Dropdown
        initialValue={initialStyle}
        Header={() => (
          <div className='p-2 px-4 font-semibold'>
            {t('panel:Generate.graphicStyleLabel')}
          </div>
        )}
        triggerStyles={
          graphicStyle
            ? 'bg-surface-primary/50 text-text-base'
            : 'border-text-neutral border'
        }
        options={styleOptions}
        icon={faPalette}
        isContentContained={false}
        placeholder={t('panel:Generate.graphicStyleLabel')}
        showSelectedWithinList={true}
        onChange={(option) => {
          console.log(option);
          handleOnChange(option.length ? (option[0].id as DesignStyle) : null);
        }}
        selectedItemsPosition={'aboveList'}
      />
    </div>
  );
};

export default GraphicStyleDropdown;
