import { useState, useCallback, useMemo } from 'react';
import { faBookOpen, faTimes } from '@fortawesome/free-solid-svg-icons';
import { type Option } from '@/components/lib/Dropdown/Dropdown';

import Button from '@/components/lib/Button';
import Card from '@/components/lib/Containers/Card';
import BlurModal from '@/components/lib/Containers/BlurModal';

import useAddAlbumToProjects from '@/hooks/albums/useAddAlbumToProjects';
import { AddAlbumModalProps } from '../../Dashboard/Projects/ProjectPage/types/album-modal.types';
import { NewAlbumForm } from './NewAlbumForm';
import AlbumDropdown from '@/components/Common/Dropdowns/AlbumDropdown';

export const AddAlbumModal = ({
  isOpen,
  onClose,
  projectId,
  existingAlbums,
}: AddAlbumModalProps) => {
  const { addAlbumToProjects } = useAddAlbumToProjects();
  const [selected, setSelected] = useState<string[]>([]);

  const onChange = useCallback(
    (option: Option[]) => {
      setSelected(option.map((el) => el.id));
    },
    [setSelected]
  );

  const dropdownInitialValue = useMemo(() => [], []);

  return (
    <BlurModal isOpen={isOpen} setIsOpen={onClose}>
      <Card className='gap-6 max-w-[95vw] w-150'>
        <Card.Header icon={faBookOpen}>
          <div className='flex items-center justify-between gap-10'>
            Ajouter un album
            <Button leftIcon={faTimes} round onClick={onClose} />
          </div>
        </Card.Header>
        <Card.Section>
          <Card.SubSectionHeader icon={faBookOpen}>
            Ajouter un ou des albums existants
          </Card.SubSectionHeader>

          <AlbumDropdown
            albumsToExclude={existingAlbums?.map((el) => el.id)}
            onChange={onChange}
            multipleSelect
            initialValue={dropdownInitialValue}
            selectedItemsPosition={'aboveList'}
          />

          <Button
            variant='accent'
            onClick={() => {
              for (const albumId of selected) {
                addAlbumToProjects(albumId, [projectId]);
              }
              onClose();
            }}
            state={selected.length === 0 ? 'disabled' : 'default'}>
            Ajouter les albums sélectionnés
          </Button>
        </Card.Section>
        <Card.Section>
          <Card.SectionHeader>Créer un nouvel album</Card.SectionHeader>
          <Card.Separator />
          <NewAlbumForm
            integrated={true}
            onClose={onClose}
            projectId={projectId}
          />
        </Card.Section>
      </Card>
    </BlurModal>
  );
};
