import {
  motion,
  MotionValue,
  useMotionValue,
  useMotionValueEvent,
  useTransform,
} from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { debounce } from 'lodash';

import { useParams } from 'react-router-dom';
import useLocalStorage from 'use-local-storage';

/**
 * Props for the Slider component
 * @typedef {Object} SliderProps
 * @property {(value: number) => void} [onChange] - Callback function triggered when the slider value changes
 * @property {string[]} labels - Array of labels to display below the slider
 * @property {number} step - Step size for value increments
 * @property {number[]} range - Array defining the min and max values [min, max]
 * @property {number} defaultValue - Default value for the slider (normalized between 0 and 1)
 * @property {string} id - Unique identifier for the slider, used to save the value in local storage per whiteboard
 */
type SliderProps = {
  onChange?: (value: number) => void;
  labels: string[];
  step: number;
  range: number[];
  defaultValue: number;
  id: string;
};

/**
 * Label component for the slider
 * @param {Object} props - Component props
 * @param {string} props.label - The text to display
 * @param {number} props.position - The normalized position (0-1) of this label
 * @param {MotionValue<number>} props.percentage - The current slider percentage as a motion value
 */
const SliderLabel = ({
  label,
  position,
  percentage,
}: {
  label: string;
  position: number;
  percentage: MotionValue<number>;
}) => {
  const opacity = useTransform(
    percentage,
    [position - 0.3, position, position + 0.3],
    [0.5, 1, 0.5]
  );

  return (
    <motion.div className='text-xs' style={{ opacity }}>
      {label}
    </motion.div>
  );
};

const thumbWidth = 16;

/**
 * Interactive slider component with labels, numeric input, and local storage persistence.
 * Values are saved per whiteboard using the provided ID in local storage.
 *
 * The slider features:
 * - Draggable thumb
 * - Click-to-position on track
 * - Keyboard navigation (left/right arrows)
 * - Dynamic labels that highlight based on current value
 * - Direct numeric input
 * - Persistent values per whiteboard ID
 *
 * @param {SliderProps} props - Component props
 * @returns {JSX.Element} The slider component
 */
const Slider = ({ onChange, labels, range, id, defaultValue }: SliderProps) => {
  const { whiteboardId } = useParams<{ whiteboardId: string }>();

  //Store the value in local storage, it's normalised to a range of 0-1
  const [storedValue, setStoredValue] = useLocalStorage<number>(
    `slider-${id}-whiteboard-${whiteboardId} `,
    defaultValue
  );

  //The width of the slider
  const [sliderWidth, setSliderWidth] = useState(0);

  // Update slider width when component mounts or window resizes
  useEffect(() => {
    //When the component mounts, update the width and directly set the value
    const updateWidth = () => {
      if (sliderRef.current) {
        console.log(sliderRef.current.clientWidth * storedValue);
        setSliderWidth(sliderRef.current.clientWidth - thumbWidth);
        x.set(storedValue * (sliderRef.current.clientWidth - thumbWidth));
      }
    };

    // Initial measurement
    updateWidth();

    // Add resize listener
    window.addEventListener('resize', updateWidth);

    // Clean up
    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  const sliderRef = useRef<HTMLDivElement>(null);

  const x = useMotionValue(storedValue * sliderWidth); //Thumb coordinates in pixel space
  const xNormalized = useTransform(() => x.get() / sliderWidth); //Thumb coordinates in 0-1 space
  const valueInRange = useTransform(xNormalized, [0, 1], range); //Value in the specified range

  // Debounce the change event to prevent too many updates
  const debouncedOnChange = debounce((latestValue: number) => {
    //Don't update if the component is not yet mounted
    if (!sliderRef.current) return;

    console.log('debounced', latestValue);

    //Update the stored value and call the onChange callback
    setStoredValue(latestValue);
    onChange?.(latestValue);
  }, 300);

  // Update the value when the thumb moves
  useMotionValueEvent(valueInRange, 'change', debouncedOnChange);

  /* Clicking directly on the track */
  const handleTrackClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (sliderRef.current) {
      const rect = sliderRef.current.getBoundingClientRect();
      const clickX = event.clientX - rect.left;
      x.set(clickX - thumbWidth / 2);
    }
  };

  /* Keyboard navigation */
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'ArrowLeft') {
      x.set(Math.max(x.get() - 10, 0));
    } else if (event.key === 'ArrowRight') {
      x.set(Math.min(x.get() + 10, sliderWidth));
    }
  };

  return (
    <div className='flex gap-3 items-center'>
      <motion.div
        layout
        ref={sliderRef}
        tabIndex={0}
        onKeyDown={handleKeyDown}
        className='focus:outline-hidden basis-full'>
        <motion.div
          layout
          className='relative flex h-4 w-full content-center items-center overflow-visible'
          onClick={handleTrackClick}>
          {/* THUMB */}
          <motion.button
            drag='x'
            dragElastic={0}
            dragMomentum={false}
            dragConstraints={sliderRef}
            whileDrag={{ scale: 1.2 }}
            className='absolute z-500 rounded-full bg-surface-tertiary'
            style={{
              width: thumbWidth,
              height: thumbWidth,
              transform: 'translateY(-100%)',
              x: x,
            }}></motion.button>
          {/* TRACK */}
          <motion.div
            layout
            className='h-1 w-full cursor-pointer overflow-hidden rounded-xs bg-surface-primary
              focus:scale-105'>
            {/* TRACK FILLED */}
            <motion.div
              className='h-2 w-full bg-surface-tertiary brightness-125'
              style={{
                scaleX: xNormalized,
                originX: 0,
              }}></motion.div>
          </motion.div>
        </motion.div>
        {/* LABELS */}
        <motion.div layout className='flex w-full justify-between'>
          {labels.map((label, i) => (
            <SliderLabel
              key={i}
              label={label}
              position={i / (labels.length - 1)}
              percentage={xNormalized}
            />
          ))}
        </motion.div>
      </motion.div>
      <input
        className='w-18 border border-text-accent/20 p-2 rounded-md bg-surface-primary/60
          -translate-y-2'
        type='number'
        step={0.05}
        onChange={(e) => {
          console.log(e.target.value);
          if (e.target.value === '') return;
          const value = parseFloat(e.target.value);

          // Check if the value is within the specified range
          if (value >= range[0] && value <= range[1]) {
            x.set(((value - range[0]) / (range[1] - range[0])) * sliderWidth);
          }

          // Update stored value
          setStoredValue(value);
          onChange?.(value);
        }}
        value={Math.round(valueInRange.get() * 100) / 100}></input>
    </div>
  );
};

export default Slider;
