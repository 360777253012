import { Loader2 } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';
import useGetCreations from '../../../hooks/creations/useGetCreations';

import { CreationCard } from './CreationCard';
import { useTranslation } from 'react-i18next';
import Collection from '@/components/lib/Containers/Collection';

const Creations = () => {
  const { ref, inView } = useInView();
  const { t } = useTranslation('dashboard');

  const {
    creations,
    isLoading,
    isError,

    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useGetCreations();

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [inView, fetchNextPage, hasNextPage]);

  if (isLoading) return <div>{t('state.loading')}.</div>;
  if (isError) return <div>{t('state.error')}</div>;
  if (!creations?.length) return <div>{t('homepage.noCreations')}</div>;

  return (
    <div className='flex h-full flex-col gap-3 overflow-hidden'>
      <Collection>
        {creations.map((creation) => (
          <CreationCard key={creation.id} creationId={creation.id} />
        ))}
      </Collection>
      <div ref={ref} className='flex justify-center'>
        {isFetchingNextPage && <Loader2 className='animate-spin' />}
      </div>
    </div>
  );
};

export default Creations;
