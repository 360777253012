import { useAxios } from '../../helpers/ProtectedLayout';
import { Whiteboard, WhiteboardShort, WhiteboardData } from '../../types/api';
import { AxiosResponse, AxiosError } from 'axios';

export type WhiteboardImage = {
  id: string;
  src: string;
};

export class AlreadyExistsError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AlreadyExistsError';
  }
}

/**
 * Whiteboard API instance to communicate with API backend.
 * @returns 
 */
export const useWhiteboardAPI = () => {
  // Retrieve the configured axios instance for the API.
  const axios = useAxios().apiInstance;

  /**
   * Get whiteboard API.
   * @param whiteboardId 
   * @returns whiteboard with Project and Generations.
   */
  const getWhiteboard = async (whiteboardId: string): Promise<Whiteboard> => {
    try {
      const response: AxiosResponse<Whiteboard> = await axios.get<Whiteboard>(
        `/whiteboard/${whiteboardId}`
      );

      return response.data;
    } catch (error) {
      // TODO : can handle errors more wisely.
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch whiteboard: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };


  /**
   * 
   * @param status 
   * @param noProject 
   * @returns 
   */
  const getWhiteboards = async (
    status?: 'archived' | 'visible',
    noProject?: boolean
  ): Promise<WhiteboardShort[]> => {
    //create url params
    const params = new URLSearchParams();
    if (status) params.append('status', status);
    if (noProject) params.append('projectId', 'none');

    try {
      const response: AxiosResponse<WhiteboardShort[]> = await axios.get<
        WhiteboardShort[]
      >('/whiteboard', { params });

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch whiteboards: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const createWhiteboard = async (
    name: string,
    projectId?: string,
    description?: string
  ): Promise<Whiteboard> => {
    try {
      const response = await axios.post<Whiteboard>('/whiteboard', {
        projectId,
        name,
        description: description || '',
      });

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.code === '400' &&
          error.response?.data?.error.includes('already exists')
        ) {
          throw new AlreadyExistsError(
            'A whiteboard with this name already exists'
          );
        }

        throw new Error(
          `failed to create whiteboard: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const updateWhiteboard = async (
    whiteboardId: string,
    whiteboard: Partial<Omit<Whiteboard, 'id'>>
  ): Promise<void> => {
    try {
      await axios.put(`/whiteboard/${whiteboardId}`, whiteboard);
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to update whiteboard: ${error.message}`);
      }

      throw error;
    }
  };

  const addWhiteboardToProject = async (
    whiteboardId: string,
    projectId: string
  ): Promise<void> => {
    try {
      await axios.put(`/whiteboard/${whiteboardId}`, {
        projectId,
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to add whiteboard to project: ${error.message}`
        );
      }

      throw error;
    }
  };

  const archiveWhiteboard = async (whiteboardId: string): Promise<void> => {
    try {
      await axios.put(`/whiteboard/${whiteboardId}`, {
        status: 'ARCHIVED',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to archive whiteboard: ${error.message}`);
      }

      throw error;
    }
  };

  const restoreWhiteboard = async (whiteboardId: string): Promise<void> => {
    try {
      await axios.put(`/whiteboard/${whiteboardId}`, {
        status: 'VISIBLE',
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`failed to restore whiteboard: ${error.message}`);
      }

      throw error;
    }
  };

  const addWhiteboardImage = async (
    whiteboardId: string,
    imageId: string,
    file: File
  ): Promise<void> => {
    try {
      const imageUploadUrl = new URL(
        `/whiteboard/${whiteboardId}/image/${imageId}`,
        import.meta.env.VITE_API_URL
      );

      const formData = new FormData();
      formData.append('image', file);

      await axios.post(imageUploadUrl.toString(), formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 400 &&
          error.response?.data?.error.includes('already exists')
        ) {
          throw new AlreadyExistsError('image already exists');
        }

        throw new Error(`image upload failed: ${error.code} ${error.message}`);
      }

      throw error;
    }
  };

  const getWhiteboardImage = async (
    whiteboardId: string,
    imageId: string
  ): Promise<Blob> => {
    try {
      const response = await axios.get<Blob>(
        `/whiteboard/${whiteboardId}/image/${imageId}`,
        {
          responseType: 'blob', // Critical: specify blob response type
        }
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(`Whiteboard image fetch failed: ${error.message}`);
      }
      throw error;
    }
  };

  const saveWhiteboardData = async (
    whiteboardId: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data: any,
    deleteFiles: string[]
  ): Promise<Whiteboard> => {
    try {
      const response = await axios.post<Whiteboard>(
        `/whiteboard/${whiteboardId}/data`,
        {
          data,
          deleteFiles,
        }
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to save whiteboard data: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const getWhiteboardData = async (
    whiteboardId: string
  ): Promise<WhiteboardData> => {
    try {
      const response: AxiosResponse<WhiteboardData> =
        await axios.get<WhiteboardData>(`/whiteboard/${whiteboardId}/data`);

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch whiteboard data: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  const saveWhiteboardPreviewImage = async (
    whiteboardId: string,
    blob: Blob
  ): Promise<void> => {
    const imageUploadUrl = new URL(
      `/whiteboard/${whiteboardId}/previewImage`,
      import.meta.env.VITE_API_URL
    );

    const imageFile = new File([blob], 'preview.jpeg', { type: blob.type });

    const formData = new FormData();
    formData.append('image', imageFile);

    await axios.post(imageUploadUrl.toString(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const getWhiteboardPreviewImage = async (
    whiteboardId: string
  ): Promise<string> => {
    try {
      const response = await axios.get<Blob>(
        `/whiteboard/${whiteboardId}/previewImage?size=small`,
        {
          responseType: 'blob', // Critical: specify blob response type
        }
      );

      return URL.createObjectURL(response.data);
    } catch (error) {
      if (error instanceof AxiosError) {
        console.log(`Whiteboard preview image fetch failed: ${error.message}`);
      }
      return '';
    }
  };

  const getLastUpdatedWhiteboard = async (): Promise<Whiteboard> => {
    try {
      const response: AxiosResponse<Whiteboard> = await axios.get<Whiteboard>(
        `/user/lastUpdatedWhiteboard`
      );

      return response.data;
    } catch (error) {
      if (error instanceof AxiosError) {
        throw new Error(
          `failed to fetch last updated whiteboard: ${error.code} ${error.message}`
        );
      }

      throw error;
    }
  };

  return {
    createWhiteboard,
    getWhiteboard,
    updateWhiteboard,
    getWhiteboardData,
    getWhiteboards,

    archiveWhiteboard,
    restoreWhiteboard,
    saveWhiteboardData,
    addWhiteboardImage,
    getWhiteboardImage,
    saveWhiteboardPreviewImage,
    getWhiteboardPreviewImage,
    getLastUpdatedWhiteboard,
    addWhiteboardToProject,
  };
};
