import {
  faCogs,
  faMoon,
  faSignOut,
  faSun,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useKeycloak } from '@react-keycloak/web';
import { useEffect, useState } from 'react';
import { capitalizeFirstLetter } from '../../../helpers/string';
import { UserInfo } from '../../../types/user';

import { motion } from 'framer-motion';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { Button as ButtonAria, Switch } from 'react-aria-components';

import Card from '@/components/lib/Containers/Card';
import Button from '@/components/lib/Button';

import i18next, { changeLanguage } from 'i18next';
import { useTheme } from '@/store/theme';
import { useTranslation } from 'react-i18next';

const User = () => {
  const keycloak = useKeycloak();
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  useEffect(() => {
    const getUserInfo = async () => {
      const userInfo = (await keycloak.keycloak.loadUserInfo()) as UserInfo;
      setUserInfo(userInfo);
    };
    getUserInfo();
  }, []);

  if (!userInfo) return null;

  const userName = capitalizeFirstLetter(userInfo.given_name);

  return (
    <div className='flex flex-col p-2 md:p-4'>
      <BlurModal isOpen={isSettingsOpen} setIsOpen={setIsSettingsOpen}>
        <Settings
          userInfo={userInfo}
          onClose={() => setIsSettingsOpen(false)}
        />
      </BlurModal>

      <ButtonAria
        onPress={() => {
          setIsSettingsOpen(true);
        }}
        className='flex flex-col justify-center'>
        {/* USERNAME */}
        <div
          className='border-text-base flex aspect-square items-center justify-center rounded-full
            border-2 p-4 leading-[0]'>
          <p className='font-bold'>{userName.charAt(0)}</p>
        </div>
      </ButtonAria>
    </div>
  );
};

export const Settings = ({
  userInfo,
  onClose,
}: {
  userInfo: UserInfo;
  onClose: () => void;
}) => {
  const { t } = useTranslation('dashboard');
  const { keycloak } = useKeycloak();

  return (
    <Card className='gap-2 p-10 w-100 max-w-[95vw]'>
      <Card.Header icon={faCogs}>
        <div className='flex items-center justify-between'>
          <span className='text-lg'>{t('settings.settings')}</span>
          <Card.CloseButton onClose={onClose} />
        </div>
      </Card.Header>
      <Card.Body>
        <Card.Section direction='column'>
          <Card.SectionHeader>{t('settings.account')}</Card.SectionHeader>
          <Card.Separator />
          <Card.SubSection direction='row'>
            <div className=''>
              <Card.SubSectionHeader>{t('list.name')}</Card.SubSectionHeader>
              <p>{userInfo.name}</p>
            </div>
            <div className=''>
              <Card.SubSectionHeader>{t('list.name')}</Card.SubSectionHeader>
              <p>{userInfo.name}</p>
            </div>
            <div className=''>
              <Card.SubSectionHeader>Email</Card.SubSectionHeader>
              <p>{userInfo.email}</p>
            </div>
          </Card.SubSection>
        </Card.Section>
        <Card.Section>
          <Card.SectionHeader>{t('settings.language')}</Card.SectionHeader>
          <Card.Separator />
          <LanguageSelector />
        </Card.Section>

        <Card.Section>
          <Card.SectionHeader>{t('settings.theme')}</Card.SectionHeader>
          <Card.Separator />
          <ThemeSelector />
        </Card.Section>
      </Card.Body>
      <Button
        leftIcon={faSignOut}
        onClick={() => {
          keycloak.logout();
        }}>
        {t('settings.logOut')}
      </Button>
    </Card>
  );
};

const LanguageSelector = () => {
  const { i18n } = useTranslation();
  return (
    <Switch
      isSelected={i18n.language === 'fr'}
      onChange={() => {
        const lang = i18next.language === 'en' ? 'fr' : 'en';
        changeLanguage(lang);
      }}>
      {({ isSelected }) => {
        return (
          <div className='flex items-center gap-2 text-xs'>
            <p>EN </p>
            <motion.div
              style={{
                justifyContent: isSelected ? 'end' : 'start',
                backgroundColor: 'var(--surface-secondary)',
              }}
              className={'flex w-12 items-center gap-2 rounded-full px-1 py-1'}>
              <motion.div
                layout
                style={{
                  backgroundColor: 'var(--text-base)',
                }}
                transition={{ duration: 0.2 }}
                className='flex aspect-square h-6 items-center justify-center rounded-full bg-white'></motion.div>
            </motion.div>
            <p>FR</p>
          </div>
        );
      }}
    </Switch>
  );
};

const ThemeSelector = () => {
  const [theme, setTheme] = useTheme();
  document.body.classList.toggle('dark-theme', theme === 'dark');
  return (
    <Switch
      defaultSelected={theme === 'dark'}
      onChange={(isSelected) => {
        setTheme(isSelected ? 'dark' : 'light');
      }}>
      {({ isSelected }) => {
        return (
          <div className='flex items-center gap-2 text-xs'>
            <p>Light </p>
            <motion.div
              style={{ justifyContent: isSelected ? 'end' : 'start' }}
              animate={{
                backgroundColor: 'var(--surface-secondary)',
              }}
              className={'flex w-12 items-center gap-2 rounded-full px-1 py-1'}>
              <motion.div
                layout
                animate={{
                  backgroundColor: isSelected
                    ? 'var(--text-base)'
                    : 'rgb(255,255,255)',
                  color: isSelected
                    ? 'var(--surface-secondary)'
                    : 'var(--surface-tertiary)',
                }}
                transition={{ duration: 0.2 }}
                className='flex aspect-square h-6 items-center justify-center rounded-full bg-white'>
                {isSelected ? (
                  <FontAwesomeIcon icon={faMoon} />
                ) : (
                  <FontAwesomeIcon icon={faSun} />
                )}
              </motion.div>
            </motion.div>
            <p>Dark</p>
          </div>
        );
      }}
    </Switch>
  );
};

export default User;
