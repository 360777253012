import RenamableItem from '@/components/lib/RenamableItem';
import useGetProject from '@/hooks/projects/useGetProject';
import { useUpdateProject } from '@/hooks/projects/useRenameProject';

import { useTranslation } from 'react-i18next';

type EditableProjectDescriptionProps = {
  projectId: string;
  className?: string;
};

export const EditableProjectDescription = ({
  projectId,
  className,
}: EditableProjectDescriptionProps) => {
  const { project } = useGetProject(projectId);
  const { updateProject } = useUpdateProject();

  const { t } = useTranslation('dashboard');

  const handleRename = async (newDesc: string) => {
    if (!project) return;
    await updateProject({
      projectId: project.id,
      project: {
        description: newDesc,
      },
    });
  };

  return (
    <RenamableItem
      initialName={
        project?.description ||
        (t('pages.project.overview.noDescription') as string)
      }
      rows={4}
      onRename={handleRename}
      className={className}
    />
  );
};
