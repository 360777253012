import Button from '@/components/lib/Button';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

import { NavLink, useLocation, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';

type SidebarLinkProps = {
  to: string;
  children: React.ReactNode;
  icon: IconDefinition;
  activeSelectorId?: string;
  className?: string;
  showLabel?: boolean;
  iconSize?: string;
  orientation?: 'vertical' | 'horizontal';
};

const SidebarLink = ({
  to,
  children,
  orientation = 'vertical',
  icon,
  activeSelectorId,
  showLabel = true,
  iconSize = '20px',

  className,
}: SidebarLinkProps) => {
  const location = useLocation();
  let isActive = location.pathname === to;

  const { whiteboardId, projectId } = useParams<{
    whiteboardId: string;
    projectId: string;
  }>();

  if (whiteboardId) {
    isActive = to === '/whiteboards';
  }
  if (projectId) {
    isActive = to === '/projects';
  }

  return (
    <NavLink
      to={to}
      className={twMerge(
        `text-text-base relative flex aspect-square sm:w-[70px] w-[60px] items-center
        justify-between gap-3 overflow-visible rounded-xl text-xs font-medium
        tracking-tighter`,
        className
      )}>
      <Button
        leftIcon={icon}
        showLabel={showLabel}
        selectorId={activeSelectorId}
        orientation={orientation}
        iconSize={iconSize}
        size={'small'}
        className='h-full w-full gap-3 overflow-visible p-2 sm:p-4'
        state={isActive ? 'active' : 'default'}
        variant='menu'>
        {children}
      </Button>
    </NavLink>
  );
};

export default SidebarLink;
