import {
  DefaultColorThemePalette,
  StateNode,
  TLDrawShapeSegment,
  TLPointerEventInfo,
  TLShape,
  TLShapeId,
  toFixed,
  Vec,
} from 'tldraw';
import { TLDrawShapeCustom } from '../CustomDraw/CustomDrawShapeUtil';
import isPointInBounds from '../../helpers/isPointInBounds';
import { brushSize } from '../../CustomStyles/BrushSize';

const eraserShapeId = 'shape:eraser_path' as TLShapeId;
const supportedShapeTypes = ['drawCustom', 'geoCustom', 'textCustom', 'image'];

export class CustomEraser extends StateNode {
  static override id = 'customEraser'; //Tool id

  override shapeType = 'drawCustom'; //The shape type that the tool can interact with

  info = {} as TLPointerEventInfo;

  // currentSegment is the current drawing path
  currentSegment: TLDrawShapeSegment = {
    type: 'free',
    points: [],
  };

  // erasedShapes are the shapes that are passed over by the eraser
  erasedShapes: Map<string, TLShape> = new Map();

  isErasing = false;

  // eraserShape is a temporary draw that simulates the eraser path
  eraserShape: TLShape | undefined = undefined;

  bgColor: string = DefaultColorThemePalette.lightMode.background;

  // This is called when the tool is activated
  override onEnter() {
    this.editor.setCursor({ type: 'cross', rotation: 0 });
    if (this.editor.user.getIsDarkMode()) {
      this.bgColor = DefaultColorThemePalette.darkMode.background;
    }
  }

  override onPointerDown(info: TLPointerEventInfo) {
    this.info = info;
    this.isErasing = true;

    this.editor.markHistoryStoppingPoint();

    // Transpose the screen point to a page point to take account of scrolling
    const point = this.editor.screenToPage(this.info.point);

    // We keep track of every shape we pass over
    this.detectErasedShapes(point);

    // Initialize the eraser segment
    this.currentSegment = {
      type: 'free',
      points: [
        {
          x: toFixed(point.x),
          y: toFixed(point.y),
          z: toFixed(point.z || 0),
        },
      ],
    };

    // Initialize the eraser draw shape
    this.editor.createShape({
      id: eraserShapeId,
      type: 'drawCustom',
      x: point.x,
      y: point.y,
      props: {
        hexColor: this.bgColor,
        fill: 'none',
        brushSize: this.editor.getStyleForNextShape(brushSize), //Use the same size as the current brush size
        segments: [
          {
            type: 'free',
            points: [
              {
                x: 0,
                y: 0,
                z: toFixed(point.z || 0),
              },
            ],
          },
        ],
      },
    });

    this.eraserShape = this.editor.getShape(eraserShapeId);
  }

  override onPointerMove(info: TLPointerEventInfo) {
    if (!this.isErasing) return;

    this.info = info;

    // Transpose the screen point to a page point to take account of scrolling
    const point = this.editor.screenToPage(this.info.point);

    // We keep track of every shape we pass over
    this.detectErasedShapes(point);

    this.currentSegment.points.push(point);

    // Update the eraser draw shape, in order to display the eraser path
    if (this.eraserShape && this.eraserShape.id) {
      const shapeId = this.eraserShape.id;
      if (shapeId) {
        const pointsInShapeSpace = this.currentSegment.points.map((point) => {
          const pointInSpace = this.editor.getPointInShapeSpace(shapeId, point);
          return {
            x: toFixed(pointInSpace.x),
            y: toFixed(pointInSpace.y),
            z: toFixed(point.z || 0),
          };
        });

        this.editor.updateShape({
          id: this.eraserShape.id,
          type: 'drawCustom',
          props: {
            segments: [
              {
                type: 'free',
                points: pointsInShapeSpace,
              },
            ],
            isClosed: false,
            fill: 'none',
          },
        });
      }
    }
  }

  override onPointerUp(info: TLPointerEventInfo) {
    this.info = info;
    this.isErasing = false;

    //Add the eraser path to the shape we passed over
    this.erasedShapes.forEach((shape) => {
      // Cast the shape to a custom draw shape to have access to the eraserPaths
      const customDrawShape = shape as TLDrawShapeCustom;

      // Convert the segment to the shape space
      // TODO: remove points that are out of the shape
      const pointsInShapeSpace: { x: number; y: number; z: number }[] = [];
      this.currentSegment.points.forEach((point) => {
        // Eliminate points that are far from the shape bounds
        // FIX: This causes bugs when we exit, then reenter the shape, bad fix for now, augmenting the padding
        const bounds = this.editor.getShapePageBounds(customDrawShape);
        if (!isPointInBounds(point, bounds, 1000)) {
          return;
        }

        const pointInShapeSpace = this.editor.getPointInShapeSpace(
          customDrawShape,
          point
        );

        pointsInShapeSpace.push({
          x: toFixed(pointInShapeSpace.x),
          y: toFixed(pointInShapeSpace.y),
          z: toFixed(point.z || 0), // z is the pressure so it must not be converted
        });
      });

      this.editor.updateShape({
        ...customDrawShape,
        props: {
          eraserPaths: [
            ...customDrawShape.props.eraserPaths,
            {
              width: this.editor.getStyleForNextShape(brushSize), //Use the same size as the current brush size
              segment: {
                type: 'free',
                points: pointsInShapeSpace,
              },
            },
          ],
        },
      });

      console.log('Erased shape', customDrawShape);
    });

    // Delete the temporary shape
    if (this.eraserShape) this.editor.deleteShape(this.eraserShape.id);

    // Reset current erasing data
    this.currentSegment = { type: 'free', points: [] };
    this.erasedShapes.clear();
    this.eraserShape = undefined;
  }

  override onExit() {
    this.info = {} as TLPointerEventInfo;
  }

  // detectErasedShapes finds every shape under the given point
  // and add it to this.erasedShapes only if its type is supported
  private detectErasedShapes(point: Vec) {
    // We keep track of every shape we pass over
    const shapes = this.editor.getShapesAtPoint(point, {
      hitInside: true,
      margin: 60,
    });

    shapes.forEach((shape) => {
      // Don't add eraser shape
      if (shape.id === eraserShapeId) return;
      if (!supportedShapeTypes.includes(shape.type)) return;

      if (!this.erasedShapes.has(shape.id.toString()))
        this.erasedShapes.set(shape.id.toString(), shape);
    });
  }
}
