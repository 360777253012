import { CustomLineShapeUtil } from '../CustomTools/CustomLineTool/CustomLineShapeUtil';
import { CustomDrawShapeUtil } from '../CustomTools/CustomDraw/CustomDrawShapeUtil';
import { CustomGeoShapeUtil } from '../CustomTools/CustomGeo/CustomGeoShapeUtil';
import { CustomTextShapeUtil } from '../CustomTools/CustomText/CustomTextShapeUtil';
import { CustomImageShapeUtil } from '../CustomShapeUtil/CustomImageShapeUtil';

export const customShapes = [
  CustomDrawShapeUtil,
  CustomGeoShapeUtil,
  CustomTextShapeUtil,
  CustomLineShapeUtil,
  CustomImageShapeUtil,
];

//When we directly override the default shape util (without changing the shape type name), we need to remove the default shape util from the editor
//This goes in the editor config and store config
//This might not me necessary starting tldraw 3.8
export const UNWANTED_DEFAULT_SHAPE_TYPES = ['image'];
