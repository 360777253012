import { useTranslation } from 'react-i18next';
import TabHelp from '../Tabs/TabHelp';
import TextArea from '../../../lib/Inputs/TextArea';
// Import TabDropdown
import { usePanelContext } from '../context/usePanelContext';
import { motion } from 'framer-motion';

import { usePanelInputContext } from '../context/usePanelInputContext';
import { useAlbumContext } from '../context/useAlbumContext';

import { UserRole } from '../../../../types';

import GraphicStyleDropdown from './StyleDropdowns/GraphicStyleDropdown';

import Slider from '../../../lib/Inputs/Slider';

import { ReactNode, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useHasRole } from '@/hooks/useHasRole';
import BlurModal from '@/components/lib/Containers/BlurModal';
import { NewAlbumForm } from '@/components/Common/ItemsForms/NewAlbumForm';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import { ModifyAlbumForm } from '@/components/Common/ItemsForms/ModifyAlbumForm';

import { AdvancedSettings } from './AdvancedSettings/AdvancedSettings';
import AlbumDropdown from './StyleDropdowns/AlbumDropdown';

export const Imagine = () => {
  const hasRole = useHasRole();

  /* CONTEXT */
  const { setImagineInputText, setAlbumCursor, imagineInputText } =
    usePanelInputContext();
  const { whiteboardId } = useParams();

  const { whiteboard } = useGetWhiteboard(whiteboardId ?? '');

  const { albumToModify, selectedAlbum, setSelectedAlbum, setAlbumToModify } =
    useAlbumContext();

  /* HELP POPUP STATE */
  const { showHelp, setShowHelp, showCreateNewAlbum, setShowCreateNewAlbum } =
    usePanelContext();

  const { t } = useTranslation(['panel']);

  // Check userRoles
  const inspireAllowed = hasRole(UserRole.Inspire);

  // TODO: for all the places where we use local storage, we should use a custom hook to handle it, or find a library, done for the slider
  // Get input text from local storage
  useEffect(() => {
    // Imagine description
    const savedText = localStorage.getItem(`imagineTextInput-${whiteboardId}`);
    if (savedText) {
      setImagineInputText(savedText);
    }
  }, []);

  const handleTextChange = useCallback(
    (text: string) => {
        console.log("Input text", text);
      setImagineInputText(text);
      localStorage.setItem(`imagineTextInput-${whiteboardId}`, text);
    },
    [setImagineInputText, whiteboardId]
  );

  const handleAlbumCursorChange = useCallback(
    (value: number) => {
      setAlbumCursor(value);
    },
    [setAlbumCursor, whiteboardId]
  );

  return (
    <motion.div layout className='flex w-full flex-col gap-3 text-xs'>
      {/* HELP */}
      {showHelp && (
        <TabHelp
          setShowHelp={setShowHelp}
          steps={[
            t('panel:Imagine.help.step1'),
            t('panel:Imagine.help.step2'),
            t('panel:Imagine.help.step3'),
          ]}
        />
      )}

      {/* NEW ALBUM POPUP */}

      <BlurModal
        isOpen={showCreateNewAlbum}
        setIsOpen={setShowCreateNewAlbum}
        fullScreen={false}>
        <NewAlbumForm
          projectId={whiteboard?.projectId}
          onClose={(album) => {
            setShowCreateNewAlbum(false);
            if (album) {
              // Select the newly created album
              console.log('album', album);
              localStorage.setItem(
                `imagineSelectedAlbum-${whiteboardId}`,
                album.id || ''
              );

              setSelectedAlbum(album.id);
            }
          }}
        />
      </BlurModal>

      {/* VIEW ALBUM TO MODIFY */}
      <BlurModal
        isOpen={!!albumToModify}
        setIsOpen={() => {
          setAlbumToModify(null);
        }}
        fullScreen={false}>
        <ModifyAlbumForm
          albumId={albumToModify || ''}
          onClose={() => {
            setAlbumToModify(null);
          }}
        />
      </BlurModal>

      <motion.div layout className='flex flex-col gap-6 rounded-2xl'>

        {/* INPUT */}
        <TextArea
          label={t('panel:Imagine.promptLabel')}
          id='prompt'
          rows={4}
          className='bg-surface-primary/50 text-text-base'
          placeholder={t('panel:Imagine.promptPlaceholder')}
          value={imagineInputText} // Add this line
          onChange={(e) => {
            handleTextChange(e.target.value);
          }}
        />

        <motion.div layout className='flex flex-col gap-3 text-xs'>
          {/* DESIGN STYLE */}
          <motion.div layout className='pl-2 text-sm'>
            {t('panel:Imagine.designStyleLabel')}
          </motion.div>

          <motion.div layout className='relative grid grid-cols-2 gap-2'>
            <GraphicStyleDropdown />

            {inspireAllowed && <AlbumDropdown />}
          </motion.div>
          {inspireAllowed && selectedAlbum && (
            <>
              <motion.div layout className='pl-2 text-sm'>
                {t('panel:Inspire.inspirationLevel')}
              </motion.div>
              <Slider
                labels={[
                  t('panel:Inspire.SliderLabels.None'),
                  t('panel:Inspire.SliderLabels.Average'),
                  t('panel:Inspire.SliderLabels.Strong'),
                ]}
                step={0.1}
                range={[0, 1]}
                id='inspiration-cursor'
                defaultValue={0.8}
                onChange={(value) => handleAlbumCursorChange(value)}
              />
            </>
          )}
        </motion.div>

        <AdvancedSettings />
      </motion.div>
    </motion.div>
  );
};

export const SectionTitle = ({
  children,
}: {
  children: ReactNode | ReactNode[];
}) => {
  return (
    <h2
      className='text-[14px] tracking-tight flex gap-2 text-text-base-saturated items-center
        border-b-text-base-saturated/40 border-b py-3 w-full'>
      {children}
    </h2>
  );
};
