import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { allRoutes } from '@/pages/router';
import { UserRole } from '@/types';
import { useHasRole } from '@/hooks/useHasRole';
import useGetAlbums from '@/hooks/albums/useGetAlbums';
import useGetProjects from '@/hooks/projects/useGetProjects';
import { useGetWhiteboards } from '@/hooks/whiteboard/useGetWhiteboards';
import useGetAllCreations from '@/hooks/creations/useGetAllCreations';
import { Action } from '../types';

/**
 * useComboboxActions Hook
 *
 * This hook provides all the available actions for the Combobox component and manages the state
 * of modals (creation and detail views).
 *
 * Features:
 * - Fetches data from various sources (creations, albums, projects, whiteboards)
 * - Creates action items with proper labels, types, and handlers
 * - Manages modal state for creation forms and detail views
 * - Respects user permissions when showing actions
 *
 * @returns {Object} An object containing:
 *   - actions: Array of all available actions for the combobox
 *   - creationDetailModalOpen: State for the creation detail modal
 *   - setCreationDetailModalOpen: Function to control the creation detail modal
 *   - createModalOpen: State for the creation modal (project or whiteboard)
 *   - setCreateModalOpen: Function to control the creation modal
 *
 * @example
 * // How to add a new action type:
 * // 1. Define a new action group in this file using useMemo
 * const myNewActions = useMemo<Action[]>(() => {
 *   return myItems.map(item => ({
 *     id: item.id,
 *     title: item.name,
 *     searchLabel: `${item.name} ${item.description}`, // Add relevant search terms
 *     type: 'myNewType', // Must be added to ActionType in types.ts
 *     action: () => {
 *       // What happens when this action is selected
 *       navigate(`/my-path/${item.id}`);
 *     }
 *   }));
 * }, [myItems, navigate]);
 *
 * // 2. Add your new actions to the combined actions array
 * const actions = useMemo<Action[]>(() => {
 *   return [...existingActions, ...myNewActions];
 * }, [existingActions, myNewActions]);
 */
export const useComboboxActions = () => {
  const hasRole = useHasRole();
  const navigate = useNavigate();
  const { t } = useTranslation('dashboard');

  const hasInspireRole = hasRole(UserRole.Inspire);
  const hasProjectsRole = hasRole(UserRole.Projects);

  const { albums } = useGetAlbums('visible');
  const { projects } = useGetProjects('visible');
  const { whiteboards } = useGetWhiteboards('visible');
  const { creations } = useGetAllCreations();

  const [creationDetailModalOpen, setCreationDetailModalOpen] = useState<
    string | null
  >(null);
  const [createModalOpen, setCreateModalOpen] = useState<
    'whiteboard' | 'project' | null
  >(null);

  /**
   * Creates action items for creations
   * @type {Action[]}
   */
  const creationActions = useMemo<Action[]>(() => {
    if (!creations) return [];
    return creations.map((creation) => ({
      id: creation.id,
      title: creation.input,
      searchLabel: creation.input,
      type: 'creation',
      action: () => {
        setCreationDetailModalOpen(creation.id);
      },
    }));
  }, [creations]);

  /**
   * Creates action items for albums
   * Only available if user has Inspire role
   * @type {Action[]}
   */
  const albumActions = useMemo<Action[]>(() => {
    if (!albums || !hasInspireRole) return [];
    return albums.map((album) => ({
      id: album.id,
      title: album.name,
      searchLabel: `${album.name}`,
      type: 'album',
      action: () => {
        navigate(`/albums/${album.id}`);
      },
    }));
  }, [albums, hasInspireRole, navigate]);

  /**
   * Creates action items for projects
   * Only available if user has Projects role
   * @type {Action[]}
   */
  const projectActions = useMemo<Action[]>(() => {
    if (!projects || !hasProjectsRole) return [];
    return projects.map((project) => ({
      id: project.id,
      title: project.name,
      searchLabel: `${project.name}`,
      type: 'project',
      action: () => {
        navigate(`/projects/${project.id}`);
      },
    }));
  }, [projects, hasProjectsRole, navigate]);

  /**
   * Creates action items for whiteboards
   * @type {Action[]}
   */
  const whiteboardActions = useMemo<Action[]>(() => {
    if (!whiteboards) return [];
    return whiteboards.map((whiteboard) => ({
      id: whiteboard.id,
      title: whiteboard.name,
      searchLabel: `${whiteboard.name}`,
      type: 'whiteboard',
      action: () => {
        navigate(`/creator/${whiteboard.id}`);
      },
    }));
  }, [whiteboards, navigate]);

  /**
   * Creates action items for navigating to different pages
   * Respects user roles for each route
   * @type {Action[]}
   */
  const navigateActions = useMemo<Action[]>(() => {
    return allRoutes
      .map((route) => {
        if (!hasRole(route.necessaryRoles)) return null;
        return {
          id: route.path,
          title: `${t('combobox.goTo')} ${t(route.label)}`,
          searchLabel: `${t('combobox.goTo')} ${route.label} ${t(route.label)}`,
          type: 'page',
          action: () => {
            navigate(route.path);
          },
        };
      })
      .filter(Boolean) as Action[];
  }, [hasRole, t, navigate]);

  /**
   * Creates action items for creating new items (projects, whiteboards)
   * @type {Action[]}
   */
  const createActions = useMemo<Action[]>(() => {
    return [
      {
        id: 'create-project',
        title: t('combobox.createProject'),
        searchLabel: t('combobox.createProject'),
        type: 'create',
        action: () => {
          setCreateModalOpen('project');
        },
      },
      {
        id: 'create-whiteboard',
        title: t('combobox.createWhiteboard'),
        searchLabel: t('combobox.createWhiteboard'),
        type: 'create',
        action: () => {
          setCreateModalOpen('whiteboard');
        },
      },
    ];
  }, [t]);

  /**
   * Combines all action types into a single array
   * The order determines priority in display
   * @type {Action[]}
   */
  const actions = useMemo<Action[]>(() => {
    let allActions = [
      ...creationActions,
      ...albumActions,
      ...projectActions,
      ...whiteboardActions,
    ];

    if (hasProjectsRole) {
      allActions = [...allActions, ...createActions];
    }

    return [...allActions, ...navigateActions];
  }, [
    creationActions,
    albumActions,
    projectActions,
    whiteboardActions,
    createActions,
    navigateActions,
    hasProjectsRole,
  ]);

  return {
    actions,
    creationDetailModalOpen,
    setCreationDetailModalOpen,
    createModalOpen,
    setCreateModalOpen,
  };
};
