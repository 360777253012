import {
  GeoShapeGeoStyle,
  TLUiAssetUrlOverrides,
  TLUiIconType,
  TLUiOverrides,
  TLUiTranslationKey,
} from 'tldraw';

// This export is used to add new tools to the UI context of the editor
// Their id must correspond to an existing tool class...
// ...which must also be added to the CustomTools.ts file
export const uiOverrides: TLUiOverrides = {
  tools(editor, tools) {
    tools.drawToolCustom = {
      id: 'drawToolCustom',
      icon: 'tool-pencil',
      label: 'Pencil',
      kbd: 'p', //Keyboard shortcut
      onSelect: () => {
        editor.setCurrentTool('drawToolCustom');
      },
    };
    tools.customEraser = {
      id: 'customEraser',
      icon: 'tool-eraser',
      label: 'Eraser',
      kbd: 'e',
      onSelect: () => {
        editor.setCurrentTool('customEraser');
      },
    };
    tools.textToolCustom = {
      id: 'textToolCustom',
      icon: 'tool-text',
      label: 'Text',
      kbd: 't',
      onSelect: () => {
        editor.setCurrentTool('textToolCustom');
      },
    };
    tools.lineToolCustom = {
      id: 'lineToolCustom',
      icon: 'tool-line',
      label: 'Line',
      kbd: 'l',
      onSelect: () => {
        editor.setCurrentTool('lineToolCustom');
      },
    };
    tools.colorSampler = {
      id: 'colorSampler',
      icon: 'tool-colorSampler',
      label: 'Color Sampler',
      kbd: 'c',
      onSelect: () => {
        editor.setCurrentTool('colorSampler');
      },
    };
    //Custom Geo Tools
    // Creating a custom tool for each GeoShapeGeoStyle
    // Those correspond to the shapes in to CustomGeoShapeTool.tsx classes and are used in the CustomToolbar.tsx
    // Geo tools is special because it's in fact the same tool and shape util for every different kind of shape
    // The only thing that changes is the style of the shape, store in the props of GeoShapeUtil
    // Check useTools.tsx code from the tldraw library
    [...GeoShapeGeoStyle.values].forEach((id) => {
      tools[id + 'ToolCustom'] = {
        id: id + 'ToolCustom',
        label: `tool.${id}` as TLUiTranslationKey,
        meta: {
          geo: id,
        },
        kbd: id === 'rectangle' ? 'r' : id === 'ellipse' ? 'o' : undefined,
        icon: ('geo-' + id) as TLUiIconType,
        onSelect() {
          editor.run(() => {
            editor.setStyleForNextShapes(GeoShapeGeoStyle, id);
            editor.setCurrentTool(id + 'ToolCustom');
          });
        },
      };
    });
    return tools;
  },
};

export const customAssetUrls: TLUiAssetUrlOverrides = {
  icons: {
    'tool-colorSampler': '/toolIcons/eye-dropper.svg',
  },
};
