import { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

import { Album } from '../../../../types/api';
import DeletableAlbumImage from './DeletableAlbumImage';
import useAddImageToAlbum from '../../../../hooks/albums/useAddImageToAlbum';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import ImageDropzone from '../../../lib/Inputs/ImageDropzone';
import { addImagesDialog } from '../../../../helpers/fileHelpers';
import { Loader2 } from 'lucide-react';
import { twMerge } from 'tailwind-merge';

interface AlbumImageGalleryProps {
  album: Album;
  className?: string;
}

const AlbumImageGallery = ({ album, className }: AlbumImageGalleryProps) => {
  const { t } = useTranslation(['panel']);
  const { addImageToAlbum, isUploading } = useAddImageToAlbum(album);

  const [isEveryFileUnder10MB, setIsEveryFileUnder10MB] = useState(true);

  const isAlbumFull = album.images.length >= 5;

  const handleDrop = async (files: File[]) => {
    if (isAlbumFull || isUploading) return;
    addImageToAlbum(files);
  };

  const handleAddFiles = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isUploading) return;
    e.preventDefault();
    addImagesDialog(async (files) => {
      if (files.length === 0) {
        setIsEveryFileUnder10MB(false);
        return;
      }
      setIsEveryFileUnder10MB(true);

      addImageToAlbum(files);
    });
  };

  return (
    <motion.div
      className='relative @container w-full overflow-hidden'
      layout='position'>
      <ImageDropzone disabled={isAlbumFull} className='p-0' onDrop={handleDrop}>
        {/* UPLOADING IMAGE LOADER */}

        <div
          className={twMerge(
            'grid h-min w-full grid-cols-1 @md:grid-cols-4 gap-x-6 gap-y-4',
            className
          )}>
          {album.images
            .sort((a, b) => {
              //sort by creation date
              return (
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
              );
            })
            .map((image) => (
              <DeletableAlbumImage
                key={image.id}
                albumId={album.id}
                imageId={image.id}
              />
            ))}

          {!isAlbumFull && (
            <>
              {/* ADD IMAGE Button */}
              <button
                onClick={handleAddFiles}
                className='border-grey flex aspect-square cursor-pointer items-center justify-center
                  rounded-xl border border-dashed hover:opacity-50'>
                {isUploading ? (
                  <Loader2 className='animate-spin text-white' size={30} />
                ) : (
                  <FontAwesomeIcon icon={faPlus} className='text-2xl' />
                )}
              </button>
              {/* DROP CTA */}
              <div className='my-4 text-center text-sm col-span-full'>
                {t('panel:Inspire.NewAlbum.DropCTA')}
              </div>
            </>
          )}
        </div>

        {!isEveryFileUnder10MB && (
          <div className='text-center text-sm'>
            {t('panel:Inspire.NewAlbum.bigFiles')}
          </div>
        )}
      </ImageDropzone>
    </motion.div>
  );
};

export default AlbumImageGallery;
