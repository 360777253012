import { useMutation, useQueryClient } from 'react-query';
import { useProjectAPI } from './useProjectAPI';
import { Project } from '../../types/api';

const useCreateProject = (onSuccess?: (project: Project) => void) => {
  const queryClient = useQueryClient();
  const { createProject } = useProjectAPI();

  const createProjectMutation = useMutation({
    mutationFn: async (name: string) => {
      return await createProject(name);
    },
    onSuccess: (project) => {
      queryClient.setQueryData(['project', project.id], project);
      queryClient.setQueryData<Project[]>(['projects'], (oldData) => {
        if (!oldData) return [project];
        return [project, ...oldData];
      });
      onSuccess?.(project);
    },
    onSettled: () => {
      queryClient.invalidateQueries('projects');
    },
  });

  return {
    createProject: (name?: string) =>
      createProjectMutation.mutate(
        name ??
          new Date().toLocaleDateString() +
            ' - ' +
            new Date().toLocaleTimeString()
      ),
    isLoading: createProjectMutation.isLoading,
    isError: createProjectMutation.isError,
    error: createProjectMutation.error,
  };
};

export default useCreateProject;
