import useGetAlbums from '@/hooks/albums/useGetAlbums';
import Collection from '@/components/lib/Containers/Collection';
import { AlbumItem } from '../../Albums/AlbumItem';

interface AlbumTabProps {
  projectId: string;
  presentation: 'grid' | 'list';
}

const AlbumTab = ({ projectId, presentation }: AlbumTabProps) => {
  const { albums } = useGetAlbums('visible', projectId);

  if (!albums) return null;
  return (
    <Collection presentation={presentation}>
      {albums.map((album) => (
        <AlbumItem
          key={album.id}
          albumId={album.id}
          presentation={presentation}
        />
      ))}
    </Collection>
  );
};

export default AlbumTab;
