/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect } from 'react';
import { KeyboardEvent } from 'react';

/**
 * useKeyboardNavigation Hook
 *
 * This hook adds keyboard shortcut support to toggle the combobox visibility.
 * It listens for Ctrl+K keyboard combination to show/hide the combobox.
 *
 * @param {React.RefObject<HTMLInputElement>} inputRef - Reference to the combobox input element
 * @param {Function} setIsFocused - Function to update the focused state of the combobox
 */
export const useKeyboardNavigation = (
  inputRef: React.RefObject<HTMLInputElement>,
  setIsFocused: (focused: boolean | ((prev: boolean) => boolean)) => void
) => {
  useEffect(() => {
    const handleShortcut = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'k') {
        e.preventDefault();
        setIsFocused((prev: boolean) => !prev);
        inputRef.current?.focus();
      }
    };

    window.addEventListener('keydown', handleShortcut as any);
    return () => window.removeEventListener('keydown', handleShortcut as any);
  }, [inputRef, setIsFocused]);
};
