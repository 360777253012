import {
  Editor,
  getSvgPathFromPoints,
  T,
  TLDrawShapeSegment,
  TLResizeInfo,
  TLShape,
  toFixed,
} from 'tldraw';
import { getFreehandOptions, getPointsFromSegments } from '../TldrawExports';

import { svgInk } from '../../helpers/svgInk';
import { TLImageShapeCustom } from '../../CustomShapeUtil/CustomImageShapeUtil';

export type ErasableShape = TLShape & {
  props: {
    eraserPaths: EraserPathSegment[];
  };
};

export type ErasableShapeProps = {
  eraserPaths: EraserPathSegment[];
};

type EraserPathSegment = {
  width: number;
  segment: TLDrawShapeSegment;
};

export const erasableShapeProps = {
  eraserPaths: T.any, //This is a hack. I couldn't find a way to validate an array of TLDrawShapeSegment. Look into T.arrayOf(T.shape({...}))
};

export const erasableShapeDefaultProps = {
  eraserPaths: [],
};

export const resizeEraserPaths = (
  customShape: ErasableShape,
  info: Partial<TLResizeInfo<TLShape>>
) => {
  const newEraserSegments: EraserPathSegment[] = [];

  const { scaleX, scaleY } = info;

  if(!scaleX || !scaleY) return customShape.props.eraserPaths;
  // Resizing each eraser path
  for (const path of customShape.props.eraserPaths) {
    //The offset corrects the coordinates when scaling into negative values
    let xOffset = 0;
    let yOffset = 0;

    if (customShape.type === 'image') {
      const imageShape = customShape as TLImageShapeCustom;

      xOffset = scaleX < 0 ? imageShape.props.w : 0;
      yOffset = scaleY < 0 ? imageShape.props.h : 0;
    }

    //Resizing each segment
    newEraserSegments.push({
      width: path.width * Math.abs(scaleX),
      segment: {
        type: path.segment.type,
        points: path.segment.points.map(({ x, y, z }) => {
          return {
            x: toFixed(scaleX * (x - xOffset)),
            y: toFixed(scaleY * (y - yOffset)),
            z,
          };
        }),
      },
    });
  }

  return newEraserSegments;
};

export const getEraserMask = (
  editor: Editor,
  shape: ErasableShape
): JSX.Element | null => {
  //Getting the eraser paths from the custom shape props
  const eraserPaths = shape.props.eraserPaths;

  //The bounds of the shape in page space
  const bounds = editor.getShapePageBounds(shape);
  if (!bounds) return null;

  //The goal is to create a mask on the svg that only shows the parts not hidden by the eraser paths
  //What's white on the mask will be shown, what's black will be hidden

  //First we need to create a white rectangle that covers the whole shape, otherwise the whole shape will be hidden
  //Get the shape corners in page space
  const corners = [
    { x: bounds.x, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y + bounds.h },
    { x: bounds.x, y: bounds.y + bounds.h },
  ];

  //Transform them intoShapeSpace
  const cornersInShapeSpace = corners.map((corner) => {
    return editor.getPointInShapeSpace(shape, corner);
  });

  //Get segments from corners
  const cornerSegments: TLDrawShapeSegment[] = [
    {
      type: 'straight',
      points: [cornersInShapeSpace[0], cornersInShapeSpace[1]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[1], cornersInShapeSpace[2]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[2], cornersInShapeSpace[3]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[3], cornersInShapeSpace[0]],
    },
  ];

  //Get the svg path from the segments
  const recStroke = getSvgPathFromPoints(
    getPointsFromSegments(cornerSegments),
    true
  );

  let eraserMask;
  if (eraserPaths.length > 0) {
    eraserMask = (
      /* There's only one mask per shape */
      <mask id={'eraserMask' + shape.id}>
        {/* White rectangle */}
        <path
          d={recStroke}
          fill='white'
          stroke='white'
          strokeWidth={
            '200px'
          } /* strokeWidth provides some padding for the mask, so it doesn't cut the shape */
        ></path>
        {/* Black eraser paths */}
        {eraserPaths.map((eraserPath, index) => {
          if (!eraserPath.segment.points.length) return null;

          const maskOptions = getFreehandOptions(
            {
              dash: 'draw',
              isPen: false,
              isComplete: false,
            },
            eraserPath.width + 1, // TODO: get size from props
            false,
            false
          );
          const eraserPathPoints = getPointsFromSegments([eraserPath.segment]);
          return (
            <path
              key={'eraserMask' + shape.id + index}
              d={svgInk(eraserPathPoints, maskOptions)}
              strokeLinecap='round'
              stroke='black'
              fill='black'
            />
          );
        })}
      </mask>
    );
  }

  if (!eraserMask) return null;
  return eraserMask;
};

export const getEraserMaskDataUrl = (
  editor: Editor,
  shape: ErasableShape
): string | null => {
  // Get eraser paths and bounds
  const eraserPaths = shape.props.eraserPaths;
  const bounds = editor.getShapePageBounds(shape);
  if (!bounds || !eraserPaths.length) return null;

  // Create the SVG element
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', bounds.w.toString());
  svg.setAttribute('height', bounds.h.toString());
  svg.setAttribute('viewBox', `0 0 ${bounds.w} ${bounds.h}`);

  // Create the white rectangle background
  const corners = [
    { x: bounds.x, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y + bounds.h },
    { x: bounds.x, y: bounds.y + bounds.h },
  ];

  const cornersInShapeSpace = corners.map((corner) =>
    editor.getPointInShapeSpace(shape, corner)
  );

  const cornerSegments: TLDrawShapeSegment[] = [
    {
      type: 'straight',
      points: [cornersInShapeSpace[0], cornersInShapeSpace[1]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[1], cornersInShapeSpace[2]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[2], cornersInShapeSpace[3]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[3], cornersInShapeSpace[0]],
    },
  ];

  const recStroke = getSvgPathFromPoints(
    getPointsFromSegments(cornerSegments),
    true
  );

  // Add white rectangle path
  const whitePath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  whitePath.setAttribute('d', recStroke);
  whitePath.setAttribute('fill', 'white');
  whitePath.setAttribute('stroke', 'white');
  whitePath.setAttribute('stroke-width', '200px');
  svg.appendChild(whitePath);

  // Add black eraser paths
  eraserPaths.forEach((eraserPath) => {
    if (!eraserPath.segment.points.length) return;

    const maskOptions = getFreehandOptions(
      {
        dash: 'draw',
        isPen: false,
        isComplete: false,
      },
      eraserPath.width + 1,
      false,
      false
    );

    const eraserPathPoints = getPointsFromSegments([eraserPath.segment]);
    const blackPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    blackPath.setAttribute('d', svgInk(eraserPathPoints, maskOptions));
    blackPath.setAttribute('stroke-linecap', 'round');
    blackPath.setAttribute('stroke', 'black');
    blackPath.setAttribute('fill', 'black');
    svg.appendChild(blackPath);
  });

  // Convert to data URL
  const svgString = new XMLSerializer().serializeToString(svg);
  const encodedSvg = encodeURIComponent(svgString);
  return `data:image/svg+xml;charset=utf-8,${encodedSvg}`;
};

// Add this function near the existing getEraserMaskDataUrl
export const getEraserMaskBlobUrl = (
  editor: Editor,
  shape: ErasableShape
): string | null => {
  const eraserPaths = shape.props.eraserPaths;
  const bounds = editor.getShapePageBounds(shape);
  if (!bounds || !eraserPaths.length) return null;

  // Create the SVG element
  const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  svg.setAttribute('width', bounds.w.toString());
  svg.setAttribute('height', bounds.h.toString());
  svg.setAttribute('viewBox', `0 0 ${bounds.w} ${bounds.h}`);


  // Create and add the white rectangle background
  const corners = [
    { x: bounds.x, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y },
    { x: bounds.x + bounds.w, y: bounds.y + bounds.h },
    { x: bounds.x, y: bounds.y + bounds.h },
  ];

  const cornersInShapeSpace = corners.map((corner) =>
    editor.getPointInShapeSpace(shape, corner)
  );

  const cornerSegments: TLDrawShapeSegment[] = [
    {
      type: 'straight',
      points: [cornersInShapeSpace[0], cornersInShapeSpace[1]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[1], cornersInShapeSpace[2]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[2], cornersInShapeSpace[3]],
    },
    {
      type: 'straight',
      points: [cornersInShapeSpace[3], cornersInShapeSpace[0]],
    },
  ];

  const recStroke = getSvgPathFromPoints(
    getPointsFromSegments(cornerSegments),
    true
  );

  const whitePath = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path'
  );
  whitePath.setAttribute('d', recStroke);
  whitePath.setAttribute('fill', 'white');
  whitePath.setAttribute('stroke', 'white');
  whitePath.setAttribute('stroke-width', '200px');
  svg.appendChild(whitePath);

  // Add black eraser paths
  eraserPaths.forEach((eraserPath) => {
    if (!eraserPath.segment.points.length) return;

    const maskOptions = getFreehandOptions(
      { dash: 'draw', isPen: false, isComplete: false },
      eraserPath.width + 1,
      false,
      false
    );

    const eraserPathPoints = getPointsFromSegments([eraserPath.segment]);
    const blackPath = document.createElementNS(
      'http://www.w3.org/2000/svg',
      'path'
    );
    blackPath.setAttribute('d', svgInk(eraserPathPoints, maskOptions));
    blackPath.setAttribute('stroke-linecap', 'round');
    blackPath.setAttribute('stroke', 'black');
    blackPath.setAttribute('fill', 'black');
    svg.appendChild(blackPath);
  });

  // Convert to blob URL
  const svgString = new XMLSerializer().serializeToString(svg);
  const blob = new Blob([svgString], { type: 'image/svg+xml' });
  return URL.createObjectURL(blob);
};
