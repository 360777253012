import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, HTMLMotionProps, motion } from 'framer-motion';
import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { memo, MouseEventHandler, ReactNode } from 'react';

const buttonVariants = cva(
  'relative text-white flex items-center cursor-pointer content-center text-sm  h-min gap-2 bg-(--btn-color) justify-center transition-colors',
  {
    variants: {
      variant: {
        primary: '[--btn-color:var(--button-primary-default)]',
        secondary: '[--btn-color:var(--button-secondary-default)]',
        tertiary: '[--btn-color:var(--button-tertiary-default)]',
        //New variants
        accent:
          ' bg-linear-to-r from-b-green/90 to-b-purple/90 hover:from-b-green hover:to-b-purple disabled:from-b-green/50   ',
        danger:
          '[--btn-color:var(--button-danger-default)] text-red font-semibold',
        tab: 'text-text-neutral border border-text-neutral/40 hover:bg-(--button-tab-hover)  hover:text-text-base/80 hover:border-text-base/20',
        menu: 'text-text-base ',
      },
      outline: {
        true: 'bg-transparent border border-(--btn-color) text-[var(--btn-color)] ',
      },
      hasIcon: {
        true: '',
        false: '',
      },
      state: {
        default: 'cursor-pointer',

        active: '',
        disabled: 'cursor-not-allowed text-opacity-50',
      },
      hasChildren: {
        true: '',
        false: ' px-3',
      },
      size: {
        xs: 'px-2 py-1 text-xs',
        small: 'px-3 py-2 text-xs',
        medium: 'p-3',
        large: 'p-4',
      },
    },
    compoundVariants: [
      //So it stays round when there is no text
      { size: 'xs', hasChildren: false, className: 'py-2' },
      { size: 'small', hasChildren: false, className: 'px-2' },

      //Menu variant
      {
        variant: 'menu',
        state: 'active',
        className:
          '[--btn-color:var(--button-menu-active)]  border-(--button-tab-active)  hover:border-(--button-menu-active )',
      },

      //Tab variant
      {
        variant: 'tab',
        state: 'active',
        className:
          '[--btn-color:var(--button-tab-active)] text-text-negative border-(--button-tab-active) hover:text-text-negative hover:border-(--button-tab-active) ',
      },
      // Accent variants
      {
        variant: 'accent',
        state: 'active',
        className: 'bg-linear from-b-green to-b-purple',
      },
      {
        variant: 'accent',
        state: 'disabled',
        className:
          '  text-opacity-50 disabled:from-b-green/50 disabled:to-b-purple/50',
      },

      // Primary variants
      {
        variant: 'primary',
        state: 'active',
        className: '[--btn-color:var(--button-primary-active)]',
      },
      {
        variant: 'primary',
        state: 'disabled',
        className: 'disabled:[--btn-color:var(--button-primary-disabled)]',
      },
      // Secondary variants
      {
        variant: 'secondary',
        state: 'active',
        className: '[--btn-color:var(--button-secondary-active)]',
      },
      {
        variant: 'secondary',
        state: 'disabled',
        className: 'disabled:[--btn-color:var(--button-secondary-disabled)]',
      },
      // Tertiary variants
      {
        variant: 'tertiary',
        state: 'active',
        className: '[--btn-color:var(--button-tertiary-active)]',
      },
      {
        variant: 'tertiary',
        state: 'disabled',
        className: 'disabled:[--btn-color:var(--button-tertiary-disabled)]',
      },
    ],
    defaultVariants: {
      size: 'medium',
      variant: 'primary',
      outline: false,

      state: 'default',
    },
  }
);

type ButtonProps = Omit<
  VariantProps<typeof buttonVariants>,
  'hasChildren' | ' hasIcon'
> & {
  leftIcon?: IconDefinition;
  rightIcon?: IconDefinition;
  children?: ReactNode | ReactNode[] | undefined;
  className?: string;
  iconSize?: string;
  showLabel?: boolean;
  round?: boolean;
  disabled?: boolean;
  selectorId?: string;
  animateLabel?: boolean;

  orientation?: 'horizontal' | 'vertical';

  onClick?: MouseEventHandler<HTMLButtonElement>;
} & Omit<HTMLMotionProps<'button'>, 'style' | 'className' | 'disabled'>;

/**
 * Button component that reflects the design system of the application. It is not general purpose. *
 *
 *  !! className props are escape hatches and should not be used !!
 *
 * @param {Object} props - The properties object.
 * @param {ReactNode | ReactNode[] | undefined} props.children - The content of the button.
 * @param {string} [props.variant] - The variant of the button. Can be 'primary', 'secondary', 'tertiary', 'accent', 'danger', 'tab', or 'menu'.
 * @param {boolean} [props.outline] - Whether the button has an outline style.
 * @param {string} [props.size] - The size of the button. Can be 'xs', 'small', 'medium', or 'large'.
 * @param {IconDefinition} [props.leftIcon] - Icon to be displayed on the left side of the button.
 * @param {IconDefinition} [props.rightIcon] - Icon to be displayed on the right side of the button.
 * @param {string} [props.state] - The state of the button. Can be 'default', 'active', or 'disabled'.
 * @param {boolean} [props.round=false] - Whether the button has rounded corners.
 * @param {string} [props.iconSize='16px'] - The size of the icons.
 * @param {boolean} [props.showLabel=true] - Whether to show the label.
 * @param {'horizontal' | 'vertical'} [props.orientation='horizontal'] - The orientation of the button content.
 * @param {MouseEventHandler<HTMLButtonElement>} [props.onClick] - The click event handler.
 * @param {string} [props.selectorId] - The ID for the selection indicator, if you want to animate and share it between other buttons.
 *
 *
 * @param {string} [props.className] - Additional class names for the button.

 * @returns {JSX.Element} The rendered button component.
 */
const Button = ({
  children,
  variant,
  outline,
  size,

  leftIcon,
  rightIcon,
  className,
  state,
  round = false,
  iconSize = '16px',
  showLabel = true,
  orientation = 'horizontal',

  animateLabel = true,

  onClick,
  selectorId,
  ...baseButtonProps
}: ButtonProps) => {
  const hasIcon = !!leftIcon || !!rightIcon;

  return (
    <motion.button
      disabled={state === 'disabled'}
      layout
      onClick={(e) => {
        if (state !== 'disabled' && onClick) {
          onClick(e);
        }
      }}
      className={twMerge(
        buttonVariants({
          size,
          variant,
          outline,
          state: state,
          hasChildren: !!children && showLabel,
          hasIcon,
        }),
        selectorId && 'bg-transparent',
        orientation === 'vertical' && 'flex-col',
        className
      )}
      style={{ borderRadius: round ? '9999px' : '12px' }} //border radius needs to be set in style for framer motion to animate it properly
      {...baseButtonProps}>
      {/* LEFT ICON */}

      <AnimatePresence initial={false}>
        {leftIcon && (
          <motion.div
            layout='position'
            style={{ width: iconSize, height: iconSize }}
            className='z-10 flex aspect-square items-center justify-center'>
            <FontAwesomeIcon
              style={{ width: iconSize, height: iconSize }}
              icon={leftIcon}
            />
          </motion.div>
        )}
      </AnimatePresence>

      {/* LABEL */}
      <AnimatePresence initial={false}>
        {children && showLabel && (
          <motion.div
            layout='position'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'linear', duration: animateLabel ? 0.2 : 0 }}
            style={{ lineHeight: hasIcon ? 0 : iconSize }}
            className={
              'z-10 flex content-center items-center justify-center text-nowrap'
            }>
            {children}
          </motion.div>
        )}
      </AnimatePresence>

      {/* RIGHT ICON */}
      <AnimatePresence initial={false}>
        {rightIcon && (
          <motion.div
            layout='position'
            style={{ width: iconSize, height: iconSize }}
            className='z-10 flex aspect-square items-center justify-center'>
            <FontAwesomeIcon
              style={{ width: iconSize, height: iconSize }}
              icon={rightIcon}
            />
          </motion.div>
        )}
      </AnimatePresence>

      <AnimatePresence initial={false}>
        <Selector selectorId={selectorId} round={round} state={state} />
      </AnimatePresence>
    </motion.button>
  );
};

const Selector = memo(
  ({
    state,
    selectorId,
    round,
  }: {
    selectorId: string | undefined;
    round: boolean;
    state: string | undefined | null;
  }) => {
    if (state !== 'active') return null;
    return (
      <motion.div
        layoutId={selectorId}
        /*  transition={{ duration: 2 }} */
        style={{ borderRadius: round ? '9999px' : '10px' }}
        className='rounded-12 absolute z-0 h-full w-full bg-(--btn-color)'
      />
    );
  }
);

export default Button;
