import useUpdateWhiteboard from '../../../hooks/whiteboard/useRenameWhiteboard';
import { useGetWhiteboard } from '@/hooks/whiteboard/useGetWhiteboard';
import RenamableItem from '@/components/lib/RenamableItem';

type RenamableWhiteboardProps = {
  whiteboardId: string;
  className?: string;
};

const RenamableWhiteboard = ({
  whiteboardId,
  className,
}: RenamableWhiteboardProps) => {
  const { whiteboard } = useGetWhiteboard(whiteboardId);
  const { updateWhiteboard } = useUpdateWhiteboard();

  const handleRename = async (newName: string) => {
    if (!whiteboard) return;
    await updateWhiteboard({
      whiteboardId: whiteboard.id,
      whiteboard: { name: newName },
    });
  };

  return (
    <RenamableItem
      initialName={whiteboard?.name}
      onRename={handleRename}
      className={className}
    />
  );
};

export default RenamableWhiteboard;
